import { Injectable } from '@angular/core';
import { MessagesBaseService } from '../shared/services/messages-base/messages-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectReservationMessagesService extends MessagesBaseService {
  protected entitySet: string = 'project-reservations';
} 

