import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-detected-adblocker-modal',
    templateUrl: './detected-adblocker-modal.component.html',
    styleUrls: ['./detected-adblocker-modal.component.scss'],
    imports: [SharedModule]
})
export class DetectedAdblockerModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onRefresh() {
    window.location.reload();
  }

}
