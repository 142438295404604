import { Injectable } from '@angular/core';
import { ModelViewer } from './model-viewer';
import { BehaviorSubject, Subject } from 'rxjs';
import { ArticleRef, ViewerRef } from './model-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetectedAdblockerModalComponent } from '../../ui-elements/detected-adblocker-modal/detected-adblocker-modal/detected-adblocker-modal.component';
import { ModelViewerErrorEnum } from './model-viewer.enum';

export interface ModelViewerRerendererSubjectInterface {
  shouldRerender: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModelViewerService {
  viewerRef$: BehaviorSubject<ViewerRef> = new BehaviorSubject<ViewerRef>(null);

  constructor(
    private modalService: NgbModal,
  ) {}

  create(server, session, item, element, assetsPath?) {
    return new ModelViewer(server, session, item, { viewerElement: element, assetsPath: assetsPath }, this.handleViewerError.bind(this));
  }

  handleViewerError(error) {
    switch (error) {
      case ModelViewerErrorEnum.TEXTURE_LOAD_FAILED:
        // assume that texture load failed because of adblocker
        this.modalService.open(DetectedAdblockerModalComponent, {
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
        });
        break;
    
      default:
        console.error('ModelViewer: unknown error', error);
        break;
    }
  }
}
