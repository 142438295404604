import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EncodeUriPipe } from '../encode-uri.pipe';

export enum ImageSizeEnum {
  SIZE_698x510 = '698x510', // parsisiuntimas iš katalogo list’o (org 800x800 arba daugiau)
  SIZE_331x272 = '331x272', // rodomas kataloge kai įjungtas thumbs tipas ir nėra filtrų
  SIZE_320x240 = '320x240',
  SIZE_640xAUTO = '640xAUTO', // rodomas katalogo navigacijoje užeinant ant kategorijų (org 1920x1080)
  SIZE_271x223 = '271x223', // rodomas kataloge kai įjungtas thumbs tipas
  SIZE_160x160 = '160x160', // rodomas kataloge list mode (org 428x338, būna ir daugiau)
  SIZE_120x120 = '120x120', // tekstūros preview
  SIZE_24x24 = '24x24',     // tekstūra dangų pasirinkime nestandartinio gaminio modale
  SIZE_16x16 = '16x16',     // tekstūros informacija (org 32x32)
  SIZE_402x258 = '402x258', // rodoma redaguojant nestandartinę prekę
  SIZE_220x220 = '220x220', // prekės foto pdf preview (plotis fiksuotas)
  SIZE_140x140 = '140x140', // rodomas užsakymo views’e
  SIZE_104x104 = '104x104', // kliento logo dokumento exportavimo modale
  SIZE_AUTOx56 = 'AUTOx56', // tiekejo logo pdf preview (aukštis fiksuotas, plotis iki *)
  SIZE_AUTOx44 = 'AUTOx44', // kliento logo pdf preview (aukštis fiksuotas, plotis iki *)
  SIZE_600x300 = '600x300', // dangos thumbnail parsiunčiamas
  SIZE_394x224 = '394x224', // informacijos skiltis pradinis thumbnails
  SIZE_280x140 = '280x140', // dangų thumbnail
  SIZE_1920x1080 = '1920x1080', // naujienos headerio fonas
  SIZE_885x560 = '885x560', // naujienos featured thumbnailsas
  SIZE_427x220 = '427x220', // naujienos standartinis thumbnails
  SIZE_764x430 = '764x430', // video player cover image
  SIZE_198x112 = '198x112', // video thumbnails
}

@Pipe({ name: 'resizeImage' })
export class ResizeImagePipe implements PipeTransform {
  constructor(private encodeUriPipe: EncodeUriPipe) {}

  transform(value: string, size: ImageSizeEnum): string {
    if (value?.startsWith('uploads')) {
      return `${environment.imageResizeURL}${size}/plain/${encodeURIComponent(value)}`;
    }

    return this.encodeUriPipe.transform(value);
  }
}
