import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, zip } from 'rxjs';
import { DocumentFileFormat } from '../../core/enums/document-file-formats.enum';
import { ClientInterface } from '../../core/models/client.model';
import { OrderInterface } from '../../core/models/order.model';
import { Tab } from '../../core/models/tab.model';
import { SharedModule } from '../../shared/shared.module';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { LoaderService } from '../../ui-elements/loader/loader.service';
import { TooltipGlobalOpenerDirective } from '../../ui-elements/tooltip/tooltip-global-opener.directive';
import { OrdersHelperService } from '../orders-helper.service';
import { DocumentTemplateService } from '../services/document-template/document-template.service';
import { DocumentTemplate } from './document-template.interface';
import { DocumentTemplateComponent } from './document-template/document-template.component';
import { TemplateTypeOrderMapperService } from './template-type-order-mapper.service';

interface CreateDocumentTabs {
  template: Tab;
  preview: Tab;
}

@Component({
    selector: 'app-orders-create-document-modal',
    templateUrl: './create-document-modal.component.html',
    styleUrls: ['./create-document-modal.component.scss'],
    providers: [OrdersHelperService],
    imports: [SharedModule, TooltipGlobalOpenerDirective, LoaderComponent, DocumentTemplateComponent]
})
export class CreateDocumentModalComponent implements OnInit, OnDestroy {
  @Input() order: OrderInterface;
  @Input() data: DocumentTemplate.Data;
  @Input() isProcessingDocumentDownload: boolean;

  @ViewChild('downloadLoader', { static: false }) downloadLoader: LoaderComponent;
  @ViewChild('previewLoader', { static: false }) previewLoader: LoaderComponent;
  @ViewChild('documentTemplateComponent', { static: false }) documentTemplateComponent: DocumentTemplateComponent;

  @Output() clientSelect: EventEmitter<ClientInterface> = new EventEmitter();

  private subscription: Subscription = new Subscription();

  tabs: CreateDocumentTabs = {
    template: { id: 0, title: 'CREATE_DOCUMENT.TEMPLATE.TABS.DOCUMENT_SETTINGS' },
    preview: { id: 1, title: 'CREATE_DOCUMENT.TEMPLATE.TABS.PREVIEW' },
  };
  activeTab: Tab;
  documentFileFormats = DocumentFileFormat;
  templates: DocumentTemplate.Template[];
  previewHTML: ArrayBuffer = null;
  loading: boolean;
  showErrors = false;
  allowGenerateDocument = false;
  selectedTemplate: DocumentTemplate.Template;

  constructor(
    private documentTemplateService: DocumentTemplateService,
    private loaderService: LoaderService,
    private translator: TranslateService,
    private templateTypeOrderMapper: TemplateTypeOrderMapperService,
  ) {
  }

  ngOnInit() {
    this.activeTab = this.tabs.template;
    this.subscription.add(this.documentTemplateService.getDataAsObservable().subscribe(data => {
      this.data = data;
      this.selectedTemplate = {...this.selectedTemplate, ...data?.selectedTemplate};
      this.allowGenerateDocument = this.shouldAllowGenerateDocument();
    }));
    this.loadTemplates();
  }

  loadTemplates(): void {
    this.loading = true;
    zip(this.translator.get('LANGUAGES'), this.documentTemplateService.getTemplatesByOrder(this.order.id)).subscribe({
      next: ([translations, data]) => {
        data.language = {
          ...data.language,
          translated: translations[data.language.short.toUpperCase()],
        };

        this.templates = data.templates;
        this.data = data;
        if (this.templates && this.templates.length) {
          const selectedTemplateId = this.selectedTemplate
            ? this.selectedTemplate.id
            : this.templateTypeOrderMapper.has(this.order.id)
            ? this.templateTypeOrderMapper.get(this.order.id)
            : this.templates[0].id;

          let selectedTemplate = this.templates.find(template => template.id === selectedTemplateId);
          if (!selectedTemplate) {
            [selectedTemplate] = this.templates;
          }
          this.onTemplateChange(selectedTemplate);
        }
        this.loading = false;
        this.subscription.add(
          this.documentTemplateService.getDataAsObservable().subscribe(({ client, clientDataType, order, templates, selectedTemplate }) => {
            this.data = { ...this.data, client, clientDataType, order, templates };
            this.selectedTemplate = { ...this.selectedTemplate, ...selectedTemplate };
          })
        );
      },
      error: () => (this.loading = false)
    });
  }

  onTemplateChange(template: DocumentTemplate.Template) {
    this.selectedTemplate = template;
    this.documentTemplateService.selectTemplate(this.selectedTemplate);
    this.templateTypeOrderMapper.set(this.order.id, this.selectedTemplate.id);
  }

  switchTab(tab) {
    if (tab.id === this.activeTab.id) {
      return;
    }

    this.activeTab = tab;
    switch (this.activeTab.id) {
      case this.tabs.preview.id:
        this.openPreviewTab();
        break;
      case this.tabs.template.id:
        this.loadTemplates();
        break;
    }
  }

  validateSubForms() {
    if (this.documentTemplateComponent) {
      return this.documentTemplateComponent.validateSubForms();
    }

    // this means that user is on a preview tab and validation is already passed
    return true;
  }

  /**
   * Get document preview HTML from back-end
   */
  openPreviewTab() {
    const subFormValid = this.validateSubForms();
    if (!this.allowGenerateDocument || !subFormValid) {

      return;
    }

    this.activeTab = this.tabs.preview;
    this.previewHTML = null;
    this.loaderService
      .load(
        this.documentTemplateService.getDocumentByTemplate(this.order.id, this.selectedTemplate.id, DocumentFileFormat.HTML),
        this.previewLoader
      )
      .subscribe((document: ArrayBuffer) => {
        this.previewHTML = document;
      });
  }

  onDocumentDownload(fileFormat: DocumentFileFormat) {
    const subFormValid = this.validateSubForms();
    if (!this.allowGenerateDocument || !subFormValid) {
      this.showErrors = true;

      return;
    }

    window.dataLayer.push({
      event: `exportOrder${fileFormat.toUpperCase()}`,
      url: window.location.href,
      order: this.order.id,
      template: this.selectedTemplate.title,
    });

    this.isProcessingDocumentDownload = true;
    this.downloadLoader.show();

    this.documentTemplateService
      .downloadDocument(this.order.id, this.selectedTemplate.id, fileFormat, `${this.order.title}.${fileFormat}`)
      .add(() => {
        this.isProcessingDocumentDownload = false;
        this.downloadLoader.hide();
      });
  }

  onDropdown(downloadDrop) {
    const subFormValid = this.validateSubForms();
    if (!this.allowGenerateDocument || !subFormValid) {

      return;
    }

    if (this.isProcessingDocumentDownload) {
      downloadDrop.close();

      return;
    }

    downloadDrop.toggle();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  shouldAllowGenerateDocument() {
    if (0 // prettier
      || !this.data
      || !this.selectedTemplate.currency
      || !this.selectedTemplate.currencyDisplayType
    ) {
      return false;
    }

    if (this.selectedTemplate.type === DocumentTemplate.Type.PROPOSAL) {
      return true;
    }

    const { client } = this.data;

    switch (this.data.clientDataType) {
      case DocumentTemplate.CustomerDataType.FROM_CUSTOMER:
        return !!(this.order && this.order.client && this.order.client.id);
      case DocumentTemplate.CustomerDataType.OPTIONAL:
        return !!(client && client.clientCompanyName && client.clientAddress && client.clientCompanyCode && client.clientVatCode);
    }
  }

  getTooltipTranslationKey() {
    return !this.selectedTemplate || !this.selectedTemplate.currency || !this.selectedTemplate.currencyDisplayType
      ? 'CREATE_DOCUMENT.CURRENCY_WARNING'
      : 'CREATE_DOCUMENT.CUSTOMER_WARNING';
  }
}
