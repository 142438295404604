<div class="row" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
  <div class="col-6">
    <app-ui-elements-form-drop-down
      [values]="listTypes"
      [title]="'CREATE_DOCUMENT.TEMPLATE.LIST_TYPE' | translate"
      valueField="id"
      labelField="title"
      [selectedValue]="selectedTemplate.listType"
      (onChange)="onSelectTemplateListType($event)"
      [translateLabels]="true"
    ></app-ui-elements-form-drop-down>
  </div>

  <div class="col-6">
    <app-ui-elements-form-drop-down
      [values]="photoTypes"
      [selectedValue]="selectedTemplate.photoType"
      valueField="id"
      labelField="title"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORMAT' | translate"
      (onChange)="onSelectPhotoType($event)"
      [translateLabels]="true"
    ></app-ui-elements-form-drop-down>
  </div>
</div>
<div class="row g-0 border-top mb-3 pb-2" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
  <div class="col"></div>
</div>

<div class="row px-3">
  <div class="col-3">
    <h4 class="color-gray-500">{{ 'CREATE_DOCUMENT.TEMPLATE.PRODUCT_LIST_SECTIONS.PRODUCT_INFORMATION' | translate }}</h4>
  </div>
  <div class="col-3">
    <h4 class="color-gray-500">{{ 'CREATE_DOCUMENT.TEMPLATE.PRODUCT_LIST_SECTIONS.DETAILS' | translate }}</h4>
  </div>
  <div class="col-3">
    <h4 class="color-gray-500">{{ 'CREATE_DOCUMENT.TEMPLATE.PRODUCT_LIST_SECTIONS.MATERIALS' | translate }}</h4>
  </div>
  <div class="col-3">
    <h4 class="color-gray-500">{{ 'CREATE_DOCUMENT.TEMPLATE.PRODUCT_LIST_SECTIONS.OTHER' | translate }}</h4>
  </div>
</div>

<div class="row px-3">
  <div class="col-3">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: {
        title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DESCRIPTION',
        property: 'isDescription',
        disabled: selectedTemplate.listType === listTypeEnum.CONFIGURATION || selectedTemplate.type == templateTypes.INVOICE
      }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.SYSTEM', property: 'isSystem' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.CATEGORY', property: 'isCategory' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: {
        title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.FULL_CODE',
        property: 'isFullCode',
        disabled: selectedTemplate.listType === listTypeEnum.CONFIGURATION && selectedTemplate.type == templateTypes.PROPOSAL
      }"
    ></ng-container>
  </div>
  <div class="col-3">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DIMENSIONS', property: 'isDimensions' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        templateFieldRef;
        context: {
          title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.VOLUME_WEIGHT',
          property: 'isVolumeAndWeight',
          disabled: selectedTemplate.type == templateTypes.INVOICE
        }
      "
    ></ng-container>
  </div>
  <div class="col-3">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: {
        title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.IMAGE',
        property: 'materialTypes.image',
        disabled: selectedTemplate.type == templateTypes.INVOICE || materialsImpossible
      }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: {
        title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.CODE',
        property: 'materialTypes.code',
        disabled: materialsImpossible
      }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: {
        title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.TITLE',
        property: 'materialTypes.title',
        disabled: materialsImpossible
      }"
    ></ng-container>
  </div>
  <div class="col-3">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.NET_PRICE', property: 'isNetPrice' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DISCOUNT', property: 'isDiscount' }"
    ></ng-container>
  </div>
</div>

<ng-template #templateFieldRef let-property="property" let-title="title" let-disabled="disabled">
  <app-check-mark
    [value]="getPropertyValue(property)"
    (changed)="onTemplateFieldChange($event, property)"
    [name]="'document-template-field' + property"
    [text]="(title.toUpperCase() | translate) || title"
    [disabled]="disabled || isBusy"
    [cssClasses]="'mb-3'"
    [attr.data-testid]="'document-template-field-' + property"
  ></app-check-mark>
</ng-template>
