import { ProjectTypes } from './../../project-reservation.service';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProjectReservationInterface, ProjectReservationOutcomeEnum, ProjectReservationStatusEnum } from '../../../core/models/project-reservation.model';
import { SharedModule } from '../../../shared/shared.module';
import { ProjectReservationHelperService } from '../../project-reservation-helper.service';
import {
  ProjectReservationLostReasonsUpdateInterface,
  ProjectReservationService
} from '../../project-reservation.service';
import { ProjectReservationStatusComponent } from '../list/project-reservation-status/project-reservation-status.component';
import { ProjectReservationModalLostComponent } from '../project-reservation-modal-lost/project-reservation-modal-lost.component';
import { ProjectReservationModalComponent } from '../project-reservation-modal/project-reservation-modal.component';
import { ProjectReservationCommunicationComponent } from './project-reservation-communication/project-reservation-communication.component';
import { ProjectReservationLostFeedbackComponent } from './project-reservation-lost-feedback/project-reservation-lost-feedback.component';
import { NotificationComponent } from "../../../shared/components/notification/notification.component";
import { FilePreviewComponent } from '../../../ui-elements/file-preview/file-preview.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { UserService } from '../../../core/services/user/user.service';

export enum ProjectReservationPreviewTabs {
  DETAILS = 'details',
  COMMUNICATION = 'communication'
}

export enum AlertTypeByProjectReservationStatus {
  new = 'd-none',
  in_progress = 'd-none',
  waiting_for_response = 'd-none',
  open_tender = 'bg-success-100',
  confirmed = 'bg-success-100',
  need_to_review = 'bg-warning-100',
  rejected = 'bg-gray-200',
  lost = 'bg-gray-200',
  won = 'bg-success-100',
  partially_applied = 'bg-success-100',
  applied = 'bg-gray-200',
  expired = 'bg-gray-200',
}

@Component({
    selector: 'app-project-reservation-preview',
    templateUrl: './project-reservation-preview.component.html',
    imports: [
      SharedModule,
      ProjectReservationStatusComponent,
      ProjectReservationLostFeedbackComponent,
      ProjectReservationCommunicationComponent,
      NotificationComponent,
      FilePreviewComponent,
      NgScrollbar,
    ]
})

export class ProjectReservationPreviewComponent implements OnDestroy, OnInit {
  @Input() item: ProjectReservationInterface;
  @Input() activeTab: ProjectReservationPreviewTabs = ProjectReservationPreviewTabs.DETAILS;

  @Output() onProjectReservationSaved = new EventEmitter<void>();
  @Output() onProjectReservationUpdated = new EventEmitter<ProjectReservationInterface>();

  tabs = ProjectReservationPreviewTabs;
  alertsByStatus = AlertTypeByProjectReservationStatus;
  projectReservationStatuses = ProjectReservationStatusEnum;
  form: FormGroup;
  isFeedbackProvided: boolean;
  isWon: boolean;
  isLost: boolean;
  isOutcomeProvided: boolean;
  isStatusWithOutcome: boolean;
  isUpcomingOrdersCountVisible: boolean;
  projectTypesEnum = ProjectTypes;
  userCurrencySymbol = '&euro;';

  protected subscriptions: Subscription = new Subscription();

  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  constructor(
    protected projectReservationService: ProjectReservationService,
    private modalService: NgbModal,
    protected activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.initDefaults();
    this.constructForm();
  }

  private initDefaults() {
    this.isFeedbackProvided = false;
    this.isWon = false;
    this.isLost = false;
    this.isOutcomeProvided = true;
    this.isStatusWithOutcome = false;
    this.isUpcomingOrdersCountVisible = false;
  }

  ngOnInit() {
    if ([
      ProjectReservationStatusEnum.OPEN_TENDER,
      ProjectReservationStatusEnum.CONFIRMED,
      ProjectReservationStatusEnum.NEED_TO_REVIEW,
      ProjectReservationStatusEnum.EXPIRED
    ].includes(this.item.status)) {
      this.isStatusWithOutcome = true;
      if (!this.item.outcome) {
        this.isOutcomeProvided = false;
      }
    }

    this.isWon = this.item.outcome === ProjectReservationOutcomeEnum.WON;
    this.isLost = this.item.outcome === ProjectReservationOutcomeEnum.LOST;

    if (this.item.outcome === ProjectReservationOutcomeEnum.WON
      && [
        ProjectReservationStatusEnum.WON,
        ProjectReservationStatusEnum.CONFIRMED,
        ProjectReservationStatusEnum.NEED_TO_REVIEW,
        ProjectReservationStatusEnum.OPEN_TENDER
      ].includes(this.item.status)) {
      this.isUpcomingOrdersCountVisible = true;
    }

    this.subscriptions.add(
      this.route.queryParams.subscribe(({ markAs }) => {
        if (this.item.outcome) {
          return;
        }

        if (markAs === ProjectReservationOutcomeEnum.WON) {
          this.onMarkAsWon();
        } else if (markAs === ProjectReservationOutcomeEnum.LOST) {
          this.onMarkAsLost();
        }
      })
    );

    this.subscriptions.add(
      this.userService.userObservable().subscribe(user => {
        this.userCurrencySymbol = user.priceList.currency.name;
    }));
  }

  constructForm(fields?: ProjectReservationInterface, disabled: boolean = false) {
    this.form = new FormGroup({
      feedback: new FormControl({ value: ProjectReservationHelperService.prepareLostFeedbackFormValues(fields), disabled: disabled }, []),
    });

    this.subscriptions.add(this.form.valueChanges.subscribe((formData) => {
      this.isFeedbackProvided = false;
      Object.keys(formData.feedback).forEach(key => {
        if (!['otherLostReasons', 'otherReasons'].includes(key) && formData.feedback[key]) {
          this.isFeedbackProvided = true;
        }
      });
      if (formData.feedback.otherReasons) {
        this.isFeedbackProvided = !!formData.feedback.otherLostReasons?.length;
      }
    }));
  }

  public switchTab(tab: ProjectReservationPreviewTabs) {
    this.activeTab = tab;
  }

  onFeedbackSubmit() {
    this.saveLostFeedback(this.form.controls.feedback.value);
  }

  private saveLostFeedback(lostFeedbackFormValues: ProjectReservationLostReasonsUpdateInterface) {
    const feedbackData = ProjectReservationHelperService.formatLostFeedback(this.item, lostFeedbackFormValues);
    feedbackData.outcome = ProjectReservationOutcomeEnum.LOST;
    this.projectReservationService.update(this.item.id, feedbackData).subscribe(result => {
      this.onProjectReservationUpdated.emit(result.data);
      this.updateForm(result.data);
    });
  }

  private updateForm(data: ProjectReservationInterface) {
    this.initDefaults();
    this.item = data;
    this.ngOnInit();
    this.form.patchValue(data);
  }

  onCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }

  onMarkAsWon() {
    const modalRef = this.modalService.open(ProjectReservationModalComponent, {
      size: 'lg',
      centered: true,
      keyboard: true,
    });

    const modalInstance: ProjectReservationModalComponent = modalRef.componentInstance;
    modalInstance.updateMode = true;
    modalInstance.constructForm(this.item);

    this.subscriptions.add(modalInstance.updated.subscribe((result) => {
      result.outcome = ProjectReservationOutcomeEnum.WON;
      this.projectReservationService.update(this.item.id, result).subscribe((result) => {
        this.onProjectReservationUpdated.emit(result.data);
        this.updateForm(result.data);
      });
    }));
  }

  onMarkAsLost() {
    const modalRef = this.modalService.open(ProjectReservationModalLostComponent, {
      size: 'sm',
      keyboard: true,
    });

    const modalInstance: ProjectReservationModalLostComponent = modalRef.componentInstance;
    modalInstance.constructForm(ProjectReservationHelperService.prepareLostFeedbackFormValues(this.item));

    this.subscriptions.add(modalInstance.saved.subscribe((result) => {
      this.saveLostFeedback(result);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
