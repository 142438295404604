import {Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {CustomDiscountVisibilityDecisionMakerService, CustomDiscountVisibilityInterface} from './custom-discount-visibility-decision-maker.service';

@Directive({ selector: '[appAddClassesIfCustomDiscountApplied]' })
export class AddClassesIfCustomDiscountAppliedDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  @Input('appAddClassesIfCustomDiscountApplied') classes: string[];

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private customDiscountVisibilityDecisionMaker: CustomDiscountVisibilityDecisionMakerService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.customDiscountVisibilityDecisionMaker.visibleAsObservable().subscribe((visible: CustomDiscountVisibilityInterface) => {
        const method = CustomDiscountVisibilityDecisionMakerService.any(visible) ? this.renderer.addClass : this.renderer.removeClass;

        this.classes.forEach((cssClass: string) => {
          method.apply(this.renderer, [this.elRef.nativeElement, cssClass]);
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
