import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CloseButtonTheme } from './close-button-theme.enum';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-ui-elements-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.scss'],
    imports: [NgClass]
})
export class CloseButtonComponent implements OnInit {
  @Output() clicked: EventEmitter<null | undefined> = new EventEmitter<null | undefined>();
  @Input() theme?: CloseButtonTheme = CloseButtonTheme.DEFAULT;

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.clicked.emit();
  }
}
