import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { decimalNumbers, validDateFormat } from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { DateInputComponent } from '../../../../ui-elements/date-input/date-input.component';
import { FileUploadComponent } from '../../../../ui-elements/file-upload/file-upload.component';
import { FormDropDownComponent } from '../../../../ui-elements/form-drop-down/form-drop-down.component';
import { TextFieldComponent } from '../../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

@Component({
    selector: 'app-template-headline',
    templateUrl: './template-headline.component.html',
    styleUrls: ['./template-headline.component.scss'],
    imports: [SharedModule, TextFieldComponent, FormDropDownComponent, DateInputComponent, FileUploadComponent]
})
export class TemplateHeadlineComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedTemplate: DocumentTemplate.Template;
  @Input() data: DocumentTemplate.Data;
  @Input() backgroundType = [];
  @Input() customerDataTypes = [];

  textFieldThemeTypes = TextFieldThemeTypes;
  documentTemplateType = DocumentTemplate.Type;
  headlineBackgroundType = DocumentTemplate.HeadlineBackgroundType;
  proposalForm: ReturnType<typeof this.constructProposalForm>;
  invoiceForm: ReturnType<typeof this.constructInvoiceForm>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private documentTemplateService: DocumentTemplateService
  ) {}

  ngOnInit() {
    if (this.selectedTemplate.type === DocumentTemplate.Type.PROPOSAL) {
      this.constructProposalForm(this.selectedTemplate.dealer, this.selectedTemplate.isVat);
      this.proposalForm.markAsPristine();
      this.proposalForm.markAsUntouched();
    } else {
      this.constructInvoiceForm(this.data);
      this.invoiceForm.markAsPristine();
      this.invoiceForm.markAsUntouched();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // we do not need to rebuild stuff and subscribe to observables
    // multiple times. we should rebuild everything only if types changed
    if (
      changes.selectedTemplate &&
      changes.selectedTemplate.currentValue &&
      ((changes.selectedTemplate.previousValue &&
        changes.selectedTemplate.currentValue.type !== changes.selectedTemplate.previousValue.type) ||
        !changes.selectedTemplate.previousValue)
    ) {
      switch (this.selectedTemplate.type) {
        case DocumentTemplate.Type.PROPOSAL:
          this.proposalForm = this.constructProposalForm(this.selectedTemplate.dealer, this.selectedTemplate.isVat);
          this.customerDataTypeChange(this.data.clientDataType);
          this.proposalForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(({ provider, customer }) => {
            const dealer: DocumentTemplate.Dealer = {
              ...this.selectedTemplate.dealer,
              address: provider.address,
              companyName: provider.name,
              companyCode: provider.companyCode,
              vatCode: provider.vatCode,
              vatRate: provider.vatRate
            };
            const client: DocumentTemplate.Client = {
              ...this.data.client,
              clientCompanyName: customer.name,
              clientAddress: customer.address,
              clientProposalTitle: customer.title,
              clientProposalDateTimestamp: customer.proposalDate ?? '', // moment(customer.proposalDate).format() : '',
            };

            this.documentTemplateService.updateData({ client, selectedTemplate: { ...this.selectedTemplate, dealer } });
          });
          break;
        case DocumentTemplate.Type.INVOICE:
          this.invoiceForm = this.constructInvoiceForm(this.data);
          this.invoiceForm.valueChanges
            .pipe(
              filter(() => this.invoiceForm.valid),
              distinctUntilChanged(),
              debounceTime(500)
            )
            .subscribe(({ invoiceNumber, invoiceDate }) => {
              if (this.invoiceForm.invalid) {
                return;
              }

              this.documentTemplateService.updateData({
                invoiceNumber,
                invoiceDateTimestamp: invoiceDate ? moment(invoiceDate).format() : '',
              });
            });
          break;
      }
    }
  }

  constructProposalForm({ companyName, address, companyCode, vatCode, vatRate }: DocumentTemplate.Dealer, calculateVatEnabled: DocumentTemplate.Template['isVat']) {
    const vatRateValidators = [
      Validators.maxLength(50),
      decimalNumbers,
      Validators.min(0),
      Validators.max(100)
    ];

    if (calculateVatEnabled) {
      vatRateValidators.push(Validators.required);
    }

    return new FormGroup({
      provider: new FormGroup({
        name: new FormControl(companyName, [Validators.maxLength(100)]),
        address: new FormControl(address, [Validators.maxLength(100)]),
        companyCode: new FormControl(companyCode, [Validators.maxLength(20)]),
        vatCode: new FormControl(vatCode, [Validators.maxLength(20)]),
        vatRate: new FormControl(vatRate, vatRateValidators)
      }),
      customer: new FormGroup({
        name: new FormControl('', [Validators.maxLength(100)]),
        address: new FormControl('', [Validators.maxLength(100)]),
        title: new FormControl('', [Validators.maxLength(100)]),
        proposalDate: new FormControl('', [validDateFormat]),
      }),
    });
  }

  constructInvoiceForm({ invoiceNumber, invoiceDateTimestamp }: DocumentTemplate.Data) {
    return new FormGroup({
      invoiceNumber: new FormControl(invoiceNumber, [Validators.maxLength(20)]),
      invoiceDate: new FormControl(invoiceDateTimestamp ? moment(invoiceDateTimestamp).format('YYYY-MM-DD') : '', [validDateFormat]),
    });
  }

  onCustomerDataTypeSelect(clientDataType: DocumentTemplate.CustomerDataType) {
    this.customerDataTypeChange(clientDataType);
    if (clientDataType !== DocumentTemplate.CustomerDataType.SELECT_CUSTOMER) {
      if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
        return;
      }

      this.documentTemplateService.updateData({ clientDataType });
    }
  }

  onBackgroundTypeChange(backgroundType: DocumentTemplate.HeadlineBackgroundType) {
    this.selectedTemplate.backgroundType = backgroundType;
    if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
      return;
    }

    this.documentTemplateService.updateData({ selectedTemplate: { ...this.selectedTemplate, backgroundType } });
  }

  onLogoUpload(file, prop: 'client' | 'dealer') {
    if (!file) {
      return;
    }
    switch (prop) {
      case 'client':
        this.data.client.clientLogo = { ...this.data.client.clientLogo, file: file.fileUrl };
        if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
          return;
        }

        this.documentTemplateService.updateData({ client: { ...this.data.client } });
        break;
      case 'dealer':
        this.selectedTemplate.dealer.logo = { ...this.selectedTemplate.dealer.logo, file: file.fileUrl };
        if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
          return;
        }

        this.documentTemplateService.updateSelectedTemplate({ dealer: { ...this.selectedTemplate.dealer } });
        break;
    }
  }

  onLogoRemove(prop: 'client' | 'dealer') {
    switch (prop) {
      case 'client':
        this.data.client.clientLogo = { ...this.data.client.clientLogo, url: '', file: null };
        if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
          return;
        }

        this.documentTemplateService.updateData({ client: { ...this.data.client } });
        break;
      case 'dealer':
        this.selectedTemplate.dealer.logo = { ...this.selectedTemplate.dealer.logo, url: '', file: null };
        if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
          return;
        }

        this.documentTemplateService.updateSelectedTemplate({ dealer: { ...this.selectedTemplate.dealer } });
        break;
    }
  }

  private customerDataTypeChange(clientDataType: DocumentTemplate.CustomerDataType) {
    const customFormGroup = this.proposalForm.controls.customer;
    customFormGroup.controls.name.enable();
    customFormGroup.controls.address.enable();

    switch (clientDataType) {
      case DocumentTemplate.CustomerDataType.SELECT_CUSTOMER:
        this.activeModal.close();
        this.documentTemplateService.openSelectClientModal();
        break;
      case DocumentTemplate.CustomerDataType.FROM_CUSTOMER:
        if (!this.data.order.client) {
          if (this.proposalForm?.invalid || this.invoiceForm?.invalid) {
            return;
          }

          this.documentTemplateService.updateData({ clientDataType: DocumentTemplate.CustomerDataType.OPTIONAL });
          return;
        }
        const { companyName, address } = this.data.order.client;
        customFormGroup.patchValue(
          {
            name: companyName,
            address: address,
            title: this.data.client.clientProposalTitle,
            proposalDate: this.data.client.clientProposalDateTimestamp
              ? moment(this.data.client.clientProposalDateTimestamp).format('YYYY-MM-DD')
              : '',
          },
          { emitEvent: false }
        );
        customFormGroup.controls.name.disable();
        customFormGroup.controls.address.disable();
        break;
      case DocumentTemplate.CustomerDataType.OPTIONAL:
        const { clientCompanyName, clientAddress, clientProposalTitle, clientProposalDateTimestamp } = this.data.client;
        customFormGroup.patchValue(
          {
            name: clientCompanyName,
            address: clientAddress,
            title: clientProposalTitle,
            proposalDate: clientProposalDateTimestamp ? moment(clientProposalDateTimestamp).format('YYYY-MM-DD') : '',
          },
          { emitEvent: false }
        );
        break;
    }
  }

  get vatRateControl(): FormControl {
    return this.proposalForm.get('provider.vatRate') as FormControl;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateSubForms() {
    if (this.selectedTemplate.type === DocumentTemplate.Type.PROPOSAL) {
      this.proposalForm.markAllAsTouched();

      return this.proposalForm.valid;
    }

    if (this.selectedTemplate.type === DocumentTemplate.Type.INVOICE) {
      this.invoiceForm.markAllAsTouched();

      return this.invoiceForm.valid;
    }
  }
}
