import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UserService } from '../../core/services/user/user.service';
import { Subscription, switchMap } from 'rxjs';
import { GenericModalComponent } from '../../ui-elements/generic-modal/generic-modal.component';
import { ModalBodyDirective } from '../../ui-elements/generic-modal/directives/modal-body/modal-body.directive';
import { ModalFooterDirective } from '../../ui-elements/generic-modal/directives/modal-footer/modal-footer.directive';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-pricelist-change-modal',
  templateUrl: './pricelist-change-modal.component.html',
  imports: [TranslatePipe, GenericModalComponent, ModalBodyDirective, ModalFooterDirective]
})
export class PricelistChangeModalComponent implements OnDestroy {
  @Output() understoodClick: EventEmitter<null> = new EventEmitter<null>();
  @Input() priceListName: string;

  isLoading = false;

  subscriptions = new Subscription();

  constructor(private userService: UserService) { }

  onUnderstoodClick() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.subscriptions.add(
      this.userService.getUser()
      .pipe(switchMap(({id, priceList}) => {
        return this.userService.update(id, { acceptedPricelist: priceList.name });
      }))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.understoodClick.emit();
        },
        error: () => {
          this.isLoading = false;
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
