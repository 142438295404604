import './polyfills';

import { CurrencyPipe, DecimalPipe, TitleCasePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, Injectable, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { NgbActiveModal, NgbDatepickerI18n, NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { provideGoogleTagManager } from 'angular-google-tag-manager';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { ApiService } from './app/api.service';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routing';
import { AuthInterceptor } from './app/auth/auth.interceptor';
import { CONFIGURATOR_SESSION_INTERCEPTOR_PROVIDER } from './app/configurator-modal/session/session.interceptor';
import { UserRolesArray } from './app/core/enums/user-role.enum';
import { IMPERSONATED_USER_INTERCEPTOR } from './app/core/impersonate/impersonate.interceptor';
import { WITH_CREDENTIALS_INTERCEPTOR_PROVIDER } from './app/core/interceptors/with-credentials.interceptor';
import { WITH_XDEBUG_INTERCEPTOR_PROVIDER } from './app/core/interceptors/with-xdebug.interceptor';
import { APP_DATE_LOCALE_ID, APP_DATE_LOCALE_ID_PROVIDER, LANGUAGE_INITIALIZER } from './app/core/locale/date-locale';
import { ACCEPT_LANGUAGE_HEADER_INTERCEPTOR_PROVIDER } from './app/core/services/language/accept-language.interceptor';
import { PRICELIST_HEADER_INTERCEPTOR_PROVIDER } from './app/core/services/price-list/price-list.interceptor';
import { SystemVersionService } from './app/core/services/system-version/system-version.service';
import { UserService } from './app/core/services/user/user.service';
import { HeaderMessageService } from './app/header-message/header-message.service';
import { SERVER_ERRORS_INTERCEPTOR } from './app/header-message/server-errors.interceptor';
import { MessageDetailsValueFormatPipe } from './app/messages-window/message-details-value-format.pipe';
import { permissions } from './app/permissions.config';
import { PERMISSIONS, ROLES } from './app/permissions/injection-tokens';
import { permissionsDecisionMakerServiceProvider } from './app/permissions/permissions-provider';
import { PermissionsUserService } from './app/permissions/user.model';
import { ResizeNotifier, ResizeNotifierService } from './app/resize-observer/resize-notifier.service';
import { REDIRECTS_FACTORY_PROVIDER } from './app/role-redirector.config';
import { ROLE_REDIRECTOR_ROLES } from './app/role-redirector/injection.tokens';
import { PriceListCurrencyPipe } from './app/shared/pipes/price-list-currency/price-list-currency.pipe';
import { SaleCoefficientFormatPipe } from './app/shared/pipes/sale-coefficient-format/sale-coefficient-format.pipe';
import { UNCACHEABLE_RESOURCE_QUERY_PARAM, UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY } from './app/uncacheable-resource/injection.tokens';
import { UpdateService } from './app/update.service';
import { VISIBILITY_BY_VARIABLES_PROPERTIES } from './app/visibility-by-variables/injection.tokens';
import { environment } from './environments/environment';

import localeLT from '@angular/common/locales/lt';
import localeRU from '@angular/common/locales/ru';
import localeFR from '@angular/common/locales/fr';
import localeDE from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import localeIT from '@angular/common/locales/it';
import localePL from '@angular/common/locales/pl';
import { APP_RELEASE_VERSION } from './app/core/constants/app.constants';

registerLocaleData(localeLT, 'lt-LT');
registerLocaleData(localeRU, 'ru-RU');
registerLocaleData(localeFR, 'fr-FR');
registerLocaleData(localeDE, 'de-DE');
registerLocaleData(localeES, 'es-ES');
registerLocaleData(localeIT, 'it-IT');
registerLocaleData(localePL, 'pl-PL');

if (environment.production && environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    // To set your release version
    release: APP_RELEASE_VERSION,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentryTracesSampleRate,
    beforeSend(event, hint) {
      const error = hint.originalException as Error;
      // Ignore HTTP errors
      if (error && error.name === 'HttpErrorResponse') {
        return;
      }
      return event;
    },
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.production && environment.sentryDSN) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY = () => {
  return {
    hidePrices: environment.hidePrices,
  };
};

export const VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY_PROVIDER = {
  provide: VISIBILITY_BY_VARIABLES_PROPERTIES,
  useFactory: VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY,
};

export const ROLES_FACTORY = () => {
  return UserRolesArray;
};

export const ROLES_FACTORY_PROVIDER = {
  provide: ROLE_REDIRECTOR_ROLES,
  useFactory: ROLES_FACTORY,
};

export const UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY = (systemVersionService: SystemVersionService) => {
  return systemVersionService.fetch();
};

export const UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY_PROVIDER = {
  provide: UNCACHEABLE_RESOURCE_QUERY_PARAM,
  useFactory: UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY,
  deps: [SystemVersionService],
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    provideServiceWorker('ngsw-worker.js', { enabled: environment.production }),
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    provideGoogleTagManager({
      id: environment.googleTagManagerId,
      gtm_auth: environment.googleTagManagerAuth,
      gtm_preview: environment.googleTagManagerPreview,
    }),
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    WITH_CREDENTIALS_INTERCEPTOR_PROVIDER,
    ...(environment.production ? [] : [WITH_XDEBUG_INTERCEPTOR_PROVIDER]),
    HeaderMessageService,
    ACCEPT_LANGUAGE_HEADER_INTERCEPTOR_PROVIDER,
    PRICELIST_HEADER_INTERCEPTOR_PROVIDER,
    CONFIGURATOR_SESSION_INTERCEPTOR_PROVIDER,
    SERVER_ERRORS_INTERCEPTOR,
    IMPERSONATED_USER_INTERCEPTOR,
    REDIRECTS_FACTORY_PROVIDER,
    ROLES_FACTORY_PROVIDER,
    VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY_PROVIDER,
    UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY_PROVIDER,
    UpdateService,
    { provide: ResizeNotifierService, useValue: ResizeNotifier },
    { provide: ROLES, useValue: UserRolesArray },
    { provide: PERMISSIONS, useValue: permissions },
    { provide: PermissionsUserService, useExisting: UserService },
    permissionsDecisionMakerServiceProvider,
    NgbActiveModal,
    MessageDetailsValueFormatPipe,
    PriceListCurrencyPipe,
    CurrencyPipe,
    SaleCoefficientFormatPipe,
    TitleCasePipe,
    DecimalPipe,
    LANGUAGE_INITIALIZER,
    APP_DATE_LOCALE_ID_PROVIDER,
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nDefault, deps: [APP_DATE_LOCALE_ID] },
    {
      provide: UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY,
      useValue: 'version',
    },
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideAngularSvgIcon()
  ],
}).catch((err) => console.log(err));
