import { Injectable } from '@angular/core';

import { OrderInterface, OrderListRowInterface } from '../core/models/order.model';
import { ConversationModalComponent } from './conversation-modal/conversation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageObjectType } from '../core/models/messages.model';

@Injectable({
  providedIn: 'root'
})
export class ConversationModalOpenerService {

  constructor(
    private modalService: NgbModal
  ) { }

  showMessagesModal(order: OrderInterface | OrderListRowInterface) {
    const modalRef = this.modalService.open(ConversationModalComponent, {
      size: 'lg'
    });
    const componentInstance: ConversationModalComponent = modalRef.componentInstance;
    componentInstance.orderId = order.id;
    componentInstance.newMessageCount = order.messagesCount;
    componentInstance.markMessagesAsSeen.subscribe(() => {
      order.messagesCount = 0;
    });
    componentInstance.object = { type: MessageObjectType.ORDER, title: order.title };
  }
}
