import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LanguageStoreHandlerService } from '../core/services/language/language-store-handler.service';
import { BreadcrumbModel } from '../shared/components/breadcrumbs/breadcrumb.model';
import { DropDownInterface } from '../shared/components/drop-down/drop-down.component';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import { AlertVariant } from '../shared/components/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-component-inventory',
  templateUrl: './component-inventory.component.html',
  styleUrls: ['./component-inventory.component.scss']
})
export class ComponentInventoryComponent implements OnInit {
  section$: Observable<string>;

  dispatchDate: string;
  language: DropDownInterface = {
    selected: {},
    values: [],
    onSelect: value => {
      this.onClick(value);
    },
  };
  breadcrumbs: BreadcrumbModel[] = [
    {title: 'Level 1', onClick: (item: BreadcrumbModel, event: MouseEvent) => {
      event.preventDefault();
      alert('Level 1');
    }},
    {title: 'Level 2', uri: '/news'},
    {title: 'Level 2'},
    {title: 'Current page title', isPageTitle: true},
  ];
  selection: any;
  filter = "";
  iconList = [];
  dealerList = [
    {
      id: 1213,
      name: "Some Name",
      code: "SC1002",
      hasOrders: true
    },
    {
      id: 1214,
      name: "Some2 Name2",
      code: "FF1008",
      hasOrders: false
    },
    {
      id: 1215,
      name: "Some3 Name3",
      code: "DD008",
      hasOrders: true
    },
    {
      id: 2222,
      name: "Disabled Option Test",
      code: "DD1123",
      hasOrders: false,
      disabled: true
    },
    {
      id: 5255,
      name: "Some5 Name5",
      code: "SOM333",
      hasOrders: true
    },
  ];

  selectedDealer = null;

  selectedDealer2 = {
    id: 1214,
    name: "Some2 Name2",
    code: "FF1008",
    hasOrders: false
  };

  variants: AlertVariant[] = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'gray',
    'dark-gray'
  ];
  selectedVariant: AlertVariant = 'primary';

  buttons = [
    {
      text: 'Cancel',
      classes: 'btn btn-outline-primary',
      action: () => {
        console.log("cancel");
      }
    },
    {
      text: 'Confirm',
      classes: 'btn btn-primary',
      action: () => {
        console.log("confirm");
      }
    }
  ];

  closeResult: string;

  constructor(
    private languageService: LanguageStoreHandlerService,
    private http: HttpClient,
    public route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.section$ = route.params.pipe(map(p => p.section))
  }

  ngOnInit(): void {
    this.languageService.getCurrentAsObservable().subscribe(lang => {
      this.language.selected = lang;
    });

    this.languageService.getAll().subscribe(languages => {
      this.language.values = languages;
    });

    this.http.get('assets/fonts/nspace-icons-icomoon/selection.json').subscribe((res) => {
      this.selection = res;
      this.selection.icons.forEach(element => {
        this.iconList.push({name: `ni-${element.properties.name}`, unicode: `\\${element.properties.code.toString(16)}`});
      });
    });
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe(f => {
      const element = document.querySelector("#" + f);
      if (element) element.scrollIntoView()
    });
  }

  public onDateChange(event: string) {
    this.dispatchDate = event;
  }

  public onClick(val: any) {
    console.log("onClick hit", val);
    // this.languageService.switch(language);
  }

  public iconListFiltered(filter: string) {
    return this.iconList.filter(item => item.name.indexOf(filter) !== -1 || item.unicode.indexOf(filter) !== -1);
  }

  public actionOneClickHandler(event: MouseEvent) {
    console.log("actionOneClickHandler hit", event);
  }

  openModal(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}
  getDismissReason(reason: any) {
    console.log('Modal dismissed', reason);
  }
}
