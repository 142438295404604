import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, combineLatest, skip, switchMap, tap } from 'rxjs';
import { SelectionMenuEntityEnum } from '../../core/enums/selection-menu-entity.enum';
import { UserRole } from '../../core/enums/user-role.enum';
import { OrderInterface } from '../../core/models/order.model';
import { PriceRequestInterface } from '../../core/models/price-request.model';
import { UserService } from '../../core/services/user/user.service';
import { ExtraListElementInterface } from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { SharedModule } from '../../shared/shared.module';
import { SelectionMenuComponent, TabTypes } from '../selection-menu.component';
import { SelectionMenuService } from '../selection-menu.service';

@Component({
    selector: 'app-selection-menu-toggler',
    templateUrl: './menu-toggler.component.html',
    imports: [SharedModule]
})
export class MenuTogglerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  selectedOrder: OrderInterface;
  selectedGroup: ExtraListElementInterface;
  selectedPriceRequest: PriceRequestInterface;
  selectionMenuEntity: SelectionMenuEntityEnum | null;

  userRole = UserRole;
  modalRef: NgbModalRef | null;
  selectionMenuEntities = SelectionMenuEntityEnum;
  isDealer = false;

  constructor(
    private selectionMenuService: SelectionMenuService,
    private modalService: NgbModal,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.selectionMenuService
        .openObservable()
        /* skip(1) ensures that Selection Menu wont't automatically open up upon initialization
        which otherwise could happen since we currently don't set open property of selectionMenuService
        to false when we close Selection Menu by pressing ESC or clicking outside */
        .pipe(skip(1))
        .subscribe((open) => {
          if (open) {
            this.openSelectionMenu();
          } else {
            this.modalRef?.close();
          }
        })
    );

    this.subscriptions.add(
      this.selectionMenuService.selectionMenuEntityAsObservable().pipe(
        switchMap((entity: SelectionMenuEntityEnum) => {
          this.selectionMenuEntity = entity;
  
          if (entity === SelectionMenuEntityEnum.PRICE_REQUEST) {
            return this.selectionMenuService.selectedPriceRequestAsObservable().pipe(
              tap((selectedPriceRequest) => {
                this.selectedPriceRequest = selectedPriceRequest;
              })
            );
          } 
          //If no entity or SelectionMenuEntityEnum.ORDER is selected
          return combineLatest([
            this.selectionMenuService.selectedOrderAsObservable(),
            this.selectionMenuService.selectedGroupAsObservable(),
          ]).pipe(
            tap(([selectedOrder, selectedGroup]) => {
              this.selectedOrder = selectedOrder;
              this.selectedGroup = selectedGroup;
            })
          );
        })
      ).subscribe()
    );

    this.isDealer = this.userService.isDealer();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onMenuOpenerClick() {
    this.selectionMenuService.open();
  }

  openSelectionMenu() {
    if(this.modalRef) {
      this.modalRef.close();
    }

    if(this.selectedOrder) {
      this.selectionMenuService.openOrder(this.selectedOrder);
    }

    this.modalRef = this.modalService.open(SelectionMenuComponent, {
      windowClass: 'sidebar-modal',
      modalDialogClass: 'position-fixed h-100 right-0',
    });
    
    const componentInstance = this.modalRef.componentInstance as SelectionMenuComponent;

    if (this.selectionMenuEntity === SelectionMenuEntityEnum.PRICE_REQUEST) {
      componentInstance.activeTab = TabTypes.PRICE_REQUESTS;
    }
  }

  getSelectedGroupTitle(selectedGroup: ExtraListElementInterface) {
    let title = [];
    if (selectedGroup) {
      title.push(selectedGroup.title);
      if (selectedGroup.parent) {
        title.push(...this.getSelectedGroupTitle(selectedGroup.parent).split(' / ').reverse());
      }
    }

    return title.reverse().join(' / ');
  }
}
