import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import { LanguageInterface, LanguageStoreHandlerService } from "../../../core/services/language/language-store-handler.service";
import { SharedModule } from "../../shared.module";

@Component({
    selector: 'app-language-switch',
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule]
})
export class LanguageSwitchComponent {
  @Input() cssClass = "";
  @Input() toggleClass = "btn text-uppercase";
  @Input() dropdownOpenToggleClass = '';
  @Input() upwards = false; 
  @Input() menuClass = "shadow-sm dropdown-menu-end";
  @Input() itemClass = "btn btn-secondary no-outline";
  @Input() userAuthenticated = true;

  selected$ = this.languageService.getCurrentAsObservable();
  languages$ = this.languageService.getAll();
  dropdownOpen = false;

  constructor(private languageService: LanguageStoreHandlerService) { }

  onSelect(value: LanguageInterface) {
    if (this.userAuthenticated) {
      this.languageService.send(value.short);
    }

    this.languageService.switch(value);
    location.reload(); // @todo: solve all untranslatable places and remove this line
  }

  onDropdownOpenChange(isOpen: boolean): void {
    this.dropdownOpen = isOpen;
  }
}
