import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private api: ApiService) {}

  fetch() {
    return this.api.get('currency/').pipe(map(({ data }) => data));
  }
}
