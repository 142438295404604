import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {UserInterface} from '../models/user.model';
import {UserRole} from '../enums/user-role.enum';
import {ImpersonateService} from '../impersonate/impersonate.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private authService: AuthService, private impersonateService: ImpersonateService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.impersonateService.impersonated()) {
      return true;
    }

    const user: UserInterface = this.authService.getUser();
    return user && user.role && user.role.name === UserRole.ROLE_ADMIN;
  }
}
