<div [ngClass]="materialListClass">
  <app-materials-list-item
    *ngFor="let material of materials"
    [material]="material"
    [materialOfOrderArticleNonRegularProduct]="materialsOfOrderArticleNonRegularProduct"
    [showMaterialCode]="showMaterialCode"
    [showMaterialTitle]="showMaterialTitle"
    [showMaterialImage]="showMaterialImage"
    [itemClass]="materialListItemClass"
    [pdfView]="pdfView"
    [class.mt-1]="pdfView"
  ></app-materials-list-item>
</div>
