<form *ngIf="form" [formGroup]="form" class="form">
  <app-inline-field>
    <label for="deliveryAddressee" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.DELIVERY_ADDRESSEE' | translate }}</label>
    <input formControlName="deliveryAddressee"
      id="deliveryAddressee"
      type="text"
      class="form-control"
      [placeholder]="shouldShowPlaceholders ? ('ADDRESS.FORM.DELIVERY_ADDRESSEE_EXPLANATION' | translate) : ''"
    >
    <ng-template *ngIf="getValidationErrors(form.get('deliveryAddressee')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
    <ng-template appInlineFieldInfoTemplate *ngIf="!shouldShowPlaceholders">
      <div class="mt-2 small">{{ 'ADDRESS.FORM.DELIVERY_ADDRESSEE_EXPLANATION' | translate }}</div>
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="address" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.ADDRESS' | translate }}</label>
    <input formControlName="address"
      name="address"
      id="address"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('address')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="postCode" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.ZIP_CODE' | translate }}</label>
    <input formControlName="postCode"
      name="postCode"
      id="postCode"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('postCode')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="city" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CITY' | translate }}</label>
    <input formControlName="city"
      name="city"
      id="city"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('city')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <ng-container *ngIf="!readOnly">
    <app-inline-field>
      <label for="country">{{ 'ADDRESS.FORM.COUNTRY' | translate }} *</label>
      <select formControlName="country"
        id="country"
        name="country"
        [compareWith]="compareCountry"
        class="form-control form-select"
      >
        <option [ngValue]="null" disabled>{{ 'ACTIONS.SELECT_OPTION' | translate }}</option>
        <option *ngFor="let country of countries" [ngValue]="country">{{ country.title || country.code }}</option>
      </select>
      <ng-template *ngIf="getValidationErrors(form.get('country')) as errors" appInlineFieldErrorsTemplate>
        {{ errors | translateValidationErrors }}
      </ng-template>
    </app-inline-field>
  </ng-container>
  <ng-container *ngIf="readOnly">
    <app-inline-field>
      <label for="countryDisabled" appInlineFieldLabel [hideRequired]="readOnly">{{ 'CLIENT.FORM.COUNTRY' | translate }}</label>
      <input formControlName="countryDisabled"
        name="countryDisabled"
        id="countryDisabled"
        class="form-control"
      >
    </app-inline-field>
  </ng-container>

  <app-inline-field>
    <label for="contactWorkingHours" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CONTACT_WORKING_HOURS' | translate }}</label>
    <input formControlName="contactWorkingHours"
      name="contactWorkingHours"
      id="contactWorkingHours"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('contactWorkingHours')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <!-- <app-inline-field>
    <label for="deliveryOptions" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.DELIVERY_OPTIONS' | translate }}</label>
    <select class="form-control form-select"
      formControlName="deliveryOption"
      id="deliveryOptions"
    >
      <option *ngIf="editing" [ngValue]="null" disabled hidden>{{ 'ADDRESS.FORM.PLEASE_SELECT' | translate }}</option>
      <option [ngValue]="deliveryOptions.STANDARD_DELIVERY">{{ 'ADDRESS.DELIVERY_OPTIONS.STANDARD_DELIVERY' | translate }}</option>
      <option [ngValue]="deliveryOptions.SPECIAL_DELIVERY">{{ 'ADDRESS.DELIVERY_OPTIONS.SPECIAL_DELIVERY' | translate }}</option>
    </select>
    <ng-template *ngIf="getValidationErrors(form.get('deliveryOption')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field> -->

  <div class="row">
    <div class="col">
      <h4 class="color-gray mt-4">{{ 'ADDRESS.FORM.CONTACT_FOR_DELIVERY' | translate }}</h4>
      <div class="form-h-spacer"></div>
    </div>
  </div>

  <app-inline-field>
    <label for="contactPerson" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CONTACT_PERSON' | translate }}</label>
    <input formControlName="contactPerson"
      name="contactPerson"
      id="contactPerson"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('contactPerson')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="email" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.EMAIL' | translate }}</label>
    <input formControlName="email"
      name="email"
      id="email"
      type="email"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('email')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="phone" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.PHONE_NUMBER' | translate }}</label>
    <input formControlName="phone"
      id="phone"
      name="phone"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('phone')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>
</form>
