<app-loader [loading]="loading" #loader [cssClasses]="'loader--white'"></app-loader>
<app-generic-modal [closable]="false" cssClasses="component-host-scrollable" [headerClasses]="'align-items-start'" class="component-host-scrollable">
  <ng-template appModalHeader>
    <h2 class="m-0 me-4">
      {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.TITLE' | translate }}
    </h2>
    <app-ui-elements-close-button class="ms-auto" [theme]="closeButtonTheme.MODAL" (click)="onCancel()"></app-ui-elements-close-button>
  </ng-template>
  <ng-template appModalBody>
    <div class="row row--custom-padding">
      <div class="col-12 px-4" *ngIf="quantityWarning">
        <div class="alert alert-danger d-flex justify-content-between py-2 px-3">
          <span>
            {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.QUANTITY_WARNING' | translate }}
          </span>
          <button *ngIf="!preview" (click)="togglePreview()" class="btn btn-link color-white ml-auto py-0" [disabled]="!parsed.length">
            {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PREVIEW' | translate }}
          </button>
        </div>
      </div>
      <div class="col-12 px-4" *ngIf="unitPriceWarning">
        <div class="alert alert-warning d-flex justify-content-between py-2 px-3">
          <span>
            {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.UNIT_PRICE_WARNING' | translate }}
          </span>
          <button *ngIf="!preview" (click)="togglePreview()" class="btn btn-link color-white ml-auto py-0" [disabled]="!parsed.length">
            {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PREVIEW' | translate }}
          </button>
        </div>
      </div>
      <div class="col-12 px-4 preview-block" *ngIf="preview">
        <table class="table table-responsive">
          <tr>
            <th class="py-2 ps-0 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.FULL_CODE' | translate }}</th>
            <th class="p-2 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.QUANTITY' | translate }}</th>
            <th class="p-2 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.TITLE' | translate }}</th>
            <th class="p-2 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.GROUP_SUBGROUP' | translate }}</th>
            <th class="p-2 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.PRICE' | translate }}</th>
            <th class="py-2 pe-0 color-gray small border-top-0">{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.FIELDS.DISCOUNT' | translate }}</th>
          </tr>
          <tr *ngFor="let recognizedRow of parsed">
            <td class="py-2 ps-0">{{ recognizedRow.fullCode }}</td>
            <td class="p-2" [ngClass]="{'bg-danger text-white': recognizedRow.quantity < 1}">{{ recognizedRow.quantity }}</td>
            <td class="p-2">{{ recognizedRow.title }}</td>
            <td class="p-2">{{ recognizedRow.group }}</td>
            <td class="p-2" [ngClass]="{'bg-warning text-white': recognizedRow.unitSalePrice < 1}">{{ recognizedRow.unitSalePrice }}</td>
            <td class="py-2 pe-0">{{ recognizedRow.discount }}</td>
          </tr>
        </table>
      </div>
      <div class="col-12 px-4 import-block" *ngIf="!preview">
        <form id="form" [formGroup]="form" class="content__inputs mb-3">
          <app-text-field
            [theme]="textFieldThemeTypes.TEXTAREA_INLINE"
            placeholder="{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PLACEHOLDER' | translate }}"
            title="{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PLACEHOLDER' | translate }}"
            class="flex-column"
            name="content"
            formControlName="content"
            [inputType]="{ type: 'textarea', collapse: false, minHeight: 194 }"
            [invalid]="form.controls['content'].invalid && form.controls['content'].dirty"
            [errors]="form.controls['content'].errors"
          >
          </app-text-field>

          <app-ui-elements-form-drop-down
            [values]="separators"
            [title]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.SEPARATOR' | translate"
            labelField="title"
            [selectedValue]="selectedSeparator"
            class="mb-3 d-block"
            (onChange)="onSeparatorChange($event)"
          ></app-ui-elements-form-drop-down>

          <label
            for="csvFileUploadInput"
            class="d-block fw-bold border-bottom color-gray pb-2 pt-4 mt-3 mb-3"
          >{{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.UPLOAD_CSV_FROM_PC' | translate }}</label>

          <button class="btn btn-light small fw-normal" (click)="onActivateFileInput($event)">{{ 'ACTIONS.CHOOSE_FILE' | translate }}</button>
          <span class="px-3" (click)="onActivateFileInput($event)">{{ filename ? filename : 'ACTIONS.NO_FILE_CHOSEN' | translate }}</span>
          <input
            class="d-none"
            id="csvFileUploadInput"
            type="file"
            (change)="onFileChangeListener($event)"
            accept=".csv,.CSV,.tsv,.TSV"
            title="{{ 'ACTIONS.CHOOSE_FILE' | translate }}"
          />
          <div *ngIf="incorrectFileType" class="mt-1 mb-3 text-danger">
            {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INCORRECT_FILE_TYPE' | translate }}
          </div>
        </form>

        <p class="pb-4 px-0 strong-black">
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW1' | translate"></span><br />
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW2' | translate"></span><br />
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW3' | translate"></span><br />
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW4' | translate"></span><br />
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW5' | translate"></span><br />
          <span [innerHTML]="'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.INSTRUCTIONS.ROW6' | translate"></span>
        </p>
      </div>
    </div>
  </ng-template>
  <ng-template appModalFooter>
    <button (click)="togglePreview()" class="btn me-auto btn-lg btn-light border-end w-25 no-outline" [disabled]="!parsed.length">
      {{ (preview ? 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PREVIEW_CLOSE' : 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.PREVIEW') | translate }}
    </button>
    <button (click)="onCancel()" class="btn btn-secondary btn-lg border-start w-25 no-outline">
      {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.CANCEL' | translate }}
    </button>
    <button (click)="onConfirm()" class="btn btn-primary btn-lg w-25 no-outline" [disabled]="(!preview && form.invalid) || !parsed.length || quantityWarning">
      {{ 'ORDER_PRODUCTS_LIST.IMPORT_CSV_MODAL.IMPORT' | translate }}
    </button>
  </ng-template>
</app-generic-modal>
