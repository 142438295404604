import {GeneralRoutePaths, InquiriesRoutePath, InquiriesRouteType, OrderRoutePath} from '../enums/route-types.enum';
import {CatalogueRouteType, OrderListRouteType, RouteTypeEnums} from '../enums/route-types.enum';
import {UserRole} from '../enums/user-role.enum';
import {CatalogueType} from '../enums/catalogue-type.enum';
import FeatureFlags from '../../../../featureFlags.json';

export interface NavbarElementPositionInterface {
  role: string;
  position: number;
}

export interface NavbarElementInterface {
  id: number;
  title: string;
  allowedRoles?: string[];
  children?: NavbarElementInterface[];
  sortable?: boolean;
  positions?: NavbarElementPositionInterface[];
  type?: number;
  path: string;
  externalPath?: string;
  externalPathTarget?: string;
  hideFromMenu?: boolean;
}

export const NavbarElements: NavbarElementInterface[] = [
  {
    title: 'NAVBAR.NEWS.TITLE',
    path: GeneralRoutePaths.NEWS,
    id: RouteTypeEnums.NEWS,
    children: []
  },
  {
    title: `NAVBAR.CATALOGUE.TITLE`,
    path: 'catalogue',
    id: RouteTypeEnums.CATALOGUE,
    sortable: false,
    children: [
      {
        title: 'NAVBAR.CATALOGUE.CATEGORY',
        path: 'category',
        id: CatalogueRouteType.CATEGORY,
        type: CatalogueType.CATEGORY,
      },
      {
        title: 'NAVBAR.CATALOGUE.SYSTEMS',
        path: 'systems',
        id: CatalogueRouteType.SYSTEMS,
        type: CatalogueType.SYSTEM,
      },
      {
        title: 'NAVBAR.CATALOGUE.COMPONENTS',
        path: 'components',
        id: CatalogueRouteType.COMPONENTS,
        type: CatalogueType.COMPONENT,
        hideFromMenu: true,
      },
    ],
  },
  {
    title: `NAVBAR.ORDERS.TITLE`,
    path: OrderRoutePath.ROOT,
    id: RouteTypeEnums.ORDERS,
    allowedRoles: [UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU],
    sortable: true,
    children: [
      {
        title: 'NAVBAR.ORDERS.OFFERS',
        path: OrderRoutePath.OFFERS,
        id: OrderListRouteType.OFFERS,
        allowedRoles: [UserRole.ROLE_DEALER],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 1},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.WAITING',
        path: OrderRoutePath.WAITING,
        id: OrderListRouteType.WAITING,
        allowedRoles: [UserRole.ROLE_PM, UserRole.ROLE_PM_RU],
        positions: [
          {role: UserRole.ROLE_PM, position: 1},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.ORDERS',
        path: OrderRoutePath.ORDERS,
        id: OrderListRouteType.ORDERS,
        allowedRoles: [UserRole.ROLE_PM_NARBUTAS],
        positions: [
          {role: UserRole.ROLE_PM_NARBUTAS, position: 1},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.PENDING',
        path: OrderRoutePath.PENDING,
        id: OrderListRouteType.PENDING,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_RU],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 2},
          {role: UserRole.ROLE_PM, position: 2},
          {role: UserRole.ROLE_PM_RU, position: 2},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.CONFIRMED',
        path: OrderRoutePath.CONFIRMED,
        id: OrderListRouteType.CONFIRMED,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_PM_NARBUTAS],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 3},
          {role: UserRole.ROLE_PM, position: 3},
          {role: UserRole.ROLE_PM_RU, position: 3},
          {role: UserRole.ROLE_PM_NARBUTAS, position: 2},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.LOADED',
        path: OrderRoutePath.LOADED,
        id: OrderListRouteType.LOADED,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_RU],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 4},
          {role: UserRole.ROLE_PM, position: 4},
          {role: UserRole.ROLE_PM_RU, position: 4},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.ARCHIVE',
        path: OrderRoutePath.ARCHIVE,
        id: OrderListRouteType.ARCHIVE,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_RU],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 5},
          {role: UserRole.ROLE_PM, position: 5},
          {role: UserRole.ROLE_PM_RU, position: 5},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.TRASH',
        path: OrderRoutePath.TRASH,
        id: OrderListRouteType.TRASH,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 6},
          {role: UserRole.ROLE_PM_NARBUTAS, position: 3},
        ],
      },
      {
        title: 'NAVBAR.ORDERS.SEARCH',
        path: OrderRoutePath.SEARCH,
        id: OrderListRouteType.SEARCH,
        allowedRoles: [null],
        positions: [
          {
            role: null,
            position: null,
          },
        ],
      },
    ],
  },
  {
    id: RouteTypeEnums.ESTIMATES,
    path: 'estimates',
    title: 'NAVBAR.ESTIMATES.LINK',
    children: [],
    allowedRoles: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_RU],
  },
  {
    id: RouteTypeEnums.INFO,
    path: 'info',
    title: 'NAVBAR.INFO.LINK',
    children: [],
    allowedRoles: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_RU],
  },
  {
    id: RouteTypeEnums.INQUIRIES,
    path: 'inquiries',
    title: 'NAVBAR.INQUIRIES.TITLE',
    sortable: true,
    children: [
      {
        title: 'NAVBAR.INQUIRIES.PROJECT_RESERVATIONS',
        path: InquiriesRoutePath.PROJECT_RESERVATIONS,
        id: InquiriesRouteType.PROJECT_RESERVATIONS,
        allowedRoles: [UserRole.ROLE_DEALER],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 1},
        ],
        hideFromMenu: !FeatureFlags.projectReservationsEnabled,
      },
      {
        title: 'NAVBAR.INQUIRIES.CUSTOM_MADE_PRICE_REQUESTS',
        path: InquiriesRoutePath.CUSTOM_MADE_PRICE_REQUESTS,
        id: InquiriesRouteType.CUSTOM_MADE_PRICE_REQUESTS,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 2},
          {role: UserRole.ROLE_PM, position: FeatureFlags.oldNonStandardsRestrictionsEnabled ? 1 : 2},
        ],
        hideFromMenu: !FeatureFlags.oldNonStandardsRestrictionsEnabled,
      },
      {
        title: 'NAVBAR.INQUIRIES.CLAIMS',
        path: InquiriesRoutePath.CLAIMS,
        id: InquiriesRouteType.CLAIMS,
        allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM],
        positions: [
          {role: UserRole.ROLE_DEALER, position: 3},
          {role: UserRole.ROLE_PM, position: FeatureFlags.oldNonStandardsRestrictionsEnabled ? 2 : 1},
        ],
      },
    ],
    allowedRoles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM],
  },
];
