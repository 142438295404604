<div class="mb-2"
     *ngFor="let propertyClass of propertyClasses"
     [attr.selenium_test]="propertyClass.code"
>
  <div class="border-bottom border-color-gray-400">
    <h4 class="border-color-gray-400
               fw-bold color-gray
               pt-3 pb-2 m-0"
    >{{ propertyClass.text }}</h4>
  </div>
  <app-default-properties [propertyClass]="propertyClass"
                          (propertyClassChange)="onPropertyChange($event)"></app-default-properties>
</div>
