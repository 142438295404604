import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ListModeSwitchService } from '../../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { ClickStopPropagationDirective } from '../../../../../shared/directives/click-stop-propagation/click-stop-propagation.directive';
import { TextareaAutosizeDirective } from '../../../../../shared/directives/textarea-autosize/textarea-autosize.directive';
import { SharedModule } from '../../../../../shared/shared.module';
import { CheckMarkComponent } from '../../../../../ui-elements/check-mark/check-mark/check-mark.component';
import { AddClassesIfCustomDiscountAppliedDirective } from '../../../custom-discount-applied/add-classes-if-custom-discount-applied.directive';
import { DragHandleDirective } from '../../../draggable/directives/ngx-drag-handle.directive';
import { OrderArticlesRowsService } from '../../../services/order-articles-rows/order-articles-rows.service';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { ExtraRowComponentModel } from '../../models/extra-row-component.model';
import { ExtraItemsService } from './extra-items.service';

@Component({
  selector: 'app-order-articles-list-extra-row',
  templateUrl: './extra-row.component.html',
  styleUrls: ['./extra-row.component.scss'],
  imports: [
    SharedModule,
    DragHandleDirective,
    ClickStopPropagationDirective,
    CheckMarkComponent,
    AddClassesIfCustomDiscountAppliedDirective,
    TextareaAutosizeDirective,
  ],
})
export class ExtraRowComponent extends ExtraRowComponentModel {
  constructor(
    protected fb: UntypedFormBuilder,
    protected extraListElementService: ExtraItemsService,
    protected selectedRowsService: SelectedRowsService,
    protected listModeSwitchService: ListModeSwitchService,
    protected orderArticlesRowsService: OrderArticlesRowsService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fb, extraListElementService, selectedRowsService, listModeSwitchService, orderArticlesRowsService, cdr);
  }

  onToggleSelect() {
    this.onSelect(!this.selected);
  }
}
