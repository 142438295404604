import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import {
  BatchOrderArticlesInterface,
  OrderArticleCreateInterface,
  OrderArticleInterface,
  OrderArticlesBatchUpdateInterface,
  OrderArticleUpdateInterface,
  OrderArticleUpdatePositionInterface,
  OtherSupplierOrderArticleCreateInterface,
  OtherSupplierOrderArticleInterface,
  OrderArticleFormItemType,
} from '../../models/order-article.model';
import {SaleMode} from "../../../shared/components/list-mode-switch/sale-mode.types";
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';

@Injectable({
  providedIn: 'root'
})
export class OrderArticleService {
  constructor(private api: ApiService, private listModeSwitchService: ListModeSwitchService) {}

  getOne(id: number, saleMode: SaleMode = SaleMode.ORDER): CacheableObservable<OrderArticleInterface | OtherSupplierOrderArticleInterface> {
    return this.api
      .get(`order-articles/${id}`, {'sale-mode': saleMode})
      .pipe(map(({ data }) => data)) as CacheableObservable<OrderArticleInterface>;
  }

  create(orderArticle: OrderArticleCreateInterface): Observable<OrderArticleInterface> {
    return this.api
      .post(`order-articles/`, orderArticle)
      .pipe(map(({ data }) => data));
  }

  update(id, orderArticle: OrderArticleUpdateInterface): Observable<OrderArticleInterface> {
    return this.api.post(`order-articles/${id}`, orderArticle);
  }

  updatePrice(id, orderArticle: OrderArticleUpdateInterface, saleMode: SaleMode = SaleMode.ORDER, isPreview: boolean = true): Observable<OrderArticleInterface> {
    return this.api.patch(
      `order-articles/${id}/price`,
      {...orderArticle},
      {'is-preview': +isPreview, 'sale-mode': saleMode}
    ).pipe(map(({ data }) => data));
  }

  updateMultiple(orderArticles: OrderArticleUpdatePositionInterface[]) {
    return this.api.patch('order-articles/multi/', orderArticles);
  }

  deleteMultiple(items: { id: number }[]): Observable<any> {
    return this.api.patch(`order-articles/multi/delete/`, items);
  }

  batchGet(ids: number[]): CacheableObservable<BatchOrderArticlesInterface> {
    let params = {};
    if (ids && ids.length > 0) {
      params = { 'ids[]': ids };
    }

    return this.api
      .get('order-articles/', params)
      .pipe(map(({ data }) => data)) as CacheableObservable<BatchOrderArticlesInterface>;
  }

  batchPrice(
    ids: number[],
    payload: OrderArticlesBatchUpdateInterface,
    isPreview = true
  ): Observable<BatchOrderArticlesInterface> {
    const params = {
      'sale-mode': this.listModeSwitchService.getSaleMode(),
      'is-preview': +isPreview,
    };

    if (ids && ids.length > 0) {
      params['ids[]'] = ids;
    }

    return this.api
      .patch('order-articles/price', payload, params)
      .pipe(map(({ data }) => data));
  }

  createCustomByItemType(item: OtherSupplierOrderArticleCreateInterface, itemType: OrderArticleFormItemType): Observable<OtherSupplierOrderArticleInterface> {
    const formData = this.api.buildFormData({ ...item, itemType });
    return this.api.post('order-articles/', formData);
  }

  updateCustomByItemType(
    id: number,
    item: OtherSupplierOrderArticleCreateInterface,
    itemType: OrderArticleFormItemType,
    skipNulls = true
  ): Observable<OtherSupplierOrderArticleInterface> {
    const data = { ...item, itemType };

    if (skipNulls) {
      for (const key in Object.keys(data)) {
        if (null === data[key]) {
          delete data[key];
        }
      }
    }

    const formData = this.api.buildFormData(data);
    return this.api.post(`order-articles/${id}`, formData);
  }
}
