import {Directive} from '@angular/core';

/**
 * Adds properties and events to drag handle elements
 *
 * @export
 */
@Directive({ selector: '[appDragHandle]' })
export class DragHandleDirective {
}
