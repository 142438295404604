import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { HeaderMessageService } from './header-message.service';
import { MessageType } from './message-type.enum';
import { MessageInterface } from './message.model';
import { MessageOptions } from './message-options.model';
import { CloseButtonComponent } from '../ui-elements/close-button/close-button.component';
import { Observable, Subscription, timer } from 'rxjs';
import { CloseButtonTheme } from '../ui-elements/close-button/close-button-theme.enum';
import { SharedModule } from '../shared/shared.module';

@Component({
    selector: 'app-header-message',
    templateUrl: './header-message.component.html',
    styleUrls: ['./header-message.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule]
})
export class HeaderMessageComponent implements OnInit, OnDestroy {
  @ViewChild('buttonComponent', { read: ViewContainerRef, static: true }) buttonComponent: ViewContainerRef;

  messageTypes = MessageType;
  currentMessage: MessageInterface;
  private subscription: Subscription;
  private timer: Observable<any>;
  closing = false;

  constructor(private headerMessageService: HeaderMessageService, private cfr: ComponentFactoryResolver) {}

  ngOnInit() {
    this.headerMessageService.getMessageSubject().subscribe((message: MessageInterface) => {
      if (message) {
        message.options = { ...new MessageOptions(), ...message.options };
        this.currentMessage = <MessageInterface>{ text: message.text, type: message.type, options: message.options };

        if (message.options.closable && !message.options.buttonComponent) {
          message.options.buttonComponent = CloseButtonComponent;
          message.options.onButtonClick = () => {
            this.close();
          };
        }

        if (message.options.buttonComponent) {
          this.buttonComponent.clear();
          const factory = this.cfr.resolveComponentFactory(message.options.buttonComponent);
          const instance: any = this.buttonComponent.createComponent(factory).instance as any;
          instance.theme = CloseButtonTheme.HEADER_MESSAGE;
          instance.clicked.subscribe(() => {
            message.options.onButtonClick();
          });
        }

        if (message.options.autoClose) {
          this.setTimer(message.options.closeAfter || 6000);
        }
      } else {
        this.close();
      }
    });
  }

  setTimer(time) {
    this.stopCurrentTimer();
    this.timer = timer(time);
    this.subscription = this.timer.subscribe(() => {
      this.close();
    });
  }

  close() {
    this.closing = true;
    timer(500).subscribe(() => {
      this.closing = false;
      this.currentMessage = null;
    });
  }

  private stopCurrentTimer() {
    if (typeof this.subscription !== 'undefined') {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.stopCurrentTimer();
  }
}
