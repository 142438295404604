import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProductArticleInterface } from './products/product.model';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';
import { Params } from '@angular/router';
import { ListModeSwitchService } from '../shared/components/list-mode-switch/list-mode-switch.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {
  constructor(
    private api: ApiService,
    private listModeSwitchService: ListModeSwitchService,
  ) {}

  getArticlesBySystemId(id: number, filterParams: Params = {}): CacheableObservable<ProductArticleInterface[]> {
    let params = this.convertFilters(filterParams);
    params = params.append('sale-mode', this.listModeSwitchService.getSaleMode().toString());

    return this.api.get(`articles/category/${id}`, params).pipe(
      map(({ data }) => data)
    ) as CacheableObservable<ProductArticleInterface[]>;
  }

  getArticleComponentsByCode(code: string) {
    return this.api.get(`article/${code}/components`).pipe(map(({ data }) => data));
  }

  getCatalogueExportUrl(id: number, format: string, filters?) {
    let params = this.convertFilters(filters);
    params = params.append('format', format);
    return this.api.download(`articles/category/${id}/export`, `catalogue_${id}_${Date.now()}.${format}`, params);
  }

  getCatalogueSearchExportUrl(query: string, format: string, filters?) {
    const fileName = `catalogue_search_${query.replace(' ', '_').toLowerCase()}_${Date.now()}.${format}`;
    let params = this.convertFilters(filters);
    params = params.append('format', format);
    return this.api.download(`articles/search/${query}/export`, fileName, params);
  }

  private convertFilters(filters: Params): Params {
    let params = new HttpParams(filters);
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (!Array.isArray(value)) {
        value = [value];
      }
      params = params.append(key, value.join(','));
    });
    return params;
  }
}
