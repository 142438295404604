import { Component, Input } from '@angular/core';
import { PriceRequestItemInterface, PriceRequestItemTypeEnum } from '../../../../core/models/price-request-item.model';
import { SharedModule } from '../../../../shared/shared.module';
import { FilePreviewComponent } from '../../../../ui-elements/file-preview/file-preview.component';
import { OrderArticleMaterialInputComponent } from '../../../../ui-elements/order-article-material-input/order-article-material-input.component';
import { PriceRequestItemArticlesTableComponent } from '../../price-request-item-articles-table/price-request-item-articles-table.component';

@Component({
    selector: 'app-price-request-item-modal',
    templateUrl: './price-request-item-modal.component.html',
    styleUrls: ['./price-request-item-modal.component.scss'],
    imports: [SharedModule, PriceRequestItemArticlesTableComponent, OrderArticleMaterialInputComponent, FilePreviewComponent]
})
export class PriceRequestItemModalComponent {
  @Input() item: PriceRequestItemInterface;

  priceRequestItemTypes = PriceRequestItemTypeEnum;
}
