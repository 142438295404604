import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private translator: TranslateService) {}

  transform(value): Observable<string | any> {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return EMPTY;
    } else if (seconds <= 10) {
      return this.translator.get('TIME_AGO.JUST_NOW');
    } else if (seconds <= 45) {
      return this.translator.get('TIME_AGO.SECONDS_AGO');
    } else if (seconds <= 90) {
      return this.translator.get('TIME_AGO.MINUTE_AGO');
    } else if (minutes <= 45) {
      return this.translator.get('TIME_AGO.MINUTES_AGO', { minutes: minutes });
    } else if (minutes <= 90) {
      return this.translator.get('TIME_AGO.HOUR_AGO');
    } else if (hours <= 22) {
      return this.translator.get('TIME_AGO.HOURS_AGO', { hours: hours });
    } else if (hours <= 36) {
      return this.translator.get('TIME_AGO.DAY_AGO');
    } else if (days <= 25) {
      return this.translator.get('TIME_AGO.DAYS_AGO', { days: days });
    } else if (days <= 45) {
      return this.translator.get('TIME_AGO.MONTH_AGO');
    } else if (days <= 345) {
      return this.translator.get('TIME_AGO.MONTHS_AGO', { months: months });
    } else if (days <= 545) {
      return this.translator.get('TIME_AGO.YEAR_AGO');
    } else {
      // (days > 545)
      return this.translator.get('TIME_AGO.YEARS_AGO', { years: years });
    }
  }

  ngOnDestroy() {
    this.removeTimer();
  }

  removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  getSecondsUntilUpdate(seconds) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
