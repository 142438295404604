import { forwardRef, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap, retryWhen, delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../ui-elements/toast/toast.service';
import { EXCLUDED_ENDPOINTS } from './server-errors-interceptor.constants';

enum forbiddenErrorCode {
  ORDER_ALREADY_SENT_TO_AX = 1,
  UNDEFINED_STATUS = 2,
  SENT_TO_AX_RETRY_TIME_INTERVAL = 3,
}

export enum API_ERROR_CODES {
  DEFAULT_MAINTENANCE = 'ER_100001#005',
  DEFAULT_ACCESS_DENIED = 'ER_100001#004',
  DEFAULT_PAGE_NOT_FOUND = 'ER_100001#003',
}

export enum ERROR_STATUSES {
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  ACCESS_DENIED = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  MAINTENANCE = 503,
}

export const SERVER_ERRORS_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => ServerErrorsInterceptor),
  multi: true,
};

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translator: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const isExcluded = EXCLUDED_ENDPOINTS.some((endpoint) => endpoint.method === req.method && endpoint.pattern.test(req.url));

    if (isExcluded) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      retryWhen((result) => this.retryWhen(result)),
      tap(
        () => {},
        (errorResponse: any) => this.handleError(errorResponse)
      )
    );
  }

  private retryWhen(result: Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>>) {
    return result.pipe(
      tap((errorResponse: any) => {
        // will try again in 1s
        if (errorResponse.status !== ERROR_STATUSES.NOT_MODIFIED) {
          throw errorResponse;
        }
      }),
      delay(1000)
    );
  }

  private handleError(errorResponse: any): void {
    if (!(errorResponse instanceof HttpErrorResponse)) {
      return;
    }

    const { error: data } = errorResponse;
    let translationObservable: Observable<string>;

    switch (errorResponse.status) {
      case ERROR_STATUSES.BAD_REQUEST:
        return;

      case ERROR_STATUSES.PAYMENT_REQUIRED:
      case ERROR_STATUSES.INTERNAL_SERVER_ERROR:
        translationObservable = this.translator.get(`ERRORS.SOMETHING_WENT_WRONG.BIG`);
        break;

      case ERROR_STATUSES.NOT_FOUND:
        translationObservable = this.translator.get(`ERRORS.PAGE_NOT_FOUND.BIG`);
        break;

      case ERROR_STATUSES.ACCESS_DENIED:
        translationObservable = this.getTranslationForForbiddenError(data);
        break;

      case ERROR_STATUSES.MAINTENANCE:
        if (errorResponse.error.error === API_ERROR_CODES.DEFAULT_MAINTENANCE) {
          this.router.navigate(['/maintenance']);
        }
        break;
    }

    if (translationObservable) {
      translationObservable
        .pipe(
          tap(message => {
            this.toastService.danger(message);
          })
        )
        .subscribe();
    }
  }

  private getTranslationForForbiddenError(data: any): Observable<string> {
    let translationKey = 'ERRORS.FORBIDDEN';
    if (typeof data.errorCode !== 'undefined') {
      translationKey = `ERRORS.BY_ERROR_CODE.${data.error}`;
    }

    return this.translator.get(translationKey).pipe(
      switchMap(translation => {
        let message;
        switch (data.errorCode) {
          case forbiddenErrorCode.ORDER_ALREADY_SENT_TO_AX:
            message = translation['ORDER_ALREADY_SENT_TO_AX'];
            break;
          case forbiddenErrorCode.UNDEFINED_STATUS:
            message = translation['UNDEFINED_STATUS'];
            break;
          case forbiddenErrorCode.SENT_TO_AX_RETRY_TIME_INTERVAL:
            message = translation['SENT_TO_AX_RETRY_TIME_INTERVAL'];
            break;
          default:
            message = translation['DEFAULT'];
        }

        return of(message);
      })
    );
  }
}
