<div class="payment-conditions">
  <div class="row mb-3">
    <div class="col px-3 text-uppercase">{{ 'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TABLE.DESCRIPTION' | translate }}</div>
    <div class="col px-3 text-uppercase">{{ 'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TABLE.PAY_UP_TO' | translate }}</div>
    <div class="col px-3 text-uppercase">%</div>
    <div class="col px-3 text-uppercase">{{ currency || order.currency?.name }}</div>
  </div>
  <ng-container [formGroup]="form">
    <ng-container
      *ngFor="let formGroup of form.get('payments')['controls']; let i = index"
    >
      <ng-container [formGroup]="formGroup">
        <div class="row align-items-start">
          <div class="col px-3">
            <app-text-field
              [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
              [name]="'orderPaymentTitle-' + i"
              [placeholder]="'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TABLE.PAYMENT_TEXT' | translate"
              [title]="'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TABLE.PAYMENT_TEXT' | translate"
              [formControl]="formGroup.get('orderPaymentTitle')"
            >
            </app-text-field>
          </div>
          <div class="col px-3">
            <app-date-input
              [formControl]="formGroup.get('orderPaymentDueDate')"
              [title]="'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TABLE.PAY_UP_TO' | translate"
              [id]="'orderPaymentDueDate' + i"
            ></app-date-input>
          </div>
          <div class="col px-3">
            <app-text-field
              [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
              [formControl]="formGroup.get('orderPaymentPercentage')"
              title="%"
              [name]="'orderPaymentPercentage-' + i"
              type="number"
              [placeholder]="'0-100'"
            >
            </app-text-field>
          </div>
          <div class="col px-3 d-flex justify-content-between align-items-center">
            <span class="w-100 py-2 px-2 bg-gray-100 me-3 cursor-not-allowed">
              <span class="px-1">
                {{ formGroup.get('orderPaymentPrice').value * exchangeRate | priceFormat }}
              </span>
            </span>
            <button class="btn bg-gray-100 hover-color-black d-flex align-items-center p-2 ms-3" (click)="onRemove(i)">
              <i class="icon ni-cancel"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="row" formArrayName="payments" *ngIf="form.get('payments')['controls'].length > 1">
      <div class="col px-3"></div>
      <div class="col px-3"></div>
      <div class="col px-3 payment-conditions-table__col--computed-value">
        <span class="px-2" [ngClass]="{ warning: totalValues.percent > maxPercentageValue }">
          <span class="px-0125r">
            <span class="px-1">{{ totalValues.percent | priceFormat }}</span>
          </span>
        </span>
      </div>
      <div class="col px-3 payment-conditions-table__col--price payment-conditions-table__col--computed-value">
        <span class="px-2">
          <span class="px-1">{{ +totalValues.price * +exchangeRate | priceFormat }}</span>
        </span>
      </div>
    </div>
  </ng-container>
  <button class="btn btn-link d-flex align-items-center ps-0 ms-3" (click)="onAddRow()">
    <i class="icon ni-add-circle-outline me-3"></i>
    <span>
      {{ 'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.NEW_ROW' | translate }}
    </span>
  </button>
</div>
