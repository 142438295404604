<app-generic-modal bodyClasses="p-0 bg-gray-200 d-flex flex-column"
  cssClasses="d-flex flex-column vh-100">
  <ng-template appModalHeader>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col">
          <div class="d-flex align-items-center">
            <h1 class="m-0">{{ 'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.TITLE' | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template appModalBody>
    <div class="d-flex flex-column h-100">
      <div class="bg-white row g-0 border-bottom">
        <div class="col">
          <div class="d-flex justify-content-between px-2">
            <ul class="nav nav-pills d-flex pe-3 m-0 me-2">
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.DETAILS)">
                <span class="nav-link d-flex gap-1 fw-bold" [ngClass]="{'active': activeTab === tabs.DETAILS}">
                  <i class="icon ni-clipboard"></i>
                  {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.TABS.DETAILS" | translate }}
                </span>
              </li>
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.COMMUNICATION)">
                <span class="nav-link d-flex gap-1 fw-bold" [ngClass]="{'active': activeTab === tabs.COMMUNICATION}">
                  <i class="icon ni-chat-square-plain position-relative">
                    <app-notification data-testid="unseenMessagesCount" [notifications]="item.unseenMessagesCount" />
                  </i>
                  {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.TABS.COMMUNICATION" | translate }}
                </span>
              </li>
            </ul>
            <div class="d-flex align-items-center px-3 gap-4">
              <div *ngIf="item.jiraIssueKey">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ID" | translate }}: <span class="color-black fw-bold">{{ item.jiraIssueKey
                  }}</span></div>
              <app-project-reservation-status [row]="item"></app-project-reservation-status>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content position-relative pt-0 h-100">
        <ng-container [ngSwitch]="activeTab">
          <div *ngSwitchCase="tabs.DETAILS" class="container-fluid pb-4r bg-white border-bottom">
            <div class="row px-3">
              <div class="col d-flex justify-content-between border-bottom py-3 align-items-center">
                <span class="h1 mb-0 fw-bold">{{ item.title }} {{ item.type ? ('(' + ((projectTypesEnum[item.type]) | translate | lowercase) + ')') : '' }}</span>
                <span class="fw-bold color-gray-900">{{ item.country.title }}, {{ item.city }}</span>
              </div>
            </div>

            <!-- dynamic stuff by project status -->
            <div class="row pt-4">
              <div class="col-10 offset-1 px-3">
                <div class="alert border-0 rounded-3 p-4" [ngClass]="alertsByStatus[item.status]">
                  <ng-container [ngSwitch]="item.status">
                    <div *ngSwitchCase="projectReservationStatuses.OPEN_TENDER">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.OPEN_TENDER.HEADING" | translate }}</p>
                      <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.OPEN_TENDER.SUBHEADING" | translate }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.CONFIRMED">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.CONFIRMED.HEADING" | translate }}</p>
                      <p *ngIf="item.conditions?.length" class="color-gray-900 mb-4">{{ item.conditions }}</p>
                      <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.CONFIRMED.SUBHEADING" | translate }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.NEED_TO_REVIEW">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.NEED_TO_REVIEW.HEADING" | translate }}</p>
                      <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.NEED_TO_REVIEW.SUBHEADING" | translate }}</p>
                      <p *ngIf="item.conditions?.length" class="color-gray-900 mb-4">{{ item.conditions }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.REJECTED">
                      <p class="h2 fw-bold mb-0">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.REJECTED.HEADING" | translate }}</p>
                      <p class="mt-4 mb-0" *ngIf="item.rejectionReasons?.length">{{ item.rejectionReasons }}</p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.LOST">
                      <p class="h2 fw-bold mb-0">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.LOST.HEADING" | translate }}</p>
                      <div *ngIf="!item.lostReasons" class="mt-4">
                        <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.LOST.SUBHEADING" | translate }}</p>
                        <form [formGroup]="form" (submit)="onFeedbackSubmit()">
                          <app-project-reservation-lost-feedback formControlName="feedback" />
                          <button
                            [disabled]="!isFeedbackProvided"
                            class="btn btn-primary"
                            type="submit"
                          >{{ 'INQUIRIES.PROJECT_RESERVATIONS.ACTIONS.SUBMIT' | translate }}</button>
                        </form>
                      </div>
                      <div *ngIf="item.lostReasons" class="mt-4">
                        <p class="color-gray-900 mb-0">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.LOST.SUBHEADING_SUBMITTED" | translate }}</p>
                      </div>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.WON">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.WON.HEADING" | translate }}</p>
                      <div class="d-flex gap-3 mb-4 align-items-start">
                        <button
                          *ngIf="item.jiraIssueKey?.length"
                          type="button"
                          class="btn btn-lg bg-success-200 rounded-3 d-flex align-items-center gap-2 px-3 py-2 text-nowrap color-gray-900 h1"
                          (click)="onCopyToClipboard(item.jiraIssueKey)"
                          [ngbTooltip]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.COPIED_TOOLTIP' | translate"
                          triggers="click:mouseout"
                        >
                          <span>{{ item.jiraIssueKey }}</span>
                          <i class="icon ni-copy"></i>
                        </button>
                        <p class="color-gray-900 mb-0">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.WON.SUBHEADING" | translate }}</p>
                      </div>
                      <p *ngIf="item.conditions?.length" class="color-gray-900 mb-4">{{ item.conditions }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.PARTIALLY_APPLIED">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.PARTIALLY_APPLIED.HEADING" | translate }}</p>
                      <div class="d-flex gap-3 mb-4 align-items-start">
                        <button
                          *ngIf="item.jiraIssueKey?.length"
                          type="button"
                          class="btn btn-lg bg-success-200 rounded-3 d-flex align-items-center gap-2 px-3 py-2 text-nowrap color-gray-900 h1"
                          (click)="onCopyToClipboard(item.jiraIssueKey)"
                          [ngbTooltip]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.COPIED_TOOLTIP' | translate"
                          triggers="click:mouseout"
                        >
                          <span>{{ item.jiraIssueKey }}</span>
                          <i class="icon ni-copy"></i>
                        </button>
                        <p class="color-gray-900 mb-0">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.PARTIALLY_APPLIED.SUBHEADING" | translate }}</p>
                      </div>
                      <p *ngIf="item.conditions?.length" class="color-gray-900 mb-4">{{ item.conditions }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.APPLIED">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.APPLIED.HEADING" | translate }}</p>
                      <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.APPLIED.SUBHEADING" | translate }}</p>
                      <p *ngIf="item.conditions?.length" class="color-gray-900 mb-4">{{ item.conditions }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                    <div *ngSwitchCase="projectReservationStatuses.EXPIRED">
                      <p class="h2 fw-bold mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.EXPIRED.HEADING" | translate }}</p>
                      <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.EXPIRED.SUBHEADING" | translate }}</p>
                      <p class="mb-0 color-gray-700">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.EXPIRES" | translate }}: <span class="fw-bold">{{ item.expirationDateTimestamp * 1000 | i18nDate }}</span></p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div *ngIf="!isOutcomeProvided" class="row pt-4">
              <div class="col-10 offset-1 px-3">
                <p class="color-gray-500 mb-3">
                  {{ 'INQUIRIES.PROJECT_RESERVATIONS.OUTCOME.QUESTION' | translate }}
                </p>
                <div class="d-flex gap-1">
                  <button (click)="onMarkAsLost()" type="button" class="btn hover-bg-danger-600 bg-danger text-white align-items-center d-inline-flex gap-2">
                    <i class="icon ni-trash"></i>
                    <span>{{ 'INQUIRIES.PROJECT_RESERVATIONS.ACTIONS.MARK_AS_LOST' | translate }}</span>
                  </button>
                  <button
                    *ngIf="item.status !== projectReservationStatuses.EXPIRED"
                    (click)="onMarkAsWon()"
                    type="button"
                    class="btn hover-bg-success bg-success-600 color-white align-items-center d-inline-flex gap-2"
                  >
                    <i class="icon ni-check-circle"></i>
                    <span>{{ 'INQUIRIES.PROJECT_RESERVATIONS.ACTIONS.MARK_AS_WON' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="isOutcomeProvided && isStatusWithOutcome" class="row pt-4">
              <div class="col-10 offset-1 px-3 d-flex flex-column gap-2 form-group">
                <label for="outcomeAnswered">{{ "INQUIRIES.PROJECT_RESERVATIONS.OUTCOME.QUESTION" | translate }}</label>
                <span *ngIf="isWon" id="outcomeAnswered" class="color-gray-900">{{ "INQUIRIES.PROJECT_RESERVATIONS.OUTCOME.WON" | translate }}</span>
                <span *ngIf="isLost" id="outcomeAnswered" class="color-gray-900">{{ "INQUIRIES.PROJECT_RESERVATIONS.OUTCOME.LOST" | translate }}</span>
              </div>
            </div>

            <div class="row pt-4">
              <div class="col-5 offset-1 d-flex flex-column gap-2 form-group">
                <label for="value">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.PROJECT_VALUE" | translate : { currency_symbol: userCurrencySymbol } }}</label>
                <span id="value" class="fw-bold color-gray-900">{{ item.value | priceListCurrency: 'symbol':'1.0':item.currency.name }}</span>
              </div>
              <div class="col-5 d-flex flex-column gap-2 form-group">
                <label for="dealerValue">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.PROJECT_DEALERS_VALUE" | translate : { currency_symbol: userCurrencySymbol } }}</label>
                <span id="dealerValue" class="fw-bold color-gray-900">{{ item.dealerValue | priceListCurrency: 'symbol':'1.0':item.currency.name }}</span>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-5 offset-1 d-flex flex-column gap-2 form-group">
                <label for="workspaceCount">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.WORKPLACE_COUNT" | translate }}</label>
                <span id="workspaceCount" class="fw-bold color-gray-900">{{ item.workplaceCount }}</span>
              </div>
              <div class="col-5 d-flex flex-column gap-2 form-group">
                <label for="architect">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.PROJECT_ARCHITECT" | translate }}</label>
                <span id="architect" class="fw-bold color-gray-900">{{ item.architect }}</span>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-5 offset-1 d-flex flex-column gap-2 form-group">
                <label for="value">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ESTIMATED_PROJECT_WIN_DATE" | translate }}</label>
                <span id="value" class="color-gray-900 fw-bold">{{ item.estimatedWinDateTimestamp * 1000 | i18nDate }}</span>
              </div>
              <div class="col-5 d-flex flex-column gap-2 form-group">
                <label for="executionDate">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.PROJECT_EXECUTION_DATE" | translate }}</label>
                <span id="executionDate" class="color-gray-900 fw-bold">{{ item.executionDateTimestamp * 1000 | i18nDate }}</span>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-10 offset-1 d-flex flex-column gap-2 form-group">
                <label>{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.PRODUCT_SYSTEMS" | translate }}</label>
                <div class="system-selection pt-2" *ngIf="item.productSystems.length">
                  <div class="d-inline-flex align-items-center
                    bg-gray-200
                    rounded-2
                    p-2 me-2 mb-2"
                    *ngFor="let system of item.productSystems"
                    [title]="system"
                  >
                    <span class="text-truncate small px-1 color-gray-900">{{ system }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-10 offset-1 d-flex flex-column gap-2 form-group">
                <label for="description">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.DESCRIPTION" | translate }}</label>
                <span id="description" class="color-gray-900 text-break--all">{{ item.description || "-" }}</span>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-10 offset-1 d-flex flex-column gap-2 form-group">
                <label for="contactInformation">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.CONTACT_INFORMATION" | translate }}</label>
                <span id="contactInformation" class="color-gray-900 text-break--all">{{ item.contactInformation || "-" }}</span>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-10 offset-1 d-flex flex-column gap-2 form-group">
                <label for="file">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ATTACHMENTS" | translate }}</label>
                <ng-scrollbar>
                  <div class="d-flex flex-sm-wrap gap-1 mt-2 mb-2">
                    <app-file-preview
                      *ngFor="let file of item.projectReservationFiles; let i = index"
                      [file]="file.file"
                      [removable]="false"
                    ></app-file-preview>
                  </div>
                </ng-scrollbar>
              </div>
            </div>
            <div class="row pt-4" *ngIf="isUpcomingOrdersCountVisible">
              <div class="col-10 offset-1 d-flex flex-column gap-2 form-group">
                <label for="upcommingOrders">{{ "INQUIRIES.PROJECT_RESERVATIONS.NEW.UPCOMING_ORDERS_COUNT" | translate }}</label>
                <span id="upcommingOrders" class="color-gray-900">{{ item.upcomingOrdersCount || "-" }}</span>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="tabs.COMMUNICATION" class="h-100 bg-white">
            <app-project-reservation-communication
              [projectReservationId]="item.id"
              [projectReservationStatus]="item.status"
            ></app-project-reservation-communication>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</app-generic-modal>
