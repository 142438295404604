import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { OrderArticleSaleCoefficientType } from '../../../core/enums/order-article-discount-type.enum';
import { UserRole } from '../../../core/enums/user-role.enum';
import { ORDER_ARTICLE_DISCOUNT_TYPE, OrderArticleInterface } from '../../../core/models/order-article.model';
import { UserInterface } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user/user.service';
import { SharedModule } from '../../../shared/shared.module';
import { UserConfigurationService } from '../../configurator-modal-configuration/user-configuration.service';

export interface PriceDetailsInterface {
  price: number;
  otherItemsCount: number;
  otherItemsTotalPrice: number;
  previousPrice?: number;
  previousCount?: number;
  orderArticles: OrderArticleInterface[];
  discount: number;
  totalPrice: number;
  selectedItemTotalPrice: number;
  selectedItemIsPlaceholder: boolean;
  selectedItemTitle: string;
}

/**
 * @TODO: Figure out easier way to have different states: 1 opener hovered, whole tooltip muted, tooltip CLOSED, tooltip opened (many more I guess)
 */
@Component({
    selector: 'app-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule]
})
export class PriceDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('popover', {static: false}) popover: NgbPopover;

  @Output() detailedViewClicked: EventEmitter<void> = new EventEmitter();

  @Input() details: PriceDetailsInterface;

  open = false;
  muted = false;
  showWarning = false;

  subscription: Subscription = new Subscription();
  user: UserInterface;

  constructor(
    private modalService: NgbModal,
    private configuratorModalUserConfiguration: UserConfigurationService,
    private userService: UserService
  ) {
    const configuration = this.configuratorModalUserConfiguration.load();
    this.muted = !configuration.shouldShowPriceChanges;
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.userService.fromStorage().subscribe(user => {
        this.user = user;
        if (this.details.otherItemsCount === 0) {
          this.popover?.close();
          this.open = false;
        } else if (!this.muted) {
          if (this.details.price !== this.details.previousPrice || this.details.orderArticles.length !== this.details.previousCount) {
            this.popover?.open();
            this.open = true;
          }
        }

        this.showWarning = this.shouldShowWarning();
      });
    }
  }


  onMute() {
    this.popover.close();
    this.muted = true;

    this.configuratorModalUserConfiguration.update({
      shouldShowPriceChanges: false,
    });
  }

  onUnmute() {
    this.muted = false;

    this.configuratorModalUserConfiguration.update({
      shouldShowPriceChanges: true,
    });
  }

  onDetailedView() {
    this.detailedViewClicked.emit();
  }

  private shouldShowWarning(): boolean {
    if (this.user) {
      switch (this.user.role.name) {
        case UserRole.ROLE_PM_NARBUTAS:
          return !!this.details.orderArticles.find(
            orderArticle =>
              !!orderArticle.customPrice || (orderArticle.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM && orderArticle.discount > 0)
          );
        case UserRole.ROLE_PM:
          return !!this.details.orderArticles.find(
            orderArticle => !!orderArticle.customPrice || orderArticle.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM
          );
        case UserRole.ROLE_DEALER:
          return !!this.details.orderArticles.find(
            orderArticle => orderArticle.saleCoefficientType === OrderArticleSaleCoefficientType.CUSTOM
          );
      }
    }

    return false;
  }

  ngAfterViewInit(): void {
    if (this.open) {
      this.popover.open();
    }
  }
}
