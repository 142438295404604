import {Component, OnInit} from '@angular/core';
import {RedirectorService} from '../../role-redirector/redirector.service';
import {Router} from '@angular/router';
import {UserService} from '../services/user/user.service';
import {UserInterface} from '../models/user.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private redirectorService: RedirectorService,
    private router: Router,
    private userService: UserService) {
  }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.redirectorService.resolveRouteParams(user.role.name).subscribe((url: any[]) => {
        this.router.navigate(url);
      });
    });
  }

}
