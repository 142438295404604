import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ProjectReservationInterface, ProjectReservationStatusEnum, ProjectReservationStatusTranslationMap } from '../../../../core/models/project-reservation.model';

@Component({
    selector: 'app-project-reservation-status',
    templateUrl: './project-reservation-status.component.html',
    imports: [TranslatePipe]
})
export class ProjectReservationStatusComponent {
  @Input() row: ProjectReservationInterface;

  statusTranslations = ProjectReservationStatusTranslationMap;
  statusBackgroundColor = {
    [ProjectReservationStatusEnum.DRAFT]: 'gray-600',
    [ProjectReservationStatusEnum.LOST]: 'gray-600',
    [ProjectReservationStatusEnum.WON]: 'success-600',
    [ProjectReservationStatusEnum.PARTIALLY_APPLIED]: 'success-600',
    [ProjectReservationStatusEnum.EXPIRED]: 'gray-600',
    [ProjectReservationStatusEnum.APPLIED]: 'gray-600',
    [ProjectReservationStatusEnum.NEED_TO_REVIEW]: 'warning-600',
    [ProjectReservationStatusEnum.CONFIRMED]: 'success-600',
    [ProjectReservationStatusEnum.OPEN_TENDER]: 'success-600',
    [ProjectReservationStatusEnum.REJECTED]: 'gray-600',
    [ProjectReservationStatusEnum.WAITING_FOR_RESPONSE]: 'warning-600',
    [ProjectReservationStatusEnum.NEW]: 'primary-600',
    [ProjectReservationStatusEnum.IN_PROGRESS]: 'primary-600',
  };
}
