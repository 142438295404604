import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';

@Component({
    selector: 'app-impersonate',
    templateUrl: './impersonate.component.html',
    styleUrls: ['./impersonate.component.scss'],
    imports: [LoaderComponent]
})
export class ImpersonateComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/']);
  }
}
