import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, map } from 'rxjs';
import { SortInterface } from '../core/models/sort.model';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';
import { SortFactory } from '../core/factory/sort.factory';
import { ClaimInterface, ClaimListItemInterface, ClaimType, ClaimTypeFileWrapperInterface, ClaimUpdateInterface } from '../core/models/claim.model';
import { ShareInquiryPayloadInterface, ShareInquiryServiceInterface } from './share-inquiry-modal/share-inquiry-service.interface';
import { SharedWithUserInterface, UserInterface } from '../core/models/user.model';
import { environment } from '../../environments/environment';

export interface ClaimListResponse {
  data: ClaimListItemInterface[];
  meta: any;
}

export interface ClaimResponse {
  data: ClaimInterface;
  meta: any;
}

export interface ClaimTroubleshootingContentResponse {
  data: TextBlockInterface;
  meta: any;
}

export interface TextBlockInterface {
  content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClaimsService implements ShareInquiryServiceInterface {

  constructor(
    private api: ApiService
  ) { }

  getOne(id: number): CacheableObservable<ClaimInterface> {
    return this.api.get(`claims/${id}`).pipe(map(({ data }) => data)) as CacheableObservable<ClaimInterface>;
  }

  filterAll(filter: any, sort?: SortInterface): CacheableObservable<ClaimListResponse> {
    let params = filter;
    if (sort) {
      params = { ...params, sort: SortFactory.getString(sort) };
    }

    return this.api.get(`claims/`, params) as CacheableObservable<ClaimListResponse>;
  }

  delete(id: ClaimInterface['id']): Observable<any> {
    return this.api.delete(`claims/${id}`, {});
  }

  getTroubleshootingContent() {
    return this.api.get(`text-blocks/by-code/troubleshooting`) as CacheableObservable<ClaimTroubleshootingContentResponse>;
  }

  fetchClaimDetails(id: ClaimInterface['id']): CacheableObservable<ClaimResponse> {
    return this.api.get(`claims/${id}`) as CacheableObservable<ClaimResponse>;
  }

  createClaim(data: ClaimUpdateInterface, validate?: boolean): CacheableObservable<ClaimResponse> {
    return this.api.post(`claims/`, data, {
      full: validate ? 1 : 0
    }) as CacheableObservable<ClaimResponse>;
  }

  updateClaim(id: ClaimInterface['id'], data: ClaimUpdateInterface, validate?: boolean): CacheableObservable<ClaimResponse> {
    return this.api.patch(`claims/${id}`, data, {
      full: validate ? 1 : 0
    }) as CacheableObservable<ClaimResponse>;
  }

  markAsInProgress(id: ClaimInterface['id']): CacheableObservable<ClaimResponse> {
    return this.api.put(`claims/${id}/transit/mark_as_in_progress`, {}) as CacheableObservable<ClaimResponse>;
  }

  deleteFile(id: ClaimInterface['id'], claimType: ClaimType, fileId: number): CacheableObservable<ClaimResponse> {
    return this.api.delete(`claims/${id}/claim-type/${claimType}/delete-file/${fileId}`, {}) as CacheableObservable<ClaimResponse>;
  }

  getShares(claimId: number): Observable<SharedWithUserInterface[]> {
    return this.api.get(`claims/${claimId}/share/users`).noCache().pipe(map(({ data }) => data));
  }

  share(claimId: number, users: ShareInquiryPayloadInterface): Observable<any> {
    return this.api.post(`claims/${claimId}/share/users`, users );
  }

  validateEmail(claimId: number, email: string): Observable<SharedWithUserInterface> {
    return this.api.post(`claims/${claimId}/share/validate-email`, { email }).pipe(map(({ data }) => data));
  }

  getUsers(): Observable<UserInterface[]> {
    return this.api.get(`claims/users`).noCache().pipe(map(({ data }) => data));
  }

  getFileUploadEndpoint(claimId: number, claimType: ClaimType, claimFileType: string): string {
    return `${environment.api}claims/${claimId}/claim-type/${claimType}/upload-file/${claimFileType}`;
  }

  getFileDeleteHandler(claimId: number, claimType: ClaimType) {
    return (file: ClaimTypeFileWrapperInterface): CacheableObservable<any> => {
      return this.deleteFile(claimId, claimType, file.file.id);
    }
  }
}
