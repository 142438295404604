import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AppUpdatedComponent } from '../app-updated/app-updated.component';
import { AppReloadComponent } from '../app-reload/app-reload.component';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    imports: [SharedModule, AppUpdatedComponent, AppReloadComponent]
})
export class ModalComponent implements OnInit {
  @Input() component: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
