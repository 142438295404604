import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {UserInterface} from '../models/user.model';
import {RegularUserGuardService} from '../services/regular-user-guard/regular-user-guard.service';
import {UserRole} from '../enums/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class NotAdminGuard  {
  constructor(
    private authService: AuthService,
    private regularUserGuardService: RegularUserGuardService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowAdmin = this.regularUserGuardService.shouldAllowAdmin(next);
    const user: UserInterface = this.authService.getUser();

    if (user && user.role && user.role.name === UserRole.ROLE_ADMIN && user.redirect && !allowAdmin) {
      window.location.href = user.redirect;

      return false;
    }

    return true;
  }
}
