import { Injectable } from '@angular/core';
import { ApiService, FileSizeEnum } from '../../../api.service';
import { AuthService } from '../../../auth/auth.service';
import { UserInterface, UserUpdateInterface } from '../../models/user.model';
import { EMPTY, Observable, of, ReplaySubject, timer } from 'rxjs';
import { catchError, exhaustMap, map, share, switchMap } from 'rxjs/operators';
import { PermissionsUserService } from '../../../permissions/user.model';
import { CacheableObservable, clearCacheMatching } from '../../../cacheable-observable/cacheable-observable.model';
import { PageVisibilityService } from '../page-visibility/page-visibility.service';
import { pause } from '../../operators/pause.operator';
import { UserRole } from '../../enums/user-role.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PricelistChangeModalComponent } from '../../../user/pricelist-change-modal/pricelist-change-modal.component';
import { ImpersonateService } from '../../impersonate/impersonate.service';

/**
 * @TODO: refactor - overcomplicated
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements PermissionsUserService {
  private user$: ReplaySubject<UserInterface> = new ReplaySubject<UserInterface>(1);
  private modalRef: NgbModalRef = null;

  private userPoll$: Observable<UserInterface> = timer(0, 10000).pipe(
    pause(this.pageVisibilityService.isPageVisibleAsObservable()),
    exhaustMap(() => this.reload().pipe(
      catchError(() => of()) // handle error and don't emit anything
    )),
    share()
  );

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private pageVisibilityService: PageVisibilityService,
    private modalService: NgbModal,
    private impersonateService: ImpersonateService,
  ) {}

  /**
   * Fetches information about user if user is logged in
   * updates auth service
   * @param cache
   */
  private fetchInfoAndUpdate(cache = true): Observable<UserInterface> {
    const authUser = this.auth.getUser();

    if (authUser?.isActive === false) {
      this.auth.logout();
      return EMPTY;
    }

    if (!this.auth.isAuthenticated()) {
      return EMPTY;
    }

    const oldPriceListId = authUser && authUser.priceList && authUser.priceList.id ? authUser.priceList.id : 0;
    const observable = cache ? this.information() : this.information().noCache();
    return new Observable<UserInterface>(observer => {
      if (this.auth.isAuthenticated()) {
        observable.subscribe((user: UserInterface) => {
          // here we are detecting pricelist version change and clearing catalog cache
          // catalog is cached and after version change all of the items there are outdated
          // we need to provide new navigation and this is the way to do it
          if (user && user.priceList && user.priceList.id > 0 && oldPriceListId !== user.priceList.id) {
            clearCacheMatching('catalog');
          }
          this.auth.updateUser(user);
          this.user$.next(this.auth.getUser());
          observer.next(this.auth.getUser());
          observer.complete();

          if (user.acceptedPricelist !== user.priceList?.name) {
            this.showPricelistChangeModal();
          }

          if (user.acceptedPricelist === user.priceList?.name && this.modalRef != null) {
            this.modalRef.close();
            this.modalRef = null;
          }
        });
      }
    });
  }

  /**
   * Do not use this method on initial methods, use only for fetch new user data from server
   */
  reload(): Observable<UserInterface> {
    return this.fetchInfoAndUpdate(false);
  }

  /**
   * Tries to load user from storage. If fails - loads user by token
   */
  fromStorage(): Observable<UserInterface> {
    return new Observable<UserInterface>(observer => {
      const user = this.auth.getUser();
      if (!user || typeof user.id === 'undefined') {
        this.fetchInfoAndUpdate().subscribe(
          (fetchedUser: UserInterface) => {
            observer.next(this.auth.getUser());
            observer.complete();
          },
          error => observer.error(error)
        );
      } else {
        this.user$.next(user);
        observer.next(user);
        observer.complete();
      }
    });
  }

  getUser(): Observable<UserInterface> {
    return this.fromStorage();
  }

  /**
   * Fetches information about currently authenticated user
   */
  information(): CacheableObservable<UserInterface> {
    return this.api.get('users/').pipe(
      map(({ data, additional }) => {
        return { ...data, ...additional };
      })
    ) as CacheableObservable<UserInterface>;
  }

  poll(): Observable<UserInterface> {
    return this.userPoll$;
  }

  update(id: number, user: UserUpdateInterface): Observable<UserInterface> {
    return this.api.patch(`users/${id}`, user).pipe(
      map(({ data }) => {
        this.user$.next(data);
        this.auth.updateUser(data);
        return data;
      })
    );
  }

  userObservable(): Observable<UserInterface> {
    return this.user$.asObservable();
  }

  resetLastUpdatedOrderAndPriceRequest() {
    this.fromStorage()
      .pipe(
        switchMap((user) =>
          this.update(user.id, { lastUpdatedOrder: null, lastUpdatedPageBreak: null, lastUpdatedCustomMadePriceRequest: null })
        )
      )
      .subscribe();
  }

  uploadProfilePicture(id: number, profilePicture: File) {
    return this.api.upload(`users/${id}`, null, profilePicture, FileSizeEnum.SIZE_10MB, 'profilePicture').pipe(
      map(({ data }) => {
        this.user$.next(data);
        this.auth.updateUser(data);
        return data;
      })
    );
  }

  isPM(): boolean {
    return [UserRole.ROLE_PM, UserRole.ROLE_PM_RU].includes(this.auth.getUser()?.role?.name);
  }

  isPmNarbutas(): boolean {
    return this.auth.getUser()?.role?.name === UserRole.ROLE_PM_NARBUTAS;
  }

  isDealer(): boolean {
    return this.auth.getUser()?.role?.name === UserRole.ROLE_DEALER;
  }

  showPricelistChangeModal() {
    if (this.modalRef != null || this.impersonateService.impersonated() || !this.isDealer()) {
      return;
    }

    const modalRef = this.modalService.open(PricelistChangeModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.priceListName = this.auth.getUser().priceList.name;

    modalRef.componentInstance.understoodClick.subscribe(() => {
      this.modalRef.close();
      this.modalRef = null;
    });

    this.modalRef = modalRef;
  }
}
