<form *ngIf="form" [formGroup]="form" class="form">
  <div class="row">
    <div class="col">
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="companyName"
          name="companyName"
          text="{{ 'CLIENT.FORM.COMPANY_NAME' | translate }}:"
          [formControl]="form.controls.companyName"
          title="{{ 'CLIENT.FORM.COMPANY_NAME' | translate }}"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="companyCode"
          name="companyCode"
          text="{{ 'CLIENT.FORM.COMPANY_CODE' | translate }}:"
          title="{{ 'CLIENT.FORM.COMPANY_CODE' | translate }}"
          [formControl]="form.controls.companyCode"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="vatCode"
          text="{{ 'CLIENT.FORM.VAT_CODE' | translate }}:"
          title="{{ 'CLIENT.FORM.VAT_CODE' | translate }}"
          name="vatCode"
          [formControl]="form.controls.vatCode"
        >
        </app-text-field>
      </div>

      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="address"
          text="{{ 'CLIENT.FORM.ADDRESS' | translate }}:"
          title="{{ 'CLIENT.FORM.ADDRESS' | translate }}"
          name="address"
          [formControl]="form.controls.address"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="postCode"
          text="{{ 'CLIENT.FORM.POST_CODE' | translate }}:"
          title="{{ 'CLIENT.FORM.POST_CODE' | translate }}"
          name="postCode"
          [formControl]="form.controls.postCode"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="city"
          text="{{ 'CLIENT.FORM.CITY' | translate }}:"
          title="{{ 'CLIENT.FORM.CITY' | translate }}"
          name="city"
          [formControl]="form.controls.city"
        >
        </app-text-field>
      </div>
      <div *ngIf="!readOnly" class="d-flex align-items-center mb-3">
        <label class="w-50 mb-0 text-end pe-3 form-label" for="country">
          {{ 'CLIENT.FORM.COUNTRY' | translate }}:<span *ngIf="form.controls.country | isFormControlRequired">*</span>
        </label>
        <select
          class="w-50 form-control form-select"
          id="country"
          name="country"
          formControlName="country"
          [compareWith]="compareCountry"
          title="{{ 'CLIENT.FORM.COUNTRY' | translate }}"
        >
          <option *ngFor="let country of countries" [ngValue]="country">{{ country.title || country.code }}</option>
        </select>
      </div>
      <div *ngIf="readOnly">
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="countryDisabled"
          text="{{ 'CLIENT.FORM.COUNTRY' | translate }}:"
          title="{{ 'CLIENT.FORM.COUNTRY' | translate }}"
          name="countryDisabled"
          [formControl]="form.controls.countryDisabled"
        >
        </app-text-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h4 class="color-gray my-2 fw-bold">{{ 'CLIENT.FORM.CONTACT_PERSON' | translate }}</h4>
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          text="{{ 'CLIENT.FORM.FULL_NAME' | translate }}:"
          title="{{ 'CLIENT.FORM.FULL_NAME' | translate }}"
          id="contactPerson"
          name="contactPerson"
          [formControl]="form.controls.contactPerson"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="email"
          text="{{ 'CLIENT.FORM.EMAIL' | translate }}:"
          title="{{ 'CLIENT.FORM.EMAIL' | translate }}"
          name="email"
          [formControl]="form.controls.email"
        >
        </app-text-field>
      </div>
      <div>
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          id="phone"
          text="{{ 'CLIENT.FORM.PHONE_NUMBER' | translate }}:"
          title="{{ 'CLIENT.FORM.PHONE_NUMBER' | translate }}"
          name="phone"
          [formControl]="form.controls.phone"
        >
        </app-text-field>
      </div>

      <div
        *permissionsElementVisibilityDecisionMaker="[
          permissionActions.CAN_ADD_AX_CODE,
          permissionActions.CAN_EDIT_AX_CODE,
          permissionActions.CAN_VIEW_AX_CODE
        ]"
      >
        <app-text-field
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW, textFieldThemeTypes.TOOLTIP_ERROR]"
          text="{{ 'CLIENT.FORM.AX_CLIENT_CODE' | translate }}:"
          title="{{ 'CLIENT.FORM.AX_CLIENT_CODE' | translate }}"
          id="axClientCode"
          name="axClientCode"
          [formControl]="form.controls.axClientCode"
        >
        </app-text-field>
      </div>
    </div>
  </div>
</form>
