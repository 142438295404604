<ng-template #content let-modal>
  <app-loader [cssClasses]="'loader--white'" [loading]="loading | async"></app-loader>

  <div class="modal-header border-bottom p-3">
    <div class="modal-title px-2">
      <h2 class="mb-0 fw-bold">
        {{ 'ORDERS.NON_STANDARD_ITEM_MODAL.TITLE' | translate }}
      </h2>
      <div>
        {{ 'ORDERS.NON_STANDARD_ITEM_MODAL.SUB_TITLE' | translate }}
      </div>
    </div>
    <!-- This remove autofocus from modal's close button -->
    <input class="d-none"/>
    <button type="button" class="btn btn-default p-0 m-1 d-flex" (click)="modal.dismiss()">
      <i class="icon ni-close"></i>
    </button>
  </div>
  <div class="modal-body p-4" [class.d-none]="(loading | async)">
    <div class="p-2">
      <div class="container-fluid">
        <div class="row">
          <!-- LEFT SIDE -->
          <div class="col-6 px-0 pe-3">
            <app-custom-article-image-control
              [image]="item.img"
              [disabled]="!canChange"
              [fileTooBig]="fileTooBig"
              (fileChange)="onFileChange($event)"
            ></app-custom-article-image-control>

            <div class="mt-3">
              <label for="inputLongText" class="form-label mb-2">{{ 'ORDERS.NON_STANDARD_ITEM_MODAL.FORM.DESCRIPTION' | translate }}*</label>
              <textarea
                rows="4"
                class="form-control"
                id="inputLongText"
                [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.DESCRIPTION' | translate"
                [title]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.DESCRIPTION' | translate"
                [formControl]="inputLongText"
                [class.is-invalid]="inputLongText.dirty && inputLongText.invalid"
              ></textarea>
              <div class="invalid-feedback">
                {{ inputLongText.errors | translateValidationErrors }}
              </div>
            </div>
          </div>

          <!-- RIGHT SIDE -->
          <div class="col-6 px-0 ps-3 overflow-auto">
            <app-non-standard-item-order-article-form
              [item]="item"
              [user]="user"
              [selectedOrder]="selectedOrder"
              (itemChanges)="onFormGroupItemChange($event)"
            ></app-non-standard-item-order-article-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex" *ngIf="!(loading | async)">
    <div class="h-100 border-end ms-2 d-flex align-items-center">
      <app-ui-elements-quantity-spinner
        [disabled]="!canChange"
        [quantity]="item.quantity"
        (onChange)="onQuantityChange($event)"
      >
      </app-ui-elements-quantity-spinner>
    </div>
    <div class="flex-grow-1 color-black fw-bold px-3 mx-2">
      <span class="me-1">{{ 'CONFIGURATOR_MODAL.FOOTER.PRICE_TOTAL' | translate }}:</span>
      <span>{{ calculatedPrice | priceListCurrency }}</span>
    </div>
    <div #createOrderBounds>
      <button
        class="btn btn-lg btn-primary rounded-0"
        [class.disabled]="!canOrder"
        (click)="onSave()"

        [ngbPopover]="getWarningMessage() | translate"
        placement="top"
        [disablePopover]="canOrder"
        container="body"
        triggers="mouseenter:mouseleave"
        popoverClass="shadow"
      >
        <span>
          {{ getButtonMessage() | async }}
        </span>
        <i class="icon icon--warning ms-1" *ngIf="!canOrder"></i>
      </button>
    </div>
  </div>
</ng-template>
