import { Component, Input, OnInit } from '@angular/core';
import { CommunicationMessageInterface, MessagesInterface } from '../../../core/models/messages.model';
import { ClaimsMessagesService } from '../../claims-messages.service';
import { UserService } from '../../../core/services/user/user.service';
import { UserInterface } from '../../../core/models/user.model';
import { ChatHelperService } from '../../../shared/services/chat-helper/chat-helper.service';
import { CommunicationTabComponent } from '../../../ui-elements/communication-tab/communication-tab.component';

@Component({
    selector: 'app-claims-communication-tab',
    templateUrl: './claims-communication-tab.component.html',
    providers: [ClaimsMessagesService, ChatHelperService],
    imports: [CommunicationTabComponent]
})
export class ClaimsCommunicationTabComponent implements OnInit {
  @Input() claimId: number;

  user?: UserInterface;
  messages: CommunicationMessageInterface[] = [];
  isLoading = true;
  lastSeenMessageId: number;

  constructor(
    private claimsMessagesService: ClaimsMessagesService,
    private userService: UserService,
    private chatHelperService: ChatHelperService,
  ) { }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.user = user;
    });
    
    this.claimsMessagesService
      .getMessages(this.claimId)
      .noCache()
      .subscribe(({ data, additional }) => {
        this.messages = data;
        this.lastSeenMessageId = additional.lastSeenMessageId;
        this.isLoading = false;
      });
  }

  onSend(message: Partial<MessagesInterface>) {
    this.chatHelperService.setIsSending(true);

    this.claimsMessagesService.sendMessage(this.claimId, message).subscribe({
      next: (data) => {
        this.messages = [...this.messages, data];
        this.lastSeenMessageId = data.id;
        this.chatHelperService.setIsSending(false);
      },
      error: (error) => {
        this.chatHelperService.parseValidationErrors(error);
        this.chatHelperService.setIsSending(false);
      },
    });
  }
}
