import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CloseButtonTheme } from '../close-button/close-button-theme.enum';
import { MobileModalBodyDirective } from './directives/modal-body/mobile-modal-body.directive';
import { ModalBodyDirective } from './directives/modal-body/modal-body.directive';
import { ModalFooterDirective } from './directives/modal-footer/modal-footer.directive';
import { ModalHeaderDirective } from './directives/modal-header/modal-header.directive';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss'],
    imports: [NgClass, NgIf, NgTemplateOutlet, CloseButtonComponent]
})
export class GenericModalComponent implements OnInit {
  @ContentChild(ModalHeaderDirective, { read: TemplateRef }) headerTemplate;
  @ContentChild(ModalBodyDirective, { read: TemplateRef }) bodyTemplate;
  @ContentChild(MobileModalBodyDirective, { read: TemplateRef }) mobileBodyTemplate;
  @ContentChild(ModalFooterDirective, { read: TemplateRef }) footerTemplate;

  @Input() cssClasses: string = '';
  @Input() headerClasses: string = '';
  @Input() bodyClasses: string = '';
  @Input() mobileBodyClasses: string = '';
  @Input() footerClasses: string = '';
  @Input() heading?: string;
  @Input() subHeading?: string;
  @Input() closable = true;
  @Input() hasHeader = true;
  @Input() mobileResponsiveFooter = false;

  closeButtonTheme = CloseButtonTheme;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
