import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'requiredFieldsAsterisk' })
export class RequiredFieldsAsteriskPipe implements PipeTransform {

  transform(value: any, requiredFields: string[] = [], key: string, asteriskSymbol = '*'): any {
    const result = `${value}${requiredFields.includes(key) ? asteriskSymbol : ''}`;;
    return result;
  }

}
