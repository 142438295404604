import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['../errors.component.scss'],
    imports: [TranslatePipe]
})
export class PageNotFoundComponent {}
