import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { OrderInterface } from '../core/models/order.model';
import { ExtraListElementInterface } from '../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { AddItemHelperService } from '../shared/services/add-item-helper/add-item-helper.service';
import { PriceRequestInterface } from '../core/models/price-request.model';
import { SelectionMenuEntityEnum } from '../core/enums/selection-menu-entity.enum';
import { SelectionMenuComponent } from './selection-menu.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SelectionMenuService implements OnDestroy {
  private selectionMenuEntity$ = new BehaviorSubject<SelectionMenuEntityEnum>(null);
  private open$ = new BehaviorSubject<boolean>(false);
  private openedOrder$ = new BehaviorSubject<OrderInterface>(null);
  private selectedOrder$ = new BehaviorSubject<OrderInterface>(null);
  private selectedGroup$ = new BehaviorSubject<ExtraListElementInterface>(null);
  private selectedPriceRequest$ = new BehaviorSubject<PriceRequestInterface>(null);

  private subscription = new Subscription();

  constructor(
    private addItemHelperService: AddItemHelperService,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {
    this.subscription.add(
      this.authService.authenticatedObservable().subscribe((authenticated) => {
        if (!authenticated) {
          this.close();
          this.resetSelectedOrder();
          this.resetSelectedPriceRequest();
        }
      })
    );
  }

  open() {
    this.open$.next(true);
  }

  close() {
    this.open$.next(false);
  }

  toggle() {
    this.open$.next(!this.open$.getValue());
  }

  openObservable(): Observable<boolean> {
    return this.open$.asObservable();
  }

  openOrder(order: OrderInterface) {
    this.openedOrder$.next(order);
  }

  selectOrder(order: OrderInterface) {
    /* Setting selectedGroup$ is not needed because adjacently with selectOrder call
    together always goes selectGroups call  */
    this.selectedOrder$.next(order);
    this.selectionMenuEntity$.next(SelectionMenuEntityEnum.ORDER);
    this.selectedPriceRequest$.next(null);
    this.syncWithAddItemHelperService(true, false);
  }

  selectGroups(group: ExtraListElementInterface) {
    /* Setting selectionMenuEntity and resetting selectedPriceRequest$ is not needed
    because adjacently with selectGroups call together always goes selectOrder call  */
    !group ? this.selectedGroup$.next(null) : this.selectedGroup$.next(group);
    this.syncWithAddItemHelperService(false, true, false);
  }

  selectPriceRequest(priceRequest: PriceRequestInterface) {
    this.selectedPriceRequest$.next(priceRequest);
    this.selectionMenuEntity$.next(SelectionMenuEntityEnum.PRICE_REQUEST);
    this.selectedOrder$.next(null);
    this.selectedGroup$.next(null);
    this.syncWithAddItemHelperService();
  }

  resetSelectedPriceRequest(): void {
    if (this.selectionMenuEntity$.value === SelectionMenuEntityEnum.PRICE_REQUEST) {
      this.selectedPriceRequest$.next(null);
      this.selectionMenuEntity$.next(null);
      this.syncWithAddItemHelperService(false, false, true);
    }
  }

  resetSelectedOrder(): void {
    if (this.selectionMenuEntity$.value === SelectionMenuEntityEnum.ORDER) {
      this.selectedOrder$.next(null);
      this.selectedGroup$.next(null);
      this.selectionMenuEntity$.next(null);
      this.syncWithAddItemHelperService(true, true, false);
    }
  }

  resetSelectedGroup(): void {
    if (this.selectedGroup$.value) {
      this.selectedGroup$.next(null);
      this.syncWithAddItemHelperService(false, true, false);
    }
  }

  selectedOrderAsObservable(): Observable<OrderInterface> {
    return this.selectedOrder$.asObservable();
  }

  selectedGroupAsObservable(): Observable<ExtraListElementInterface> {
    return this.selectedGroup$.asObservable();
  }

  selectedPriceRequestAsObservable(): Observable<PriceRequestInterface> {
    return this.selectedPriceRequest$.asObservable();
  }

  openedOrderAsObservable(): Observable<OrderInterface> {
    return this.openedOrder$.asObservable();
  }

  selectionMenuEntityAsObservable(): Observable<SelectionMenuEntityEnum> {
    return this.selectionMenuEntity$.asObservable();
  }

  openSelectionMenuModal(): NgbModalRef {
    const modalRef = this.modalService.open(SelectionMenuComponent, {
      windowClass: 'sidebar-modal',
      modalDialogClass: 'position-fixed h-100 right-0',
    });

    return modalRef;
  }

  private syncWithAddItemHelperService(syncOrder: boolean = true, syncGroup: boolean = true, syncPriceRequest: boolean = true): void {
    if (syncOrder) {
      this.addItemHelperService.setLastOrder(this.selectedOrder$.value);
    }

    if (syncGroup) {
      this.addItemHelperService.setLastGroup(this.selectedGroup$.value);
    }

    if (syncPriceRequest) {
      this.addItemHelperService.setLastPriceRequest(this.selectedPriceRequest$.value);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
