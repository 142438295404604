import { DecisionMakerService, PermissionInterface } from './decision-maker.service';
import { PERMISSIONS, PERMISSIONS_DECISION_MAKER_SERVICE, ROLES } from './injection-tokens';
import { PermissionsUserService, PermissionsUserServiceInterface } from './user.model';

const permissionsDecisionMakerServiceFactory = (
  permissions: PermissionInterface[],
  roles: string[],
  permissionsUserService: PermissionsUserServiceInterface
) => {
  const service = new DecisionMakerService(permissionsUserService);

  service.setPermissions(permissions).setRoles(roles);

  return service;
};

export const permissionsDecisionMakerServiceProvider = {
  provide: PERMISSIONS_DECISION_MAKER_SERVICE,
  useFactory: permissionsDecisionMakerServiceFactory,
  deps: [PERMISSIONS, ROLES, PermissionsUserService],
};
