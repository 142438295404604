import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddressInterface, AddressUpdateInterface } from '../../core/models/address.model';
import { CountryInterface } from '../../core/models/country.model';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AddressFormService } from './address-form.service';
import { TextFieldThemeTypes } from '../../ui-elements/text-field/text-field/theme-types.enum';
import { Subscription } from 'rxjs';
import { DeliveryOptions } from '../../core/enums/delivery-options.enum';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '../../core/constants/breakpoints.constants';

export interface AddressModelInstanceChangeEventInterface {
  address: AddressInterface;
  valid: boolean;
  formModel?: AddressUpdateInterface;
}

export interface AddressModelInstanceInitEventInterface {
  address: AddressInterface;
  valid: boolean;
  formModel?: AddressUpdateInterface;
}

interface AvailableFieldInterface {
  visible: boolean;
}

interface AvailableFieldsInterface {
  address?: AvailableFieldInterface;
  postCode?: AvailableFieldInterface;
  city?: AvailableFieldInterface;
  country?: AvailableFieldInterface;
  contactPerson?: AvailableFieldInterface;
  deliveryAddressee?: AvailableFieldInterface;
  contactWorkingHours?: AvailableFieldInterface;
  email?: AvailableFieldInterface;
  phone?: AvailableFieldInterface;
  // deliveryOption?: AvailableFieldInterface;
}

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model?: AddressInterface;
  @Input() countries: CountryInterface[];
  @Input() availableFields?: AvailableFieldsInterface = null;
  @Input() readOnly? = false;
  /** False when creating new address, true when editing already existing one */
  @Input() editing = false;

  @Output() changed: EventEmitter<AddressModelInstanceChangeEventInterface> = new EventEmitter<AddressModelInstanceChangeEventInterface>();
  @Output() init: EventEmitter<AddressModelInstanceChangeEventInterface> = new EventEmitter<AddressModelInstanceChangeEventInterface>();

  form: UntypedFormGroup;
  textFieldThemeTypes = TextFieldThemeTypes;
  fields: AvailableFieldsInterface = {
    address: { visible: true },
    postCode: { visible: true },
    city: { visible: true },
    country: { visible: true },
    contactPerson: { visible: true },
    deliveryAddressee: { visible: true },
    contactWorkingHours: { visible: true },
    email: { visible: true },
    phone: { visible: true },
    // deliveryOption: { visible: true },
  };
  deliveryOptions = DeliveryOptions;
  shouldShowPlaceholders = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private addressFormService: AddressFormService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.breakpointObserver.observe(`(max-width: ${BREAKPOINTS.SM})`).subscribe(result => {
        this.shouldShowPlaceholders = result.matches;
      })
    );

    this.form = this.addressFormService.constructForm({ ...{ country: null }, ...this.model }, this.readOnly);

    if (this.model) {
      const {
        address,
        postCode,
        city,
        country,
        contactPerson,
        deliveryAddressee: deliveryAddressee,
        contactWorkingHours,
        email,
        phone,
        // deliveryOption,
      } = this.model;
      this.form.patchValue({
        address: address || '',
        postCode: postCode || '',
        city: city || '',
        country: country || null,
        countryDisabled: country ? `${country.title}, ${country.code}` : '',
        contactPerson: contactPerson || '',
        deliveryAddressee: deliveryAddressee || '',
        contactWorkingHours: contactWorkingHours || '',
        email: email || '',
        phone: phone || '',
        // deliveryOption: deliveryOption || null
      });
    }

    if (this.readOnly) {
      return;
    }

    this.emit('init');
    this.updateOnChange();
    // this.subscription.add(
    //   this.breakpointObserver.observe(`(max-width: ${BREAKPOINTS.SM})`).subscribe(result => {
    //     this.isMobile = result.matches;
    //   })
    // );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableFields && changes.availableFields.currentValue) {
      this.fields = { ...this.fields, ...changes.availableFields.currentValue };
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  compareCountry(c1: CountryInterface | null, c2: CountryInterface | null) {
    return c1 === c2 || (c1 && c2 && c1.id === c2.id);
  }

  private updateOnChange() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        if (this.form.value) {
          this.model = { ...this.model, ...this.form.value };
          this.emit('changed');
        }
      })
    );
  }

  private emit(event: string) {
    this[event].emit({
      address: this.model,
      valid: this.form.valid,
      formModel: { ...this.form.value, country: this.form.value.country?.id },
    });
  }

  getValidationErrors(control: AbstractControl): ValidationErrors | null | false {
    if (!this.editing) {
      return control.invalid && control.dirty && this.excludeErrorsByKey(control.errors, ['required']);
    } else {
      return control.invalid && control.errors;
    }
  }

  private excludeErrorsByKey(errors: ValidationErrors | null, exclude: string[]): ValidationErrors | null {
    if (!errors) {
      return errors;
    }

    const newErrors: ValidationErrors = {};

    Object.keys(errors).forEach(key => {
      if (!exclude.includes(key)) {
        newErrors[key] = errors[key];
      }
    });

    return Object.keys(newErrors).length === 0 ? null : newErrors;
  }
}
