import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { EstimatesService } from '../estimates/estimates.service';
import { DateHelper } from '../core/util/date.helper';
import { LanguageSwitchComponent } from '../shared/components/language-switch/language-switch.component';
import { LogoComponent } from '../core/tree/logo/logo.component';
import { SharedModule } from '../shared/shared.module';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    imports: [SharedModule, LanguageSwitchComponent, LogoComponent]
})
export class MaintenanceComponent implements OnInit {
  constructor(
    private routerService: Router,
    private estimatesService: EstimatesService
  ) {}

  until: string | null = null;

  ngOnInit(): void {
    /**
     * calls random endpoint which is not accessible when maintenance mode is on
     * and which doesn't return 401 when called by not logged in user
     */
    this.estimatesService.get().subscribe({
      next: () => {
        this.routerService.navigate(['/sign-in']);
      },
      error: error => {
        const utcDate = error?.error?.until ?? null;

        this.until = DateHelper.convertUtcToIso(utcDate);
      }
    });
  }
}
