import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { CacheableObservable } from '../../cacheable-observable/cacheable-observable.model';
import { FileFormat } from '../../core/enums/file-format.enum';
import { Observable } from 'rxjs';
import { PriceDetailsInterface } from '../../configurator-modal/footer/price-details/price-details.component';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { ConfigurationArticle } from '../../configurator-modal/configuration-article.model';
import { ProductResponseInterface } from '../../core/models/configurator.model';

export interface SessionConfigurationInterface {
  articleVersionId?: string | number;
  configuratorId: string;
  configuratorSessionId: string;
  sessionConfigurationIdentifier: string;
  version?: string | number;
}

export interface RefetchProductPriceInterface {
  quantity: number;
  saleMode: number;
  order?: number;
  orderArticle?: number;
}

export interface DownloadUrlOptionsInterface {
  height?: number;
  width?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public static ENDPOINT = {
    OPEN: environment.gofigure + '/open',
    UPDATE: environment.gofigure + '/update',
    UNDO: environment.gofigure + '/undo',
    REDO: environment.gofigure + '/redo',
    SELECT: environment.gofigure + '/select',
    PRICE: environment.gofigure + '/price',
    DOWNLOAD: 'configurator/article/:id/download/:format',
    ARTICLES: environment.gofigure + '/articles',
  };

  constructor(private api: ApiService) { }

  getProductBySystemAndArticleNumber(
    system: string,
    baseArticleNumber: string,
    variantCode?: string,
    orderArticle?: number
  ): Observable<ProductResponseInterface> {
    return this.api.post(
      ProductsService.ENDPOINT.OPEN,
      {
        system,
        baseArticleNumber,
        variantCode,
        orderArticle,
      },
      null
    );
  }

  getConfigurationArticles(): Observable<ConfigurationArticle[]> {
    return this.api.get(ProductsService.ENDPOINT.ARTICLES).noCache().pipe(
      map(({ data }) => data)
    );
  }

  fetchProductPrice({
    quantity,
    saleMode,
    order,
    orderArticle,
  }: RefetchProductPriceInterface): CacheableObservable<PriceDetailsInterface> {
    let params: any = { quantity, 'sale-mode': saleMode };
    if (orderArticle) {
      params = { ...params, orderArticle };
    }

    if (order) {
      params = { ...params, order };
    }

    return this.api.get(ProductsService.ENDPOINT.PRICE, params).pipe(
      map(({ data }) => {
        return {
          ...data,
        };
      })
    ) as CacheableObservable<PriceDetailsInterface>;
  }

  updateProductProperty(
    configuratorId: string,
    propClass: string,
    propertyName: string,
    valueFrom: string,
    orderArticle?: number
  ): Observable<ProductResponseInterface> {
    return this.api.post(ProductsService.ENDPOINT.UPDATE, {
      configuratorId,
      properties: [
        {
          articlePropertyClass: propClass,
          articleProperty: propertyName,
          valueFrom,
        },
      ],
      orderArticle,
    });
  }

  undo(): Observable<ProductResponseInterface> {
    return this.api.post(ProductsService.ENDPOINT.UNDO, {})
  }

  redo(): Observable<ProductResponseInterface> {
    return this.api.post(ProductsService.ENDPOINT.REDO, {})
  }

  selectProduct(configuratorId: string): Observable<ProductResponseInterface> {
    return this.api.post(ProductsService.ENDPOINT.SELECT, { configuratorId });
  }

  downloadConfiguratorFile(id: string, format?: FileFormat, options?: DownloadUrlOptionsInterface) {
    return this.api.download(
      ProductsService.ENDPOINT.DOWNLOAD.replace(':id', id).replace(':format', format),
      `configurator_export_${id}_${Date.now()}.${format}`,
      options
    );
  }
}
