import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { BrowserSupportService } from '../../shared/services/browser-support/browser-support.service';

@Component({
    selector: 'app-unsupported-browser',
    templateUrl: './unsupported-browser.component.html',
    styleUrls: ['../errors.component.scss'],
    imports: [TranslatePipe]
})
export class UnsupportedBrowserComponent {
  constructor(private router: Router, private browserSupport: BrowserSupportService) {
    browserSupport.browserIsSupported$.subscribe((supported) => {
      if (supported) {
        router.navigate(['/']);
      }
    });
  }
}
