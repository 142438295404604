<div class="image-preview d-flex align-items-center justify-content-center position-relative rounded-1"
     [class.has-image]="hasImage()"
     [style.background-image]="imageBackground()"
     [class.image-error]="fileTooBig"
>
  <div appUploadDragDrop
       class="position-absolute w-100 h-100 rounded-1"
       (fileDropped)="onFileDrop($event)"
       *ngIf="!disabled"
  ></div>
  <label class="text-center"
         for="imageInput"
         *ngIf="!disabled && !hasImage()"
  >
    <i class="icon ni-blueprint-72 d-block"></i>
    <button class="btn btn-sm btn-link py-2 mt-2 no-underline"
            (click)="onActivateFileInput($event, 'imageInput')"
            [title]="'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.UPLOAD_IMAGE' | translate"
    >{{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.UPLOAD_IMAGE' | translate }}</button>
  </label>
</div>
<input type="file" id="imageInput"
       (change)="onFileSelect($event)"
       *ngIf="!disabled"
       [title]="'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.UPLOAD_IMAGE' | translate"
/>

<div class="image-actions text-end py-2 border-bottom" *ngIf="!disabled && hasImage()">
  <label for="imageInput">
    <i class="btn btn-default btn-xs d-inline-flex p-1 me-2 icon ni-create"></i>
  </label>
  <i class="btn btn-default btn-xs d-inline-flex p-1 icon ni-minus" (click)="resetImage()"></i>
</div>

<div *ngIf="fileTooBig" class="text-danger pt-2">
  {{ 'ERRORS.BY_ERROR_CODE.ER_100101#002' | translate }}
</div>
