import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ConfigurationArticle } from '../configuration-article.model';

@Component({
    selector: 'app-article-selector',
    templateUrl: './article-selector.component.html',
    host: { class: 'd-block hover-bg-gray-100' },
    imports: [SharedModule]
})
export class ArticleSelectorComponent {
  @ViewChild('triggerElement', { read: ElementRef }) private triggerElement: ElementRef<HTMLElement>;
  @ViewChild('articleListTemplate') private articleListTemplate: TemplateRef<any>;

  @Input() currentArticleId: string;
  @Input() articles: ConfigurationArticle[] = [];
  @Input() widthReferenceEl: HTMLElement;
  @Input() configurationArticlesWithMigration: string[];
  @Input() currentArticlePrice: number;

  @Output() selectArticle = new EventEmitter<string>();

  private configuratorArticlesOverlay: OverlayRef | null = null;

  constructor(
    private overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  open() {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      width: `${this.widthReferenceEl.clientWidth}px`,
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.triggerElement.nativeElement)
        .withPositions([{
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top'
        }])
    });

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });

    const componentPortal = new TemplatePortal(this.articleListTemplate, this._viewContainerRef);
    overlayRef.attach(componentPortal)

    this.configuratorArticlesOverlay = overlayRef;
  }

  currentSelectedArticle(): ConfigurationArticle {
    return this.articles.find(a => a.id === this.currentArticleId);
  }

  closeOverlay() {
    this.configuratorArticlesOverlay?.detach();
    this.configuratorArticlesOverlay = null;
  }

  get hasMultipleArticles(): boolean {
    return this.articles.length > 1;
  }
}
