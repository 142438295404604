<button
  *ngIf="content?.length"
  class="btn p-0 no-outline ms-1"
  [class]="buttonClass"
  (click)="onCopyToClipboard($event, content)"
  [ngbTooltip]="tooltipContent"
  data-testid="copyToClipboard"
>
  <i class="icon hover-color-gray-800" [class.ni-copy]="!copied" [class.ni-check]="copied"></i>
</button>

<ng-template #tooltipContent>
  {{ tooltipText | translate }}
</ng-template>
