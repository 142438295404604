import { Routes } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { ContainerType } from './core/enums/container-type.enum';
import { GeneralRoutePaths, InquiriesRoutePath, OrderRoutePath, RoutePathParams, RouteTypeEnums } from './core/enums/route-types.enum';
import { UserRole } from './core/enums/user-role.enum';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NotAdminGuard } from './core/guards/not-admin.guard';
import { RegularUserGuard } from './core/guards/regular-user.guard';
import { RoleGuard } from './core/guards/role.guard';
import { RouteByRolesGuard } from './core/guards/route-by-roles.guard';
import { UserGuard } from './core/guards/user.guard';
import { ValidLanguageGuard } from './core/guards/valid-language.guard';
import { HomeComponent } from './core/home/home.component';
import { ImpersonateComponent } from './core/impersonate/impersonate.component';
import { ImpersonateResolver } from './core/impersonate/resolver/impersonate.resolver';
import { LogoutComponent } from './core/logout/logout.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { SomethingWentWrongComponent } from './errors/something-went-wrong/something-went-wrong.component';
import { UnsupportedBrowserComponent } from './errors/unsupported-browser/unsupported-browser.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MessagesService } from './messages-modal/messages.service';
import { DiscountModalOpenerService } from './orders/discount-modal/discount-modal-opener.service';
import { OrderArticlesListService } from './orders/order-articles-list/services/order-articles-list/order-articles-list.service';
import { SelectedRowsService } from './orders/order-articles-list/services/selected-rows/selected-rows.service';
import { OrdersService } from './orders/orders.service';
import { DocumentTemplateService } from './orders/services/document-template/document-template.service';
import { PricelistDownloadComponent } from './shared/components/pricelist-download/pricelist-download.component';
import { CommaPipe } from './shared/pipes/comma/comma.pipe';
import { I18nDatePipe } from './shared/pipes/i18n-date/i18n-date.pipe';
import { PriceFormatPipe } from './shared/pipes/price-format/price-format.pipe';
import { CatalogueService } from './catalogue/catalogue.service';
import { ProductsService } from './catalogue/products/products.service';
import { WindowRef } from './core/services/window-ref/window-ref.service';
import { InfoBlockService } from './catalogue/info-block/info-block.service';
import { UncachePipe } from './uncacheable-resource/uncache.pipe';
import { ModelViewerService } from './configurator-modal/model-viewer/model-viewer.service';
import { ConfiguratorModalService } from './configurator-modal/configurator-modal.service';
import { OrderArticleService } from './core/services/order-article/order-article.service';
import { LoaderService } from './ui-elements/loader/loader.service';
import { SessionService } from './configurator-modal/session/session.service';
import { SaleCoefficientsService } from './user/sale-coefficients/sale-coefficients.service';
import { AddressService } from './core/services/address/address.service';
import { CountryService } from './core/services/country/country.service';
import { UserNotificationService } from './shared/services/user-notification/user-notification.service';

export const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard] },
  {
    path: GeneralRoutePaths.SIGN_IN,
    loadChildren: () => import('./auth/auth.routing').then((m) => m.authRoutes),
    canActivate: [UserGuard],
    data: {
      containerType: ContainerType.FULL_WIDTH,
    },
  },
  {
    path: GeneralRoutePaths.LOGOUT,
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: GeneralRoutePaths.TERMS,
    loadChildren: () => import('./terms/terms-and-conditions.routing').then((m) => m.termsAndConditionsRoutes),
  },
  {
    path: `${GeneralRoutePaths.IMPERSONATE}/${RoutePathParams.EMAIL}`,
    canActivate: [AdminGuard],
    resolve: { email: ImpersonateResolver },
    component: ImpersonateComponent,
  },
  {
    path: GeneralRoutePaths.CATALOGUE,
    data: { id: RouteTypeEnums.CATALOGUE },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./catalogue/catalogue.routing').then((m) => m.catalogueRoutes),
    providers: [CatalogueService, ProductsService, InfoBlockService, NgbActiveModal, UncachePipe],
  },
  {
    path: GeneralRoutePaths.CONFIGURATION,
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./configurator-modal/configurator.routing').then((m) => m.configuratorRoutes),
    providers: [ModelViewerService, ConfiguratorModalService, OrderArticleService, LoaderService],
  },
  {
    path: OrderRoutePath.ROOT,
    data: {
      id: RouteTypeEnums.ORDERS,
      allowedRoles: [UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU],
    },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./orders/orders.routing').then((m) => m.ordersRoutes),
    providers: [
      OrdersService,
      NgbActiveModal,
      RouteByRolesGuard,
      MessagesService,
      SelectedRowsService,
      OrderArticlesListService,
      SelectedRowsService,
      DiscountModalOpenerService,
      I18nDatePipe,
      DocumentTemplateService,
      PriceFormatPipe,
      CommaPipe,
    ],
  },
  {
    path: GeneralRoutePaths.USER,
    data: { id: RouteTypeEnums.USER },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard],
    loadChildren: () => import('./user/user.routing').then((m) => m.userRoutes),
  },
  {
    path: GeneralRoutePaths.NEWS,
    data: {
      id: RouteTypeEnums.NEWS,
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./news/news.routing').then((m) => m.newsRoutes),
  },
  {
    path: GeneralRoutePaths.INFO,
    data: {
      id: RouteTypeEnums.INFO,
      uri: 'info',
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./content/content.routing').then((m) => m.contentRoutes),
  },
  {
    path: GeneralRoutePaths.ESTIMATES,
    data: {
      id: RouteTypeEnums.ESTIMATES,
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./estimates/estimates.routing').then((m) => m.estimatesRoutes),
  },
  {
    path: GeneralRoutePaths.FAQ,
    data: {
      id: RouteTypeEnums.FAQ,
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./faq/faq.routing').then((m) => m.faqRoutes),
  },
  {
    path: GeneralRoutePaths.DOWNLOAD_PRICELIST,
    canActivate: [AuthGuard],
    component: PricelistDownloadComponent,
  },
  {
    path: GeneralRoutePaths.FEATURE_FLAGS,
    data: {
      allowedRoles: `${
        environment.production
          ? [UserRole.ROLE_ADMIN]
          : [UserRole.ROLE_ADMIN, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU]
      }`,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./feature-flags/feature-flags.routing').then((m) => m.featureFlagsRoutes),
  },
  {
    path: GeneralRoutePaths.UI_INVENTORY,
    data: {
      allowedRoles: `${
        environment.production
          ? [UserRole.ROLE_ADMIN]
          : [UserRole.ROLE_ADMIN, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU]
      }`,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./component-inventory/component-inventory.routing').then((m) => m.componentInventoryRoutes),
  },
  {
    path: InquiriesRoutePath.ROOT,
    data: {
      id: RouteTypeEnums.INQUIRIES,
      uri: 'inquiries',
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./inquiries/inquiries.routing').then((m) => m.inquiriesRoutes),
  },
  { path: GeneralRoutePaths.PAGE_NOT_FOUND, component: PageNotFoundComponent },
  { path: GeneralRoutePaths.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
  { path: GeneralRoutePaths.UNSUPPORTED_BROWSER, component: UnsupportedBrowserComponent },
  { path: GeneralRoutePaths.MAINTENANCE, component: MaintenanceComponent },
  {
    path: RoutePathParams.LANGUAGE,
    canActivate: [ValidLanguageGuard],
    children: [
      {
        path: GeneralRoutePaths.SIGN_IN,
        loadChildren: () => import('./auth/auth.routing').then((m) => m.authRoutes),
        canActivate: [UserGuard],
        data: {
          containerType: ContainerType.FULL_WIDTH,
        },
      },
      {
        path: GeneralRoutePaths.TERMS,
        loadChildren: () => import('./terms/terms-and-conditions.routing').then((m) => m.termsAndConditionsRoutes),
      },
      { path: GeneralRoutePaths.PAGE_NOT_FOUND, component: PageNotFoundComponent },
      { path: GeneralRoutePaths.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
      { path: GeneralRoutePaths.UNSUPPORTED_BROWSER, component: UnsupportedBrowserComponent },
    ],
  },
  { path: '**', redirectTo: `/${GeneralRoutePaths.PAGE_NOT_FOUND}` },
];
