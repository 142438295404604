import { Component, Input, OnInit } from '@angular/core';
import { OrderInterface } from '../../../../../core/models/order.model';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
    selector: 'app-delivery-information-modal',
    templateUrl: './delivery-information-modal.component.html',
    imports: [SharedModule]
})
export class DeliveryInformationModalComponent implements OnInit {
  @Input() order: OrderInterface;

  addressData = '';
  additionalRequirements = '';
  contacts = '';

  ngOnInit() {
    const addressData = [];
    const contactPersonData = [];
    const delivery = [];

    if (this.order.address.address) {
      addressData.push(this.order.address.address);
    }
    if (this.order.address.postCode) {
      addressData.push(`${this.order.address.postCode} ${this.order.address.city ? this.order.address.city : ''}`);
    }
    if (this.order.address.country?.title) {
      addressData.push(this.order.address.country.title);
    } else if (this.order.address.country?.code) {
      addressData.push(this.order.address.country.code);
    }

    if (this.order.address.contactPerson) {
      contactPersonData.push(this.order.address.contactPerson);
    }
    if (this.order.address.email) {
      contactPersonData.push(this.order.address.email);
    }
    if (this.order.address.phone) {
      contactPersonData.push(this.order.address.phone);
    }

    if (this.order.address.deliveryRequirements) {
      delivery.push(this.order.address.deliveryRequirements);
    }
    let deliveryDateAndTime = '';
    if (this.order.deliveryDate) {
      deliveryDateAndTime = this.order.deliveryDate;
    }
    if (this.order.deliveryTimeFrom) {
      deliveryDateAndTime += ` ${this.order.deliveryTimeFrom}`;
    }
    if (this.order.deliveryTimeTo) {
      deliveryDateAndTime += ` - ${this.order.deliveryTimeTo}`;
    }
    delivery.push(deliveryDateAndTime);

    this.addressData = addressData.join(', ');
    if (this.order.address.contactWorkingHours) {
      this.addressData += `<br>${this.order.address.contactWorkingHours}`;
    }

    this.additionalRequirements = delivery.join('<br>');
    this.contacts = contactPersonData.join(', ');
  }
}
