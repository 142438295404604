import {Component, Input, OnInit} from '@angular/core';
import { DisplayedValueLimitPipe } from '../../pipes/displayed-value-limit/displayed-value-limit.pipe';
import { SharedModule } from '../../shared.module';

const DISPLAYED_VALUE_LIMIT = 99;

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    imports: [SharedModule, DisplayedValueLimitPipe]
})
export class NotificationComponent implements OnInit {
  @Input() notifications;
  @Input() bgClass = "bg-primary";
  @Input() textClass = "color-white";

  displayedValueLimit: number = DISPLAYED_VALUE_LIMIT;

  constructor() {
  }

  ngOnInit() {
  }

}
