import { Pipe, PipeTransform } from '@angular/core';
import { OrderArticleInterface } from '../../../../core/models/order-article.model';
import { FullOrderArticleInterface } from '../../../../core/models/full-order-article.model';

@Pipe({ name: 'fullCodes' })
export class FullCodesPipe implements PipeTransform {
  transform(value?: FullOrderArticleInterface, count: number = 3, postfix: string = ', ...'): string {
    if (!value) {
      return;
    }

    const codes: string[] = this.generateCodes(value.orderArticle);

    return codes.length > count ? `${codes.slice(0, count).join(', ')}${postfix}` : `${codes.join(', ')}`;
  }

  private generateCodes(node?: OrderArticleInterface): string[] {
    const queue = [node];
    const list: string[] = [];
    let nextNode: OrderArticleInterface;

    while (queue.length > 0) {
      nextNode = queue.shift();

      const code = nextNode.code;
      if (!list.find(addedCode => code === addedCode)) {
        list.push(code);
      }

      if (nextNode.children.length === 0) {
        continue;
      }

      nextNode.children.forEach((child: OrderArticleInterface) => {
        queue.push(child);
      });
    }

    return list;
  }
}
