<label [for]="id" *ngIf="title" class="form-label mb-2">{{ title }}</label>

<div class="d-flex" [id]="id" [title]="title">
  <div
    class="droppable-area d-flex justify-content-sm-center align-items-sm-center position-relative w-auto w-sm-100"
    [class.disabled]="isDisabled"
    [ngClass]="{
      'dragging-over': draggingOverDropzone,
      'bg-gray-200': !draggingOverDropzone,
      'upload-error is-invalid': (fileUploadError !== FileUploadErrors.NONE) || formErrors
    }"
    (click)="resetError()"
    (clickOutside)="resetError()"
    (paste)="onKeyboardPaste($event)"
    tabindex="0"
  >
    <div
      *ngIf="config"
      class="w-100 h-100 position-absolute"
      [class.disabled]="isDisabled"
      [dropzone]="config"
      (success)="onUploadSuccess($event)"
      (dragEnter)="onDraggingOverDropzoneStart()"
      (dragLeave)="onDraggingOverDropzoneEnd()"
      (drop)="onDraggingOverDropzoneEnd()"
    ></div>

    <div class="droppable-area-info d-none d-sm-flex flex-column justify-content-center align-items-center small">
      <svg-icon src="/assets/images/drag-drop-indicator/drag-drop-indicator.svg" class="color-gray-500"></svg-icon>

      <div class="mt-3 mb-2">
        <ng-container *ngTemplateOutlet="errors"></ng-container>

        <div [ngClass]="{ 'd-none': fileUploadError !== FileUploadErrors.NONE }">
          <button type="button" class="btn btn-link fw-bold no-outline align-baseline p-0" [ngClass]="'dropzone-button-' + dropZoneName">
            {{ 'DRAG_DROP.FORMS.CLICKABLE_INFO_TEXT' | translate }}
          </button>
          <span class="color-gray-700">{{ 'DRAG_DROP.FORMS.NON_CLICKABLE_INFO_TEXT' | translate }} </span>
          <button type="button" class="btn btn-link fw-bold no-outline align-baseline p-0" (click)="onButtonPaste($event)">
            {{ 'DRAG_DROP.FORMS.PASTE_FROM_CLIPBOARD' | translate }}
          </button>
        </div>
      </div>

      <span *ngIf="[FileUploadErrors.NONE, FileUploadErrors.FILE_TOO_LARGE].includes(fileUploadError)" class="color-gray-500">{{
        'DRAG_DROP.FORMS.MAX_SIZE_INFO_GB' | translate : { size: maxFileSizeInGB }
      }}</span>
    </div>

    <button
      type="button"
      class="btn btn-light d-flex gap-1 d-sm-none position-relative"
      [class.disabled]="isDisabled"
      [ngClass]="'dropzone-button-' + dropZoneName"
    >
      <i class="icon ni-upload"></i>
      <span>{{ 'ACTIONS.UPLOAD' | translate }}</span>
    </button>
  </div>
</div>

<div class="invalid-feedback d-block" *ngIf="formErrors">
  {{ formErrors}}
</div>

<div class="py-2 d-sm-none" *ngIf="!formErrors">
  <ng-container *ngTemplateOutlet="errors"></ng-container>
</div>

<ng-scrollbar>
  <div class="d-flex flex-sm-wrap gap-1 mt-2 mb-2">
    <app-file-preview
      *ngFor="let file of model; let i = index"
      [file]="file.file"
      [removable]="!isDisabled"
      (remove)="onRemoveFile(file)"
      [inProgress]="file.inProgress"
    ></app-file-preview>
  </div>
</ng-scrollbar>

<ng-template #errors>
  <ng-container [ngSwitch]="fileUploadError">
    <span *ngSwitchCase="FileUploadErrors.FILE_TOO_LARGE" class="fw-bold color-danger">{{
      'DRAG_DROP.FORMS.ERRORS.TOO_LARGE' | translate
    }}</span>
    <span *ngSwitchCase="FileUploadErrors.UNSUPPORTED_FILE_TYPE" class="fw-bold color-danger">{{
      'DRAG_DROP.FORMS.ERRORS.UNSUPPORTED_TYPE' | translate
    }}</span>
    <span *ngSwitchCase="FileUploadErrors.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED" class="fw-bold color-danger">{{
      'DRAG_DROP.FORMS.ERRORS.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED' | translate : { count: maxNumberOfParallelUploads }
    }}</span>
  </ng-container>
</ng-template>
