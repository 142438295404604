import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({ selector: '[appUploadDragDrop]' })
export class UploadDragDropDirective {
  @Output() fileDropped: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.dragover') isDragover: boolean;

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragover = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragover = false;
  }

  @HostListener('drop', ['$event']) ondrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragover = false;
    const { files } = event.dataTransfer;
    if (files.length) {
      this.fileDropped.emit(files[0]);
    }
  }

  constructor() {}
}
