import { Inject, LOCALE_ID, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { languageToDateLocale } from '../../../core/locale/language-to-date-locale';
import { APP_DATE_LOCALE_ID } from '../../../core/locale/date-locale';

@Pipe({
    name: 'i18nDate',
    pure: false
})
export class I18nDatePipe extends DatePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(@Inject(APP_DATE_LOCALE_ID) locale: string, private translateService: TranslateService) {
    super(locale);
    (this as any).locale = languageToDateLocale(translateService.currentLang);
    this.subscription.add(
      translateService.onLangChange.subscribe(({ lang }) => {
        (this as any).locale = languageToDateLocale(lang);
      })
    );
  }

  transform(value: Date | string | number, fallbackValue = '-', format?: string, timezone?: string, locale?: string): any {
    return !value ? fallbackValue : super.transform(value, format, timezone, locale);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
