import {Directive, HostBinding, Input, OnInit} from '@angular/core';
import {UserService} from '../../../core/services/user/user.service';
import {UserRole} from '../../../core/enums/user-role.enum';
import {map} from 'rxjs/operators';

@Directive({ selector: '[appDisableByRole]' })
export class DisableByRoleDirective implements OnInit {
  @Input() appDisableByRole: UserRole[];

  @HostBinding('attr.disabled') disabled: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.getUser().pipe(map((user) => user.role.name)).subscribe((role: UserRole) => {
      this.disabled = this.appDisableByRole.includes(role) || null; // assign to null if want enable element
    });
  }

}
