import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PropertyValueChangeEventInterface } from '../../property-values-selector/property-values-selector.component';
import { ArticlePropertyClassInterface } from '../../../core/models/configurator.model';
import { DefaultPropertyClassesComponent } from '../../properties-configurator-property-classes/default-property-classes/default-property-classes.component';

@Component({
    selector: 'app-simple-article-properties-configurator',
    templateUrl: './simple-article-properties-configurator.component.html',
    styleUrls: ['./simple-article-properties-configurator.component.scss'],
    imports: [DefaultPropertyClassesComponent]
})
export class SimpleArticlePropertiesConfiguratorComponent implements OnInit {

  @Input() propertyClasses: ArticlePropertyClassInterface[];

  @Output() propertyChange: EventEmitter<PropertyValueChangeEventInterface> = new EventEmitter<PropertyValueChangeEventInterface>();

  constructor() {
  }

  ngOnInit() {
  }

  onPropertyChange(event: PropertyValueChangeEventInterface) {
    this.propertyChange.emit(event);
  }

}
