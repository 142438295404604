import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-something-went-wrong',
    templateUrl: './something-went-wrong.component.html',
    styleUrls: ['../errors.component.scss'],
    imports: [TranslatePipe]
})
export class SomethingWentWrongComponent {}
