import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { LocalStorageKeys } from '../../../../core/constants/local-storage.constants';
import { UserInterface } from '../../../../core/models/user.model';
import { UserService } from '../../../../core/services/user/user.service';
import { SharedModule } from '../../../../shared/shared.module';
import { CheckMarkComponent } from '../../../../ui-elements/check-mark/check-mark/check-mark.component';
import { FormDropDownComponent } from '../../../../ui-elements/form-drop-down/form-drop-down.component';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

export const ListTypes = [
  { title: 'CREATE_DOCUMENT.TEMPLATE.LIST_TYPES.DETAILED', id: DocumentTemplate.ListType.DETAILED },
  { title: 'CREATE_DOCUMENT.TEMPLATE.LIST_TYPES.CONFIGURATION', id: DocumentTemplate.ListType.CONFIGURATION },
];

export const ImageTypes = [
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.LARGE', id: DocumentTemplate.ImageType.LARGE },
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.SMALL', id: DocumentTemplate.ImageType.SMALL },
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.NO_PHOTO', id: DocumentTemplate.ImageType.HIDDEN },
];

const MaterialPropertiesMap = {
  'materialTypes.image': DocumentTemplate.MaterialType.IMAGE,
  'materialTypes.code': DocumentTemplate.MaterialType.CODE,
  'materialTypes.title': DocumentTemplate.MaterialType.TITLE
};

interface ProductListRememberedOptions {
  photoType: DocumentTemplate.ImageType;
  isDescription: boolean;
  isSystem: boolean;
  isFullCode: boolean;
  isDimensions: boolean;
  isCategory: boolean;
  isVolumeAndWeight: boolean;
  materialTypes: DocumentTemplate.MaterialType[];
  isDiscount: boolean;
  isNetPrice: boolean;
}

export enum ProductListRememberedPresetType {
  LIST_TYPE = 'listType',
  PHOTO_TYPE = 'photoType',
}

@Component({
    selector: 'app-template-product-list',
    templateUrl: './template-product-list.component.html',
    imports: [SharedModule, FormDropDownComponent, CheckMarkComponent]
})
export class TemplateProductListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedTemplate: DocumentTemplate.Template;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  user: UserInterface;
  photoTypes = ImageTypes;
  listTypes = ListTypes;
  listTypeEnum = DocumentTemplate.ListType;
  templateImageType = DocumentTemplate.ImageType;
  templateTypes = DocumentTemplate.Type;
  selectedTemplateCopy: DocumentTemplate.Template = {} as DocumentTemplate.Template;
  isBusy = false;
  subscriptions = new Subscription();
  materialsImpossible = false;

  constructor(
    private documentTemplateService: DocumentTemplateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.selectedTemplateCopy = cloneDeep(this.selectedTemplate);
    this.userService.getUser().subscribe(user => (this.user = user));

    // remove backup from LocalStorage if onDestroy didn't run
    localStorage.removeItem(LocalStorageKeys.DOCUMENT_TEMPLATE_STORAGE_KEY);

    this.subscriptions.add(
      this.documentTemplateService.getBusyStateAsObservable().subscribe(isBusy => (this.isBusy = isBusy))
    );
  }

  ngOnChanges() {
    this.selectedTemplateCopy = cloneDeep(this.selectedTemplate);
    this.materialsImpossible =
      this.selectedTemplate.type === this.templateTypes.PROPOSAL &&
      this.selectedTemplate.listType === this.listTypeEnum.DETAILED &&
      this.selectedTemplate.photoType === this.templateImageType.LARGE;
  }

  getPropertyValue(property: string) {
    return property.split('.').reduce((obj, key) => Array.isArray(obj) && obj.indexOf(key) > -1 ? true : obj[key], this.selectedTemplateCopy);
  }

  storeFormValuesInLS(listType: DocumentTemplate.ListType) {
    // store form values to LocalStorage
    const storableOptions: ProductListRememberedOptions = {
      photoType: this.selectedTemplateCopy.photoType,
      isDescription: this.selectedTemplateCopy.isDescription,
      isSystem: this.selectedTemplateCopy.isSystem,
      isCategory: this.selectedTemplateCopy.isCategory,
      isFullCode: this.selectedTemplateCopy.isFullCode,
      isDimensions: this.selectedTemplateCopy.isDimensions,
      isVolumeAndWeight: this.selectedTemplateCopy.isVolumeAndWeight,
      materialTypes: this.selectedTemplateCopy.materialTypes,
      isDiscount: this.selectedTemplateCopy.isDiscount,
      isNetPrice: this.selectedTemplateCopy.isNetPrice
    };

    localStorage.setItem(
      LocalStorageKeys.DOCUMENT_TEMPLATE_STORAGE_KEY,JSON.stringify(
        {
          ...JSON.parse(localStorage.getItem(LocalStorageKeys.DOCUMENT_TEMPLATE_STORAGE_KEY)),
          ...{ [listType]: storableOptions }
        }
      )
    );
  }

  loadStoredFormValues(listType: DocumentTemplate.ListType): boolean {
    const lsCopy = localStorage.getItem(LocalStorageKeys.DOCUMENT_TEMPLATE_STORAGE_KEY)
    if (!lsCopy) {
      return false;
    }

    const storedOptions = JSON.parse(lsCopy);
    if (storedOptions[listType]) {
      this.selectedTemplateCopy.photoType = storedOptions[listType].photoType;
      this.selectedTemplateCopy.isDescription = storedOptions[listType].isDescription;
      this.selectedTemplateCopy.isSystem = storedOptions[listType].isSystem;
      this.selectedTemplateCopy.isCategory = storedOptions[listType].isCategory;
      this.selectedTemplateCopy.isFullCode = storedOptions[listType].isFullCode;
      this.selectedTemplateCopy.isDimensions = storedOptions[listType].isDimensions;
      this.selectedTemplateCopy.isVolumeAndWeight = storedOptions[listType].isVolumeAndWeight;
      this.selectedTemplateCopy.materialTypes = storedOptions[listType].materialTypes;
      this.selectedTemplateCopy.isDiscount = storedOptions[listType].isDiscount;
      this.selectedTemplateCopy.isNetPrice = storedOptions[listType].isNetPrice;

      return true;
    }

    return false;
  }

  onSelectPhotoType(value) {
    // backup to LS
    this.storeFormValuesInLS(this.selectedTemplateCopy.listType);

    this.selectedTemplateCopy.photoType = value.id;

    // restore from LS if exists
    if (!this.loadStoredFormValues(value.id)) {
      // nothing was loaded from save, so presets can be applied safely
      this.applyPresets(ProductListRememberedPresetType.PHOTO_TYPE, value.id);
    }

    this.updateTemplate();
  }

  onSelectTemplateListType(value) {
    // backup to LS
    this.storeFormValuesInLS(this.selectedTemplateCopy.listType);

    this.selectedTemplateCopy.listType = value.id;
    // restore from LS if exists
    if (!this.loadStoredFormValues(value.id)) {
      // nothing was loaded from save, so presets can be applied safely
      this.applyPresets(ProductListRememberedPresetType.LIST_TYPE, value.id);
    }

    this.updateTemplate();
  }

  applyPresets(type: ProductListRememberedPresetType, value: number|string|DocumentTemplate.ImageType|DocumentTemplate.ListType) {
    switch (type) {
      case ProductListRememberedPresetType.LIST_TYPE:
        switch (value) {
          case DocumentTemplate.ListType.DETAILED:
            // column 1
            this.selectedTemplateCopy.isDescription = true;
            this.selectedTemplateCopy.isSystem = true;
            this.selectedTemplateCopy.isCategory = true;
            this.selectedTemplateCopy.isFullCode = true;

            // column 2
            this.selectedTemplateCopy.isDimensions = true;
            this.selectedTemplateCopy.isVolumeAndWeight = true;

            // column 3
            this.selectedTemplateCopy.photoType = DocumentTemplate.ImageType.SMALL;
            this.selectedTemplateCopy.materialTypes = [
              DocumentTemplate.MaterialType.IMAGE,
              DocumentTemplate.MaterialType.TITLE,
              DocumentTemplate.MaterialType.CODE
            ];

            // column 4
            this.selectedTemplateCopy.isDiscount = true;
            break;
          case DocumentTemplate.ListType.CONFIGURATION:
            // column 1
            this.selectedTemplateCopy.isDescription = false;
            this.selectedTemplateCopy.isSystem = true;
            this.selectedTemplateCopy.isCategory = true;
            this.selectedTemplateCopy.isFullCode = false;

            // column 2
            this.selectedTemplateCopy.isDimensions = true;
            this.selectedTemplateCopy.isVolumeAndWeight = true;

            // column 3
            this.selectedTemplateCopy.photoType = DocumentTemplate.ImageType.SMALL;
            this.selectedTemplateCopy.materialTypes = [
              DocumentTemplate.MaterialType.IMAGE,
              DocumentTemplate.MaterialType.TITLE,
              DocumentTemplate.MaterialType.CODE
            ];

            // column 4
            this.selectedTemplateCopy.isNetPrice = true;
            this.selectedTemplateCopy.isDiscount = true;
            break;
        }
        break;

      case ProductListRememberedPresetType.PHOTO_TYPE:
        switch (value) {
          case DocumentTemplate.ImageType.LARGE:
            if (this.selectedTemplateCopy.listType === DocumentTemplate.ListType.DETAILED) {
              this.selectedTemplateCopy.materialTypes = [];
            } else {
              this.selectedTemplateCopy.materialTypes = [
                DocumentTemplate.MaterialType.IMAGE,
                DocumentTemplate.MaterialType.TITLE,
                DocumentTemplate.MaterialType.CODE
              ];
            }
            break;
          case DocumentTemplate.ImageType.SMALL:
            this.selectedTemplateCopy.materialTypes = [
              DocumentTemplate.MaterialType.IMAGE,
              DocumentTemplate.MaterialType.TITLE,
              DocumentTemplate.MaterialType.CODE
            ];
            break;
          case DocumentTemplate.ImageType.HIDDEN:
            this.selectedTemplateCopy.materialTypes = [
              DocumentTemplate.MaterialType.IMAGE,
              DocumentTemplate.MaterialType.TITLE,
              DocumentTemplate.MaterialType.CODE
            ];
            break;
        }
        break;
    }
  }

  onTemplateFieldChange(status: boolean, templateFieldProperty: string) {
    this.documentTemplateService.setBusyState(true);
    // material properties uses one materialType field on BE with array of values
    const materialProperties = Object.keys(MaterialPropertiesMap);
    if (materialProperties.includes(templateFieldProperty as DocumentTemplate.MaterialType)) {
      const materialTypeValue = this.selectedTemplateCopy.materialTypes || [];
      const materialMapped = MaterialPropertiesMap[templateFieldProperty];

      if (status && !materialTypeValue.includes(materialMapped)) {
        materialTypeValue.push(materialMapped);
      } else {
        materialTypeValue.splice(materialTypeValue.indexOf(materialMapped), 1);
      }

      this.selectedTemplateCopy['materialTypes'] = materialTypeValue;
      this.updateTemplate();

      return;
    }

    this.selectedTemplateCopy[templateFieldProperty] = status;
    this.updateTemplate();
  }

  private updateTemplate() {
    this.documentTemplateService.updateSelectedTemplate(this.selectedTemplateCopy);
  }

  ngOnDestroy() {
    localStorage.removeItem(LocalStorageKeys.DOCUMENT_TEMPLATE_STORAGE_KEY);
    this.subscriptions.unsubscribe();
  }
}
