<div class="row" [formGroup]="form">
  <div class="col-6" [formGroupName]="'provider'">
    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.PROVIDER' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.PROVIDER' | translate"
      name="providerCompanyName"
      [formControl]="form.controls.provider.controls.companyName"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate"
      name="providerAddress"
      [formControl]="form.controls.provider.controls.address"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.COMPANY_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.COMPANY_CODE' | translate"
      name="providerCompanyCode"
      [formControl]="form.controls.provider.controls.companyCode"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.VAT_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.VAT_CODE' | translate"
      name="providerVATCode"
      [formControl]="form.controls.provider.controls.vatCode"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [class.vat-enabled]="!!selectedTemplate.isVat"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.VAT_RATE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.VAT_RATE' | translate"
      name="providerVATRate"
      [formControl]="form.controls.provider.controls.vatRate"
      [required]="!!selectedTemplate.isVat"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.BANK_ACCOUNT_NUMBER' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.BANK_ACCOUNT_NUMBER' | translate"
      name="providerIban"
      [formControl]="form.controls.provider.controls.iban"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.BANK' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.BANK' | translate"
      name="providerBank"
      [formControl]="form.controls.provider.controls.bank"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.BANK_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.BANK_CODE' | translate"
      name="providerBankCode"
      [formControl]="form.controls.provider.controls.bankCode"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.SWIFT_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.SWIFT_CODE' | translate"
      name="providerSwift"
      [formControl]="form.controls.provider.controls.swift"
    >
    </app-text-field>
  </div>
  <div class="col-6" [formGroupName]="'customer'">
    <app-ui-elements-form-drop-down
      [values]="customerDataTypes"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.CLIENT_DATA.TITLE' | translate"
      [selectedValue]="data.clientDataType"
      labelField="title"
      valueField="id"
      (onChange)="onCustomerDataTypeSelect($event.id)"
    ></app-ui-elements-form-drop-down>
    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.CUSTOMER' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.CUSTOMER' | translate"
      name="clientName"
      [formControl]="form.controls.customer.controls.companyName"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate"
      name="clientAddress"
      [formControl]="form.controls.customer.controls.address"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.COMPANY_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.COMPANY_CODE' | translate"
      name="clientCompanyCode"
      [formControl]="form.controls.customer.controls.companyCode"
    >
    </app-text-field>

    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.VAT_CODE' | translate) + ':'"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.VAT_CODE' | translate"
      name="clientVatCode"
      [formControl]="form.controls.customer.controls.vatCode"
    >
    </app-text-field>
  </div>
</div>
