import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, NgZone, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import Dropzone from 'dropzone';
import { Mime } from 'mime';
import standardTypes from 'mime/types/standard.js';
import otherTypes from 'mime/types/other.js';
import { FileUploadErrorEnum } from '../../../core/enums/file-upload-error.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFileInterface, BaseFileWrapperInterface, DropzoneFileInterface, DropzoneFileListInterface } from '../../../core/models/file.model';
import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/auth.service';
import { ImpersonateService } from '../../../core/impersonate/impersonate.service';
import { FileSizeHelper } from '../../../core/util/file-size.helper';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { ErrorService, DropzoneFileUploadErrorResponseInterface } from '../../services/error/error.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgScrollbar } from 'ngx-scrollbar';
import { FilePreviewComponent } from '../../../ui-elements/file-preview/file-preview.component';
import { SharedModule } from '../../shared.module';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { UuidGenerator } from '../../../core/util/uuid.generator';

export const FILE_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true,
};

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FILE_INPUT_ACCESSOR],
  imports: [SharedModule, NgClickOutsideDirective, DropzoneModule, NgScrollbar, FilePreviewComponent, SvgIconComponent]
})
export class FileInputComponent <
  TFile extends BaseFileInterface,
  TFileWrapper extends BaseFileWrapperInterface<TFile>
> implements OnInit, OnChanges, ControlValueAccessor {
  model: TFileWrapper[] = [];
  config: DropzoneConfigInterface | null = null;
  isDisabled = false;
  deletedFilesIds: number[] = [];
  draggingOverDropzone = false;
  maxFileSizeInGB = 1;
  maxNumberOfParallelUploads = 20;
  mime: Mime;

  FileUploadErrors = FileUploadErrorEnum;

  @Input() id = UuidGenerator.uuid();
  @Input() title?: string;
  @Input() disabled = false;
  @Input() dropEndpoint = '';
  @Input() fileTyping?: any;
  @Input() dropZoneName = 'dropzone';
  @Input() formErrors: string | null;
  @Input() uploadPrevented = false;
  @Input() autoUpload = true;
  @Input() multiple = false;
  @Input() deleteHandler?: (file: TFileWrapper) => Observable<any>|null;

  @Output() fileDelete = new EventEmitter<number>();
  @Output() fileAdded = new EventEmitter<DropzoneFileInterface>();
  @Output() fileUploadFailed = new EventEmitter<DropzoneFileInterface>();

  @Input() set acceptedFiles(value: string) {
    if (!value.includes(this.standardSnippetExtension)) {
      value += `,${this.standardSnippetExtension}`;
    }

    this._acceptedFiles = value;
  }

  private _acceptedFiles: string = '';
  get acceptedFiles(): string {
    return this._acceptedFiles;
  }

  dropzoneInstance: Dropzone;
  fileUploadError: FileUploadErrorEnum = FileUploadErrorEnum.NONE;
  readonly standardSnippetExtension = '.png';

   writeValue(value: TFileWrapper[]): void {
    this.model = value;
  }

  constructor(
    private translator: TranslateService,
    private toastService: ToastService,
    private authService: AuthService,
    private impersonateService: ImpersonateService,
    private zone: NgZone,
    private errorService: ErrorService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.isDisabled = this.disabled || this.uploadPrevented;

    this.mime = new Mime(standardTypes, otherTypes);
    this.mime.define({
      'application/postscript': ['eps'],
    });

    if (!this.isDisabled) {
      this.setupDropZone();
    }
  }

  ngOnChanges() {
    this.isDisabled = this.disabled || this.uploadPrevented;

    if (!this.isDisabled && !this.config) {
      this.setupDropZone();
    }
  }

  onChangedCallback = (value: TFileWrapper[]) => { };
  registerOnChange(fn: (value: TFileWrapper[]) => {}): void {
    this.onChangedCallback = fn;
  }

  onTouchedCallback = () => { };
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onKeyboardPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData?.items;

    if (!clipboardItems.length || this.uploadPrevented || this.disabled) {
      return;
    }

    const addedFilesForUpload: Dropzone.DropzoneFile[] = [];

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      const blob = item.getAsFile();
      // blob.type and item.type returns empty string for dwg files,
      // so mime.getExtension() returns empty string too
      const extension = `.${this.mime.getExtension(item.type) || blob.name.split('.').pop()}`;
      const isImage = item.type.startsWith('image/');
      const allImagesAllowed = this.acceptedFiles.includes('image/*');

      const isFileAccepted = this.acceptedFiles.includes(extension) || (isImage && allImagesAllowed);

      if (!isFileAccepted) {
        continue;
      }

      const filename = `${Date.now()}${extension}`;
      const file = new File([blob], filename, { type: blob.type });

      const dropzoneFile: Dropzone.DropzoneFile = Object.assign(file, {
        accepted: true,
        status: 'queued',
        previewElement: null,
        previewTemplate: null,
        previewsContainer: null,
      });

      this.dropzoneInstance.addFile(dropzoneFile);
      addedFilesForUpload.push(dropzoneFile);
    }

    if (addedFilesForUpload.length) {
      this.dropzoneInstance.emit('addedfiles', addedFilesForUpload);

      return;
    }

    this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;
  }

  async onButtonPaste(e: MouseEvent) {
    e.stopPropagation();

    if (this.uploadPrevented || this.disabled) {

      return;
    }

    try {
      const clipboardItems = await navigator.clipboard.read();

      if (!clipboardItems.length) {
        return;
      }

      const imageMimeType = clipboardItems[0].types.find((type) => type.startsWith('image/'));

      if (!imageMimeType) {
        this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;

        return;
      }

      const extension = `.${this.mime.getExtension(imageMimeType)}`;

      const isFileAccepted = ['image/*', extension].some((type) => this.acceptedFiles.includes(type));

      if (!isFileAccepted) {
        this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;

        return;
      }

      const blob = await clipboardItems[0].getType(imageMimeType);
      const filename = `${Date.now()}${extension}`;

      const file = new File([blob], filename, { type: imageMimeType });
      const dropzoneFile: Dropzone.DropzoneFile = Object.assign(file, {
        accepted: true,
        status: 'queued',
        previewElement: null,
        previewTemplate: null,
        previewsContainer: null,
      });

      this.dropzoneInstance.addFile(dropzoneFile);
      this.dropzoneInstance.emit('addedfiles', [dropzoneFile]);
    } catch (error) {}
  }

  setupDropZone() {
    const component = this;
    const url = this.prepareUrl(this.dropEndpoint);

    this.config = {
      url: url.toString(),
      acceptedFiles: this.acceptedFiles,
      parallelUploads: this.maxNumberOfParallelUploads,
      // According to the Dropzone.js documentation, the maximum file size should be specified in bytes.
      // However, in practice, the specified value is interpreted as megabytes.
      maxFilesize: FileSizeHelper.convertGigabytesToMegabytes(component.maxFileSizeInGB),
      disablePreviews: true,
      dictDefaultMessage: this.translator.instant('ACTIONS.UPLOAD'),
      clickable: `.dropzone-button-${component.dropZoneName}`,
      uploadMultiple: this.multiple,
      autoProcessQueue: false,
      headers: {
        Authorization: `Bearer ${this.authService.getToken()}`
      },
      init: function () {
        const dropzoneInstance = this;
        component.dropzoneInstance = dropzoneInstance;

        this.on('addedfiles', (files: DropzoneFileInterface[] | DropzoneFileListInterface) => {
          component.zone.run(() => {
            const filesArray: DropzoneFileInterface[] = Array.isArray(files) ? files : Array.from(files);

            if (filesArray.length > component.maxNumberOfParallelUploads) {
              component.fileUploadError = FileUploadErrorEnum.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED;

              filesArray.forEach((file) => {
                dropzoneInstance.removeFile(file);
              });
              return;
            }

            const allFilesAccepted = filesArray.every((file) => file.accepted);

            if (!allFilesAccepted) {
              filesArray.forEach((file) => {
                dropzoneInstance.removeFile(file);
              });
              return;
            }

            filesArray.forEach((file) => component.addTempFile(file));

            if (component.autoUpload) {
              dropzoneInstance.processQueue();
            }
          });
        });
        this.on('error', (file: DropzoneFileInterface, message: string, xhr: XMLHttpRequest) => {
          component.fileUploadFailed.emit(file);
          component.zone.run(() => {
            component.removeTempFile(file as unknown as TFile);

            // in case of server-side error
            if (xhr) {
              if (xhr.status === HttpStatusCode.Forbidden) {
                component.translator.get('CREATE_DOCUMENT.TEMPLATE.ATTACH_IMAGE_ERROR').subscribe((translation: string) => {
                  component.toastService.danger(translation);
                });

                return;
              }

              const response: DropzoneFileUploadErrorResponseInterface = JSON.parse(xhr.responseText);


              const fileErrors =  component.errorService.extractDropzoneFileErrors(response);

              if (component.errorService.includesMimeTypeError(fileErrors)) {
                component.translator.get('DRAG_DROP.FORMS.ERRORS.UNSUPPORTED_TYPE').subscribe((translation) => {
                  component.toastService.danger(translation);
                });

                return;
              }

              if (component.errorService.includesFileSizeError(fileErrors)) {
                component.translator.get('DRAG_DROP.FORMS.ERRORS.TOO_LARGE').subscribe((translation) => {
                  component.toastService.danger(translation);
                });

                return;
              }

              component.translator.get('CREATE_DOCUMENT.TEMPLATE.ATTACH_IMAGE_ERROR').subscribe((translation: string) => {
                component.toastService.danger(translation);
              });

              return;
            }

            const maxFileSizeInBytes = FileSizeHelper.convertGigabytesToBytes(component.maxFileSizeInGB);

            if (file.size > maxFileSizeInBytes) {
              component.fileUploadError = FileUploadErrorEnum.FILE_TOO_LARGE;
              return;
            }

            component.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;
          });
        });
        this.on('success', (file: DropzoneFileInterface) => {
          component.fileUploadError = FileUploadErrorEnum.NONE;
          component.fileAdded.emit(file);
        });
        this.on('thumbnail', (file, dataUrl: string) => {
          component.zone.run(() => {
            component.addTempFile(file, dataUrl);
          });
        });
      },
    } as DropzoneConfigInterface;
  }

  onUploadSuccess(event) {
    const [request, response, __] = event;
    const { filename } = request.upload;

    const fileIndex = this.model.findIndex(({ file, inProgress }) => inProgress && file.name === filename);
    this.model[fileIndex] = response.data;

    this.onChangedCallback(this.model);
  }

  onRemoveFile(file: TFileWrapper) {
    const { id } = file.file;

    if (this.isDisabled || this.deletedFilesIds.includes(id)) {
      return;
    }

    this.deletedFilesIds.push(id);
    const fileIndex = this.model.findIndex(({ file }) => file.id == id);

    // Use custom delete handler if provided
    const deleteOperation = this.deleteHandler
      ? this.deleteHandler(file)
      : of(true);

    deleteOperation.subscribe({
      next: () => {
        this.model.splice(fileIndex, 1);
        this.onChangedCallback(this.model);
        this.fileDelete.emit(id);
        this.deletedFilesIds.splice(this.deletedFilesIds.indexOf(id), 1);
      },
      error: () => {
        this.deletedFilesIds.splice(this.deletedFilesIds.indexOf(id), 1);
      }
    });
  }

  addTempFile(sourceFile: DropzoneFileInterface, dataUrl?: string) {
    const newFile = {
      file: {
        id: null,
        name: sourceFile.name,
        extension: sourceFile.name.split('.').pop(),
        dataUrl: sourceFile.dataURL ?? '',
      } as TFile,
      inProgress: true,
    } as TFileWrapper;

    // find possibly existing file and update it - this happens when thumbnail gets generated
    const fileIndex = this.model.findIndex(({ file, inProgress }) => inProgress && file.name === sourceFile.name);
    if (fileIndex >= 0) {
      this.model[fileIndex] = newFile;
      this.onChangedCallback(this.model);
      return;
    }

    this.model.push(newFile);
    this.onChangedCallback(this.model);
  }

  removeTempFile(completedFile: TFile) {
    const fileIndex = this.model.findIndex(({ file, inProgress }) => inProgress && file.name === completedFile.name);

    if (fileIndex >= 0) {
      this.model.splice(fileIndex, 1);
    }

    this.onChangedCallback(this.model);
  }

  onDraggingOverDropzoneStart(): void {
    this.draggingOverDropzone = true;
  }

  onDraggingOverDropzoneEnd(): void {
    this.draggingOverDropzone = false;
  }

  resetError(): void {
    this.fileUploadError = FileUploadErrorEnum.NONE;
  }

  prepareUrl(url: string): string {
    // fallback to some default if no url is provided
    const urlObject = url?.length ? new URL(url) : new URL(`${environment.api}upload`);

    if (this.impersonateService.impersonated()) {
      const impersonatedUser = this.impersonateService.getUser();

      urlObject.searchParams.append(
        '_impersonate',
        impersonatedUser.email
      );
    }

    return urlObject.toString();
  }

  updateDropEndpoint(url: string): void {
    this.dropzoneInstance.options.url = this.prepareUrl(url);
    this.changeDetectionRef.detectChanges();
  }

  triggerQueue(): void {
    this.dropzoneInstance.processQueue();
  }
}
