<form [formGroup]="form" class="navbar-form">
  <div class="position-relative deep-hover-color-black">
    <input
      #inputElement
      type="text"
      name="query"
      formControlName="query"
      class="form-control navbar-form__search-input ms-1 pe-5"
      id="query"
      [placeholder]="getPlaceholder() | translate"
      [title]="getPlaceholder() | translate"
    />
    <div class="position-absolute end-0 top-0 bottom-0 p-2 search-icon deep-hover">
      <i
        *ngIf="!form.controls.query.value"
        class="icon ni-search"
        (click)="inputElement.focus()"
      ></i>
      <i
        *ngIf="form.controls.query.value"
        class="icon ni-cancel-fill"
        (click)="clear()"
      ></i>
    </div>
  </div>
</form>
