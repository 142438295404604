import { Injectable } from '@angular/core';
import { PriceRequestItemFileInterface, PriceRequestItemFileTypeEnum, PriceRequestItemInterface, PriceRequestItemTypeEnum } from '../../../core/models/price-request-item.model';
import { FileData, imageFileExtension } from '../../../core/models/file-data.model';
import { ApiService } from '../../../api.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomPriceRequestItemFormModalComponent } from '../modals/custom-price-request-item-form-modal/custom-price-request-item-form-modal.component';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { Observable, map } from 'rxjs';
import { PriceRequestItemModalComponent } from '../modals/price-request-item-modal/price-request-item-modal.component';
import { BasedOnStandardPriceRequestItemFormModalComponent } from '../modals/based-on-standard-price-request-item-form-modal/based-on-standard-price-request-item-form-modal.component';
import { ClarificationModalComponent } from '../modals/clarification-modal/clarification-modal.component';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';
import { environment } from '../../../../environments/environment';

export interface CreatePriceRequestItemInterface {
  type: PriceRequestItemTypeEnum;
  title?: string;
  quantity?: number;
  description?: string;
  dimensions?: string;
  finishes?: string;
  itemId?: string;
}

export interface PriceRequestItemFileUploadResponseInterface {
  data: {
    id: number;
    type: PriceRequestItemFileTypeEnum,
    file: PriceRequestItemFileInterface
  }
}

@Injectable({
  providedIn: 'root',
})
export class PriceRequestItemsService {

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private listModeSwitchService: ListModeSwitchService,
  ) { }

  getTotalPrice(item: PriceRequestItemInterface): number {
    const price = this.listModeSwitchService.isSaleMode() ? item.salePrice : item.purchasePrice;
    return price * item.quantity;
  }

  getFirstImageFile(item: PriceRequestItemInterface): FileData | undefined {
    return item.customMadePriceRequestItemFiles
      ?.map(({ file }) => file)
      .find(({ extension }) => imageFileExtension.includes(extension));
  }

  create(id: number, data: CreatePriceRequestItemInterface): CacheableObservable<PriceRequestItemInterface> {
    return this.api
      .post(`custom-made-price-requests/${id}/items/`, data)
      .pipe(map(({ data }) => data)) as CacheableObservable<PriceRequestItemInterface>;
  }

  update(id: number, itemId: number, data: PriceRequestItemInterface, full = false): CacheableObservable<PriceRequestItemInterface> {
    delete data.customMadePriceRequestItemFiles;
    return this.api.patch(`custom-made-price-requests/${id}/items/${itemId}`, data, {
      full: full ? 1 : 0
    }).pipe(map(({ data }) => data)) as CacheableObservable<PriceRequestItemInterface>;
  }

  delete(priceRequestId: number, priceRequestItemIds: number | number[]) {
    return this.api.delete(`custom-made-price-requests/${priceRequestId}/items/`, {}, {
      'ids[]': Array.isArray(priceRequestItemIds) ? priceRequestItemIds : [priceRequestItemIds]
    });
  }

  deleteFile(priceRequestId: number, priceRequestItemId: number, fileId: number) {
    return this.api.delete(`custom-made-price-requests/${priceRequestId}/items/${priceRequestItemId}/files/${fileId}`, {});
  }

  pasteItems(priceRequestId: number, items: PriceRequestItemInterface[]) {
    return this.api.post(`custom-made-price-requests/${priceRequestId}/items/paste`, {
      customMadePriceRequestItems: items.map(({ id }) => id)
    });
  }

  submitClarification(priceRequestId: number, priceRequestItemId: number, clarificationAnswer: string): Observable<any> {
    return this.api.post(`custom-made-price-requests/${priceRequestId}/items/${priceRequestItemId}/clarify`, { clarificationAnswer });
  }

  openCustomPriceRequestItemFormModal(priceRequestId: number, priceRequestItemid: number, values: PriceRequestItemInterface): NgbModalRef {
    const modalRef = this.modalService.open(CustomPriceRequestItemFormModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      modalDialogClass: 'align-items-stretch'
    });

    const instance = modalRef.componentInstance as CustomPriceRequestItemFormModalComponent;

    instance.priceRequestId = priceRequestId;
    instance.priceRequestItemId = priceRequestItemid;
    instance.constructForm(values);

    return modalRef;
  }

  openBasedOnStandardPriceRequestItemFormModal(
    priceRequestId: number,
    item: PriceRequestItemInterface,
    loading: boolean = false
  ): NgbModalRef {
    const modalRef = this.modalService.open(BasedOnStandardPriceRequestItemFormModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      modalDialogClass: 'align-items-stretch',
    });

    const instance = modalRef.componentInstance as BasedOnStandardPriceRequestItemFormModalComponent;

    instance.priceRequestId = priceRequestId;
    instance.loading = loading;

    if (!loading) {
      instance.item = item;
      instance.constructForm(item);
      instance.initializeSubscriptions();
    }

    return modalRef;
  }

  openPriceRequestItemModal(item: PriceRequestItemInterface): NgbModalRef {
    const modalRef = this.modalService.open(PriceRequestItemModalComponent, {
      size: 'xl',
      centered: true,
      modalDialogClass: 'align-items-stretch'
    });

    const instance = modalRef.componentInstance as PriceRequestItemModalComponent;

    instance.item = item;

    return modalRef;
  }

  openPriceRequestItemClarificationModal(items: PriceRequestItemInterface[]): NgbModalRef {
    const modalRef = this.modalService.open(ClarificationModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    const instance = modalRef.componentInstance as ClarificationModalComponent;

    instance.priceRequestItems = items;

    return modalRef;
  }

  getFileUploadEndpoint(priceRequestId: number, priceRequestItemId: number, fileType: PriceRequestItemFileTypeEnum): string {
    return `${environment.api}custom-made-price-requests/${priceRequestId}/items/${priceRequestItemId}/files/${fileType}`;
  }

  getFileDeleteHandler(priceRequestId: number, priceRequestItemId: number) {
    return (file: PriceRequestItemFileInterface): Observable<any> => {
      return this.deleteFile(priceRequestId, priceRequestItemId, file.id);
    }
  }
}
