import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProductsService } from '../../catalogue/products/products.service';
import { ConfiguratorModalActionType } from '../../core/enums/configurator-modal-action-type.enum';
import { SelectionMenuEntityEnum } from '../../core/enums/selection-menu-entity.enum';
import { UserRole } from '../../core/enums/user-role.enum';
import { OrderArticleInterface } from '../../core/models/order-article.model';
import { OrderInterface } from '../../core/models/order.model';
import { PriceRequestInterface } from '../../core/models/price-request.model';
import { UserService } from '../../core/services/user/user.service';
import { ExtraListElementInterface } from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { MenuTogglerComponent } from '../../selection-menu/menu-toggler/menu-toggler.component';
import { OrderSelectOnChangeEventInterface } from '../../selection-menu/selection-menu.component';
import { SelectionMenuService } from '../../selection-menu/selection-menu.service';
import { ListModeSwitchService } from '../../shared/components/list-mode-switch/list-mode-switch.service';
import { DisableByRoleDirective } from '../../shared/directives/disable-by-role/disable-by-role.directive';
import { SharedModule } from '../../shared/shared.module';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { QuantitySpinnerChangeEvent, QuantitySpinnerComponent } from "../../ui-elements/quantity-spinner/quantity-spinner.component";
import { VisibilityByVariablesComponent } from '../../visibility-by-variables/visibility-by-variables.component';
import { ConfiguratorModalService } from '../configurator-modal.service';
import { PriceDetailsModalComponent } from '../price-details-modal/price-details-modal.component';
import { PriceDetailsComponent, PriceDetailsInterface } from './price-details/price-details.component';

export interface FooterOnOrderEventInterface {
  quantity: number;
  order: OrderInterface;
  group?: ExtraListElementInterface;
}

export interface FooterOnAddToPriceRequestEventInterface {
  quantity: number;
  priceRequest: PriceRequestInterface;
}

@Component({
    selector: 'app-configurator-modal-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [SharedModule, QuantitySpinnerComponent, DisableByRoleDirective, MenuTogglerComponent, PriceDetailsComponent, VisibilityByVariablesComponent, LoaderComponent]
})
export class FooterComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('loader', { static: true }) loader;
  @ViewChild('quantitySpinner') private quantitySpinner: QuantitySpinnerComponent;

  form: UntypedFormGroup;
  priceDetails: PriceDetailsInterface;
  isDisabled = false;
  articlesCount = 1;

  selectedOrder: OrderInterface;
  selectedGroup: ExtraListElementInterface;
  selectedPriceRequest: PriceRequestInterface;
  hideMigrationIssues = false;
  acceptedMigrationIssuesCount = 0;

  private subscriptions: Subscription = new Subscription();

  @Input() item: string;
  @Input() orderArticle?: OrderArticleInterface;
  @Input() selectedEntity: SelectionMenuEntityEnum | null;

  @Input() price?: number;

  @Input() quantity: number;
  @Input() organisation: any;
  @Input() canOrder: boolean;

  @Input() lastUpdate: number;
  @Input() action: ConfiguratorModalActionType;
  @Input() hasMigrationIssues: number;
  @Input() configurationLoaded = false;

  @Output() orderSelect: EventEmitter<OrderSelectOnChangeEventInterface> = new EventEmitter<OrderSelectOnChangeEventInterface>();
  @Output() createOrder: EventEmitter<FooterOnOrderEventInterface> = new EventEmitter<FooterOnOrderEventInterface>();
  @Output() updateOrder: EventEmitter<FooterOnOrderEventInterface> = new EventEmitter<FooterOnOrderEventInterface>();
  @Output() addToPriceRequestClicked = new EventEmitter<FooterOnAddToPriceRequestEventInterface>();
  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() openMigrationLog = new EventEmitter<void>();

  configuratorModalActionType = ConfiguratorModalActionType;
  selectableEntities = SelectionMenuEntityEnum;

  userRole = UserRole;
  isDealer: boolean;

  constructor(
    private selectionMenuService: SelectionMenuService,
    private configuratorModalService: ConfiguratorModalService,
    private translateService: TranslateService,
    private productsService: ProductsService,
    private saleModeService: ListModeSwitchService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.checkPriceAndOrder();
  }

  private checkPriceAndOrder() {
    if (!this.configurationLoaded) {
      return;
    }

    this.canOrder = !!this.selectedOrder;
    this.refetchPrice(this.quantity);
  }

  ngOnInit() {
    this.canOrder = !!this.selectedOrder;
    this.loader.show();
    this.subscriptions.add(this.configuratorModalService.processingAsObservable().subscribe(processing => this.loader.visible(processing)));
    this.subscriptions.add(
      this.configuratorModalService.disabledStateObservable().subscribe(disabledState => (this.isDisabled = disabledState))
    );

    this.subscriptions.add(
      this.selectionMenuService.selectedOrderAsObservable().subscribe(selectedOrder => {
        this.selectedOrder = selectedOrder;
        this.checkPriceAndOrder();
      })
    );

    this.subscriptions.add(
      this.selectionMenuService.selectedGroupAsObservable().subscribe(selectedGroup => {
        this.selectedGroup = selectedGroup;
      })
    );

    this.subscriptions.add(
      this.selectionMenuService.selectedPriceRequestAsObservable().subscribe((selectedPriceRequest) => {
        this.selectedPriceRequest = selectedPriceRequest;
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.hideMigrationIssuesObservable().subscribe(hideMigrationIssues => {
        this.hideMigrationIssues = hideMigrationIssues;
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.acceptedMigrationsMapObservable().subscribe(acceptedMigrationsMap => {
        this.acceptedMigrationIssuesCount = acceptedMigrationsMap.size;
      })
    );

    this.isDealer = this.userService.isDealer();
  }

  ngOnDestroy() {
    this.isDisabled = false;
    this.subscriptions.unsubscribe();
  }

  onOrderSelect(event) {
    this.orderSelect.emit(event);
  }

  onAddToPriceRequest() {
    this.addToPriceRequestClicked.emit({ quantity: this.quantity, priceRequest: this.selectedPriceRequest });
  }

  onCreateOrder() {
    if (this.canOrder && !this.isDisabled && this.quantitySpinner.input.valid) {
      this.emitEvent();
    } else {
      if (!this.selectedOrder) {
        this.selectionMenuService.open();
      }
    }
  }

  onQuantityChange(event: QuantitySpinnerChangeEvent) {
    this.quantity = event.quantity;
    this.refetchPrice(this.quantity);
  }

  emitEvent() {
    const event = this.selectedOrder && this.selectedOrder.id ? this.updateOrder : this.createOrder;

    // @ts-ignore: global window.dataLayer
    window.dataLayer.push({
      event: this.selectedOrder && this.selectedOrder.id ? 'configuratorSave' : 'configuratorCreateOrder',
      url: window.location.href,
      order: this.selectedOrder && this.selectedOrder.id ? this.selectedOrder.id : 'none',
      quantity: this.quantity,
      item_id: this.orderArticle && this.orderArticle.id ? this.orderArticle.id : 'none',
      item_name: this.orderArticle && this.orderArticle.shortText ? this.orderArticle.shortText : 'none',
      item_variant: this.orderArticle && this.orderArticle.fullCode ? this.orderArticle.fullCode : 'none',
      item_category: this.orderArticle && this.orderArticle.system ? this.orderArticle.system : 'none',
    });

    this.translateService.get('ORDERS.UNTITLED_OFFER').subscribe((translation: string) => {
      event.emit({
        quantity: this.quantity,
        order: this.selectedOrder || ({ title: translation } as OrderInterface),
        group: this.selectedGroup ?? null,
      });
    });
  }

  getWarningMessage(): string {
    if (!this.selectedOrder) {
      return 'CONFIGURATOR_MODAL.ERRORS.NO_ORDER_SELECTED';
    }
    return 'CONFIGURATOR_MODAL.ERRORS.ADD_REQUIRED_STUFF';
  }

  onDetailedViewClick() {
    const modalRef = this.modalService.open(PriceDetailsModalComponent, {size: 'fit'});
    modalRef.componentInstance.item = this.item;
    modalRef.componentInstance.quantity = this.quantity;
    modalRef.componentInstance.selectedOrder = this.selectedOrder;
    modalRef.componentInstance.orderArticle = this.orderArticle;
  }

  onOpenMigrationLog() {
    this.openMigrationLog.emit();
  }

  private refetchPrice(quantity: number) {
    this.productsService
      .fetchProductPrice({
        quantity: quantity,
        saleMode: this.saleModeService.getSaleMode(),
        order: this.selectedOrder ? this.selectedOrder.id : null,
        orderArticle: this.orderArticle ? this.orderArticle.id : null,
      })
      .noCache()
      .subscribe((data: PriceDetailsInterface) => {
        this.configuratorModalService.setSelectedArticlePrice(data.selectedItemTotalPrice);

        this.priceDetails = {
          ...data,
          previousPrice: this.price,
          previousCount: this.articlesCount,
        };
        this.price = data.totalPrice;
        this.articlesCount = data.orderArticles.length;

        this.changeDetectorRef.detectChanges();
      });
  }

  openSelectionMenu() {
    this.selectionMenuService.open();
  }
}
