import {Injectable} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user/user.service';
import {UserInterface} from '../models/user.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteByRolesGuard  {
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Observable<boolean>(observer => {
      this
        .userService
        .fromStorage()
        .subscribe((user: UserInterface) => {
          observer.next(childRoute.data.allowedRoles.includes(user.role.name));
          observer.complete();
        });
    });
  }
}
