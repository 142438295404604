import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {UserInterface} from '../models/user.model';
import {UserRole} from '../enums/user-role.enum';
import {map, Observable, of, switchMap} from 'rxjs';
import {Router} from '@angular/router';
import { ImpersonateService } from '../impersonate/impersonate.service';
import { TermsService } from '../../terms/terms.service';

@Injectable({
  providedIn: 'root'
})
export class RegularUserGuard  {
  constructor(
    private authService: AuthService,
    private termsService: TermsService,
    private impersonationService: ImpersonateService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user: UserInterface = this.authService.getUser();

    return of(user).pipe(
      switchMap((user) =>
        user && !this.impersonationService.impersonated()
        ? of(user).pipe(this.termsService.getAgreement())
        : of(true)
      ),
      map((canContinue) => {
        if(!canContinue) {
          this.authService.logout();
          return false;
        }

        if (
          user
          && user.role?.name !== UserRole.ROLE_ADMIN
          && !user.isProfileComplete
          && state.url !== '/user/profile'
        ) {
          this.router.navigateByUrl('/user/profile');
          return false;
        }

        return true;
      })
    );
  }
}
