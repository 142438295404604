import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { TooltipPositionValues } from './model';
import {TooltipOpenerService} from './tooltip-opener.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '../../core/constants/breakpoints.constants';
import { isMobile } from '../../core/util/feature-detection.helper';

@Directive({
    exportAs: 'tooltipOpener',
    selector: 'tooltipOpener, [tooltipOpener]'
})
export class TooltipGlobalOpenerDirective implements OnInit, OnDestroy {
  @Input() tooltipContent: string | TemplateRef<any>;
  @Input() tooltipPosition: TooltipPositionValues = 'top';
  @Input() tooltipArrowPosition: TooltipPositionValues = 'bottom-left';
  @Input() additionalClasses: string;
  @Input() contentClasses: string;
  @Input() arrowClasses: string;
  @Input() tooltipHoverableContent: boolean;
  @Input() tooltipDisabled?: boolean;
  @Input() tooltipBounds?: HTMLElement;

  isMobile = false;

  constructor(
    private element: ElementRef,
    private tooltipOpener: TooltipOpenerService,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    this.isMobile = isMobile();

    this.breakpointObserver.observe(`(max-width: ${BREAKPOINTS.SM})`).subscribe(result => {
      this.close();
    });
  }

  @HostListener('pointerenter') onMouseEnter() {
    this.tooltipOpener.clearTimer();
    if (!this.tooltipDisabled) {
      this.tooltipOpener.changed({
        open: true,
        content: this.tooltipContent,
        position: this.tooltipPosition,
        arrowPosition: this.tooltipArrowPosition,
        openerElement: this.element,
        additionalClasses: this.additionalClasses,
        contentClasses: this.contentClasses,
        arrowClasses: this.arrowClasses,
        hoverableContent: this.tooltipHoverableContent,
        tooltipBounds: this.tooltipBounds,
      });
    }
  }

  @HostListener('pointerleave') onMouseLeave() {
    if (this.isMobile) {
      return;
    }

    if (this.tooltipHoverableContent) {
      this.tooltipOpener.setTimer(setTimeout(() => {
        this.close();
      }, 100));
    } else {
      this.close();
    }
  }

  @HostListener('click')
  onMouseClick() {
    if (this.isMobile) {
      this.open();
    } else {
      this.close();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public open() {
    this.tooltipOpener.changed({
      open: true,
      content: this.tooltipContent,
      position: this.tooltipPosition,
      arrowPosition: this.tooltipArrowPosition,
      openerElement: this.element,
      additionalClasses: this.additionalClasses,
      contentClasses: this.contentClasses,
      arrowClasses: this.arrowClasses,
      hoverableContent: this.tooltipHoverableContent,
      tooltipBounds: this.tooltipBounds,
    });
  }

  public close() {
    this.tooltipOpener.changed({
      open: false
    });
  }

  ngOnDestroy() {
    this.onMouseLeave();
  }
}
