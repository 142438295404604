import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarElementInterface, NavbarElements } from '../navbar-elements';
import { GeneralRoutePaths, RouteTypeEnums } from '../../enums/route-types.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownInterface } from '../../../shared/components/drop-down/drop-down.component';
import { PriceListStoreHandlerService } from '../../services/price-list/price-list-store-handler.service';
import { UserService } from '../../services/user/user.service';
import { UserInterface } from '../../models/user.model';
import { MessagesModalComponent } from '../../../messages-modal/messages-modal/messages-modal.component';
import { NotificationsModalComponent } from '../../../notifications-modal/notifications-modal/notifications-modal.component';
import { PermissionActions } from '../../../permissions.config';
import { Subscription } from 'rxjs';
import { TreeService } from '../tree.service';
import { SaleMode } from '../../../shared/components/list-mode-switch/sale-mode.types';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public user: UserInterface;

  elements: NavbarElementInterface[] = NavbarElements;
  selectedElement: NavbarElementInterface = null;
  permissionActions = PermissionActions;
  saleMode: SaleMode;
  generalRoutePaths = GeneralRoutePaths;

  priceList: DropDownInterface = {
    selected: null,
    values: [],
    onSelect: value => {
      this.priceListService.switch(value);
    },
  };

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private priceListService: PriceListStoreHandlerService,
    private treeService: TreeService,
    private routerService: Router,
    private listModeSwitchService: ListModeSwitchService,
    private router: Router
  ) {}

  ngOnInit() {
    // Update user information on navbar init for notifications
    this.userService.fromStorage().subscribe(user => {
      this.user = user;
      // Init services when app fully loads
      setTimeout(() => {
        this.priceListService.init();
      });
      this.elements = NavbarElements.filter(element => {
        if (element.id === RouteTypeEnums.NEWS || element.hideFromMenu) {
          return false;
        }
        return !element.allowedRoles || (element.allowedRoles && element.allowedRoles.indexOf(user.role.name) > -1);
      });
    });

    // Subscribe for user data changes
    this.subscription.add(this.userService.userObservable().subscribe(updatedUser => (this.user = updatedUser)));
    this.subscription.add(
      this.userService.poll().subscribe(
        updatedUser => (this.user = updatedUser),
        error => {
          this.subscription.unsubscribe();
        }
      )
    );
    this.subscription.add(
      this.treeService.getState().subscribe(({ first }) => {
        this.selectedElement = first;
      })
    );

    this.subscription.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.saleMode = +saleMode;
      })
    );

    this.priceListService.getCurrentAsObservable().subscribe(priceList => {
      this.priceList.selected = priceList;
    });

    this.priceListService.getAll().subscribe(priceLists => {
      this.priceList.values = priceLists;
    });
  }

  getRouterLinkPath(navElement: NavbarElementInterface): string {
     if (!navElement.children?.length || navElement.path === GeneralRoutePaths.INFO) {
      return navElement.path;
    }

    // Find first applicable sub-nav element for current user
    const [secondaryNavElement] = navElement.children
      .filter(({ allowedRoles }) => !allowedRoles || allowedRoles.indexOf(this.user?.role?.name) > -1)
      .sort((a, b) => {
        if (!(a.positions && b.positions)) {
          return 0;
        }
        return (
          a.positions.find(({ role }) => role === this.user?.role?.name).position -
          b.positions.find(({ role }) => role === this.user?.role?.name).position
        );
      });

    if (!secondaryNavElement) {
      return navElement.path;
    }

    return `${navElement.path}/${secondaryNavElement.path}`;
  }

  onClick(navElement: NavbarElementInterface) {
    if ([RouteTypeEnums.CATALOGUE, RouteTypeEnums.ORDERS, RouteTypeEnums.INQUIRIES].indexOf(navElement.id) > -1) {
      this.treeService.open(navElement, null, true);
      return;
    }
    this.treeService.modifyState({first: navElement, second: null, third: null});
  }

  getNewsItem() {
    const news = NavbarElements.find(item => item.id === RouteTypeEnums.NEWS);

    if (news === undefined) {
      return NavbarElements[0];
    }

    return news;
  }

  onMessagesModalOpen() {
    this.modalService.open(MessagesModalComponent, {
      windowClass: 'messages-modal',
    });
  }

  onNotificationsModalOpen() {
    this.modalService.open(NotificationsModalComponent, {
      windowClass: 'messages-modal',
      scrollable: true,
    });
  }

  /**
   * Clears all the routing data set by user
   */
  ngOnDestroy() {
    this.user = null;
    this.subscription.unsubscribe();
    this.treeService.closeAll();
  }

  onLogout() {
    // preserve picked language and drop everything else
    const userLanguage = sessionStorage.getItem('language');
    sessionStorage.clear();
    sessionStorage.setItem('language', userLanguage);

    this.routerService.navigate(['/logout']);
  }
}
