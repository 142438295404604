import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MaintenancePlanInterface, MaintenanceService } from '../../../maintenance/maintenance.service';
import * as moment from 'moment';
import { AlertComponent } from '../alert/alert.component';
import { TranslatedDatePipe } from '../../pipes/translated-date/translated-date.pipe';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule, AlertComponent, TranslatedDatePipe]
})
export class MaintenanceBannerComponent implements OnInit {
  @Input() belowFooter = false;

  plan: MaintenancePlanInterface;
  lessThanTwoHoursLeft = false;
  dismissed = false;
  
  constructor(public maintenanceService: MaintenanceService) { }

  ngOnInit(): void {
    this.maintenanceService.maintenancePlan$.subscribe((plan) => {
      this.plan = plan;
      
      if(!plan?.dateFrom || !plan?.dateTo) {
        return;
      }
      
      if(moment(plan?.dateFrom).diff(moment(), 'hours') < 2) {
        this.lessThanTwoHoursLeft = true;
      }
    });

    this.maintenanceService.maintenanceBannerDismissed$.subscribe((dismissed) => {
      this.dismissed = dismissed;
    });
  }

  dismiss(): void {
    this.maintenanceService.dismissMaintenanceBanner();
  }
}
