import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-copy-to-clipboard',
  imports: [NgbTooltip, TranslatePipe, CommonModule],
  templateUrl: './copy-to-clipboard.component.html',
})
export class CopyToClipboardComponent {

  @Input() content: string;
  @Input() buttonClass = 'deep-hover d-none';

  copied = false;
  tooltipText = 'ACTIONS.COPY_TO_CLIPBOARD';

  constructor(
    public translate: TranslateService
  ) { }

  onCopyToClipboard(e: Event, content: string): void {
    e.stopPropagation();

    navigator.clipboard.writeText(content).then(() => {
      this.copied = true;

      this.tooltipText = 'ACTIONS.COPIED_TO_CLIPBOARD';

      setTimeout(() => {
        this.copied = false;
        this.tooltipText = 'ACTIONS.COPY_TO_CLIPBOARD';
      }, 1000);
    });
  }
}
