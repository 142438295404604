import { Component, Input } from '@angular/core';
import { OrderState } from '../../../core/enums/order.state.enum';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-price-request-item-product-code-icon',
    templateUrl: './price-request-item-product-code-icon.component.html',
    imports: [SharedModule]
})
export class PriceRequestItemProductCodeIconComponent {
  @Input() code: string | null;
  @Input() isDealer: boolean;
  @Input() orderState: OrderState;

  OrderStates = OrderState;
}
