// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  api: 'https://admin.d365.nspace.narbutas.com/api/',
  sentryDSN: 'https://ce7900286d063b9ba95ac207c2f8ab75@o485579.ingest.us.sentry.io/4508850745573376',
  sentryTracesSampleRate: 0,
  release: '9.12.0',
  hidePrices: false,
  gofigure: '//gofigure.d365.nspace.narbutas.com',
  xdebugKey: 'VSCODE',
  gitHash: '885b60d754ff1d9e1c145a2344a22110add9cfe0',
  marketingMaterialsURL: '/catalogue/category/marketing-materials/swatch-cards',
  serviceDeskURL: 'https://narbutas.atlassian.net/servicedesk/customer/portal/42/',
  imageResizeURL: 'https://imageresize.d365.nspace.narbutas.com/_/',
  googleTagManagerId: 'GTM-T3CBVJB',
  googleTagManagerAuth: 'yBKoAakPKmEdb_4--24bTw',
  googleTagManagerPreview: 'env-264',
};
