import { Component, Input } from '@angular/core';
import { FileData } from '../../../core/models/file-data.model';
import { GalleryComponent } from '../../../shared/components/gallery/gallery.component';
import { ClickStopPropagationDirective } from '../../../shared/directives/click-stop-propagation/click-stop-propagation.directive';
import { EncodeUriPipe } from '../../../shared/pipes/encode-uri.pipe';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-preview-image-modal',
    templateUrl: './preview-image-modal.component.html',
    styleUrls: ['./preview-image-modal.component.scss'],
    imports: [SharedModule, ClickStopPropagationDirective, GalleryComponent, EncodeUriPipe]
})
export class PreviewImageModalComponent {
  @Input() image: FileData;
  
  constructor() { }
}
