import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { SearchService } from './search.service';

@Component({
    selector: 'app-navigation-search-input',
    templateUrl: './navigation-search-input.component.html',
    styleUrls: ['./navigation-search-input.component.scss'],
    imports: [SharedModule]
})
export class NavigationSearchInputComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    query: new FormControl<string>('')
  });

  private subscriptions = new Subscription();

  constructor(
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.form.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(formValue => {
        this.searchService.changeTerm(formValue.query);
      })
    );

    this.subscriptions.add(
      this.searchService.getTermObservable().subscribe(query => {
        this.form.patchValue({ query }, { emitEvent: false });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPlaceholder() {
    return this.searchService.getInputPlaceHolder();
  }

  clear() {
    this.form.reset();
  }
}
