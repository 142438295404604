import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import {CacheableObservable} from '../../../cacheable-observable/cacheable-observable.model';
import {CountryInterface} from '../../models/country.model';
import {AddressInterface} from '../../models/address.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private apiService: ApiService) {
  }

  all(): CacheableObservable<CountryInterface[]> {
    return this.apiService.get(`country/`).pipe(map(({data}) => data)) as CacheableObservable<AddressInterface[]>;
  }

  search(term: string): CacheableObservable<CountryInterface[]> {
    return this.apiService.get(`country/search/${term}`).pipe(map(({data}) => data)) as CacheableObservable<CountryInterface[]>;
  }
}
