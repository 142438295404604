import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../api.service';
import { saveAs as exportedSaveAs } from 'file-saver';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-pricelist-download',
    templateUrl: './pricelist-download.component.html',
    styleUrls: ['./pricelist-download.component.scss']
})
export class PricelistDownloadComponent implements OnInit {
  [x: string]: any;

  constructor(private api: ApiService, private routerService: Router, private http: HttpClient) {}

  ngOnInit() {
    this.http
      .get(`${environment.api}users/pricelist`, {
        responseType: 'blob',
      })
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: data.type });
        exportedSaveAs(blob, 'pricelist');
        this.routerService.navigate(['/']);
      });
  }
}
