
import { Component, Input } from '@angular/core';
import { FileData } from '../../../core/models/file-data.model';
import { ClickStopPropagationDirective } from '../../directives/click-stop-propagation/click-stop-propagation.directive';
import { ImageSizeEnum } from '../../pipes/resize-image/resize-image.pipe';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-row-image',
    templateUrl: './row-image.component.html',
    styleUrls: ['./row-image.component.scss'],
    imports: [SharedModule, ClickStopPropagationDirective,]
})
export class RowImageComponent {
  @Input() imageFile: FileData = null;
  @Input() defaultIcon: string = '';
  @Input() iconColorClass: string = 'gray-500';
  @Input() backgroundColorClass: string = 'bg-gray-300';
  @Input() downloadable: boolean = false;

  imageSizes = ImageSizeEnum;
}
