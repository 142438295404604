import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/services/user/user.service';
import { SharedModule } from '../../shared/shared.module';

export enum NewItemType {
  ORDER,
  GROUP,
  SUBGROUP,
  PRICE_REQUEST
}

export interface CreateNewItemEventInterface {
  title: string;
}

@Component({
    selector: 'app-selection-menu-new-item',
    templateUrl: './new-item.component.html',
    imports: [SharedModule]
})
export class NewItemComponent implements OnInit {
  @Input() type: NewItemType = NewItemType.ORDER;
  @Output() create: EventEmitter<CreateNewItemEventInterface> = new EventEmitter();

  @ViewChild('addNewItem') set addNewItem(item: ElementRef<HTMLInputElement> | undefined) {
    if (item) {
      item.nativeElement.focus();
    }
  };

  isFormActive: boolean;
  form: ReturnType<typeof this.constructForm>;

  newItemType = NewItemType;

  constructor(private fb: FormBuilder,
    private translator: TranslateService,
    public userService: UserService) {
  }

  ngOnInit() {
    this.form = this.constructForm();
  }

  constructForm() {
    return this.fb.group({
      title: new FormControl(null, [Validators.maxLength(50)]),
    });
  }

  submitForm() {
    if (this.form.valid) {
      const title = this.form.controls.title.value;
      if (!title) {
        this.translator.get(this.getStringByType().placeholder).subscribe(translation => {
          this.emitEvent(translation);
        });
        return;
      }
      this.emitEvent(title);

      return;
    }

    this.form.controls.title.markAsDirty({ onlySelf: true });
  }

  private emitEvent(title: string) {
    this.create.emit({ title });
    this.onFormToggle();
  }

  onFormToggle() {
    this.isFormActive = !this.isFormActive;
    if (!this.isFormActive) {
      this.form.reset();
    }
  }

  getStringByType(): { action?: string, placeholder: string } {
    switch(this.type) {
      case NewItemType.ORDER:
        if (this.userService.isPmNarbutas()) {
          return { action: 'ORDER_SELECT.ORDERS.NEW_ORDER', placeholder: 'ORDER_SELECT.ORDERS.PLACEHOLDER' };
        }
        if (this.userService.isDealer()) {
          return { action: 'ORDER_SELECT.OFFERS.NEW_OFFER', placeholder: 'ORDER_SELECT.OFFERS.PLACEHOLDER' };
        }
      case NewItemType.GROUP:
        return { action: 'ORDER_SELECT.GROUPS.NEW_GROUP', placeholder: 'ORDER_SELECT.GROUPS.PLACEHOLDER' };
      case NewItemType.SUBGROUP:
        return { action: 'ORDER_SELECT.GROUPS.NEW_SUBGROUP', placeholder: 'ORDER_SELECT.GROUPS.PLACEHOLDER_SUBGROUP' };
      case NewItemType.PRICE_REQUEST:
        return { placeholder: 'SELECTION_MENU_SIDEBAR.NEW_PRICE_REQUEST_PLACEHOLDER'}
    }
  }
}
