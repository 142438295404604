import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { exhaustMap, map } from 'rxjs/operators';
import { timer } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {
  public static ENDPOINT = {
    KEEP_ALIVE: environment.gofigure + '/keep-alive',
  };

  constructor(private apiService: ApiService) {}

  // @TODO: configurator is optional, right now BE requires it to be sent, but later must be removed/implemented
  keepAlive(session: string, configurator: string = '', options: { delay?: number; interval?: number } = { delay: 3000, interval: 0 }) {
    return timer(options.delay, options.interval).pipe(
      exhaustMap(() => {
        return this.apiService
          .post(ConfiguratorService.ENDPOINT.KEEP_ALIVE, { sessionId: session, configuratorId: configurator })
          .pipe(map(res => res));
      })
    );
  }
}
