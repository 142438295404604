import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConversationMessageType } from '../core/enums/messages-type.enum';
import { FileData } from '../core/models/file-data.model';
import { MessagesInterface } from '../core/models/messages.model';
import { UserInterface } from '../core/models/user.model';
import { UserService } from '../core/services/user/user.service';
import { LinkifyPipe } from '../shared/pipes/linkify/linkify.pipe';
import { SharedModule } from '../shared/shared.module';
import { FilesListComponent } from '../ui-elements/communication-tab/files-list/files-list.component';
import { LoaderComponent } from '../ui-elements/loader/loader.component';
import { MessageDetailsValueFormatPipe } from './message-details-value-format.pipe';

@Component({
    selector: 'app-messages-window',
    templateUrl: './messages-window.component.html',
    styleUrls: ['./messages-window.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, LoaderComponent, FilesListComponent, LinkifyPipe, MessageDetailsValueFormatPipe]
})
export class MessagesWindowComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('unreadMessages') unreadMessages: ElementRef;
  
  @Input() messages: MessagesInterface[] = [];
  @Input() loading: boolean;
  @Input() newMessagesCount: number = 0;
  @Output() markAsSeen: EventEmitter<any> = new EventEmitter<any>();

  conversationMessageType = ConversationMessageType;
  user: UserInterface;
  initLoad = true;
  initialNewMessagesCount: number;
  unreadMessagesIndex: number;

  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.newMessagesCount?.firstChange) {
      this.initialNewMessagesCount = changes.newMessagesCount.currentValue;
    }

    if (this.loading || !this.messages.length || !changes.messages) {
      return;
    }

    if (this.initLoad) {
      this.unreadMessagesIndex = this.initialNewMessagesCount ? changes.messages.currentValue.length - this.initialNewMessagesCount : null;
    }

    const hostEl = this.el.nativeElement;

    setTimeout(() => {
      const offset = 84;
      const scrollToPosition = this.unreadMessages && this.initLoad ? this.unreadMessages.nativeElement.offsetTop - offset : hostEl.scrollHeight;

      this.el.nativeElement.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });

      this.markAsSeen.emit();
      this.initLoad = false;
    }, 0);
  }

  ngOnInit() {
    this.userService.fromStorage().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  trackByMessageId(index, item: MessagesInterface) {
    return item.id;
  }

  getMessageFiles(files): FileData[] {
    return files.map(file => ({
      id: file.id,
      name: file.name,
      filename: file.filename,
      url: file.url,
      extension: file.extension,
    }));
  }
}
