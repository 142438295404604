<ng-container *ngIf="limitedView" [ngSwitch]="row.orderArticleType">
  <div *ngSwitchCase="types.PAGE_BREAK">
    <div class="small color-gray-600 d-flex align-items-center auto-grow py-3">
      <span class="ms-3 line"></span>
      <span class="text--italic flex-shrink-0 px-4">
        {{ 'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.PAGE_BREAK' | translate }}
      </span>
      <span class="line me-3"></span>
    </div>
  </div>
  <div *ngSwitchCase="types.TEXT">
    <p class="small color-gray-600 m-0 p-2 text-break--all">{{ row.title }}</p>
  </div>
</ng-container>

<ng-container *ngIf="!limitedView" [ngSwitch]="row.type">
  <div
    *ngSwitchCase="types.PAGE_BREAK"
    class="extra-row extra-row--page-break d-flex hover-bg-gray-200 deep-hover-color-gray-400 border-top border-bottom"
    [ngClass]="{ selected: selected }"
    (click)="onToggleSelect()"
  >
    <button class="btn btn-link d-flex color-gray-200 deep-hover drag-handle px-1 py-3" appDragHandle
      appClickStopPropagation [class.invisible]="isFilterEnabled">
      <i class="icon ni-reorder"></i>
    </button>

    <div class="d-flex align-items-center justify-content-center checkbox-column first-column">
      <app-check-mark
        [name]="row.id"
        (changed)="onSelect($event)"
        [value]="selected"
        appClickStopPropagation
        cssClasses="m-0"
        ></app-check-mark>
    </div>
    <div class="d-flex align-items-center auto-grow">
      <span class="ms-3 line"></span>
      <span class="flex-shrink-0 px-4 small color-gray-400">
        {{ 'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.PAGE_BREAK' | translate }}
      </span>
      <span class="line"></span>
    </div>
    <div
      class="px-3 settings-column d-flex justify-content-center align-items-center"
      [appAddClassesIfCustomDiscountApplied]="['custom-discount--applied']"
    >
      <button class="btn no-outline color-gray hover-color-black btn--action d-flex p-1" appClickStopPropagation (click)="onDelete()">
        <i class="icon ni-more-horiz"></i>
      </button>
    </div>
  </div>

  <div
    *ngSwitchCase="types.TEXT"
    class="extra-row extra-row--text-row hover-bg-white d-flex bg-gray-200 deep-hover-color-gray-400"
    [class.extra-row--text-row--active]="selected"
    (click)="onToggleSelect()"
  >
    <button class="btn btn-link d-flex color-gray-200 deep-hover drag-handle px-1 py-3" appDragHandle
      appClickStopPropagation [class.invisible]="isFilterEnabled">
      <i class="icon ni-reorder"></i>
    </button>

    <div class="d-flex pt-3 justify-content-center checkbox-column first-column">
      <app-check-mark
        [name]="row.id"
        (changed)="onSelect($event)"
        [value]="selected"
        appClickStopPropagation
        cssClasses="m-0"
        ></app-check-mark>
    </div>
    <form class="d-flex align-items-center auto-grow py-2" [formGroup]="form">
      <textarea
        type="text"
        class="title-edit ms-1 p-1 form-control no-resize overflow-hidden"
        formControlName="title"
        #input
        [placeholder]="'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.FORM.PLACEHOLDER' | translate"
        [title]="'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.FORM.PLACEHOLDER' | translate"
        (keydown.enter)="onEnterPress($event)"
        (keydown.esc)="onUndoChanges()"
        (blur)="onBlur($event)"
        rows="1"
        autosize
        [autosizeMinHeight]="32"
        maxlength="255"
        ></textarea>
    </form>
    <div
      class="px-3 settings-column pt-2 mt-1 justify-content-center"
      [appAddClassesIfCustomDiscountApplied]="['custom-discount--applied']"
    >
      <button
        class="btn btn-primary p-1 no-after no-outline btn--action d-flex"
        appClickStopPropagation
        *ngIf="form.controls['title'].enabled"
        (click)="onEnterPress($event)"
      >
        <i class="icon ni-check"></i>
      </button>

      <div *ngIf="form.controls['title'].disabled" class="action-dropdown" ngbDropdown #activeDropdown="ngbDropdown" placement="bottom-end">
        <button class="btn bg-gray-200 no-after no-outline color-gray hover-color-black btn--action d-flex p-1" ngbDropdownToggle appClickStopPropagation>
          <i class="icon ni-more-horiz shown-color-black"></i>
        </button>

        <div ngbDropdownMenu class="dropdown-menu shadow">
          <button class="dropdown-item btn btn-secondary no-outline d-flex"
                  (click)="enableEdit();">
            <i class="icon ni-edit-text me-3"></i>
            <span>
              {{ 'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.ACTIONS.EDIT' | translate }}
            </span>
          </button>
          <button class="dropdown-item btn btn-secondary no-outline d-flex"
                  (click)="onDelete();">
            <i class="icon ni-delete me-3"></i>
            <span>
              {{ 'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.ACTIONS.REMOVE' | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

