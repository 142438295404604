import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProjectReservationLostReasonsInterface } from '../../../../core/models/project-reservation.model';
import { SharedModule } from '../../../../shared/shared.module';

export const PROJECT_RESERVATION_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProjectReservationLostFeedbackComponent),
  multi: true,
};

@Component({
    selector: 'app-project-reservation-lost-feedback',
    templateUrl: './project-reservation-lost-feedback.component.html',
    providers: [
        PROJECT_RESERVATION_INPUT_ACCESSOR
    ],
    imports: [SharedModule]
})

export class ProjectReservationLostFeedbackComponent implements ControlValueAccessor, OnInit, OnDestroy {
  form: FormGroup;
  isDisabled = false;
  _value: ProjectReservationLostReasonsInterface;

  protected subscriptions: Subscription = new Subscription();

  @Input() set value(value: string | undefined) {};
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('notesTextarea') notesTextarea: ElementRef;

  constructor() {
    this.constructForm();
  }

  ngOnInit() {
    this.subscriptions.add(this.form.valueChanges.subscribe((data) => {
      if (!data) {
        return;
      }
      this.onChangedCallback(data);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  writeValue(value: ProjectReservationLostReasonsInterface): void {
    if (!value) {
      return;
    }

    this.form.patchValue({ ...value });
  }

  onChangedCallback = (value: ProjectReservationLostReasonsInterface) => { };
  registerOnChange(fn: (value: ProjectReservationLostReasonsInterface) => {}): void {
    this.onChangedCallback = fn;
  }

  onTouchCallback = () => { };
  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  constructForm(model?: ProjectReservationLostReasonsInterface) {
    this.form = new FormGroup({
      tooLongProductionLeadTime: new FormControl(),
      tooHighPrice: new FormControl(),
      tooLongResponseTime: new FormControl(),
      lackOfAdditionalSupport: new FormControl(),
      productOrCompanyRequirementsNotMet: new FormControl(),
      projectWasCanceled: new FormControl(),
      otherReasons: new FormControl(),
      otherLostReasons: new FormControl()
    });

  }

  onFocusNotes() {
    if (!!this.form.controls.otherReasons.value) {
      // control should be shown first and only then can be focuses
      setTimeout(() => {
        this.notesTextarea.nativeElement.focus();
      }, 0);
    }
  }
}
