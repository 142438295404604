<div
  class="bg-gray-200 group-row d-flex align-items-center"
  [ngClass]="{
    selected: selected,
    'group-row--open border-bottom': isOpen,
    'hover-bg-white deep-hover-color-gray-400': !limitedView
  }"
  (click)="!limitedView ? onToggleGroup() : null"
>
  <p *ngIf="limitedView; else nonLimitedView" class="m-0 p-2 color-gray-600 small">{{ row.title }}</p>
  <ng-template #nonLimitedView>
    <button class="btn btn-link d-flex color-gray-200 deep-hover drag-handle m-0 px-1 py-3" appDragHandle
      appClickStopPropagation [class.invisible]="isFilterEnabled">
      <i class="icon ni-reorder"></i>
    </button>

    <div class="py-3 d-flex first-column">
      <app-check-mark
        [name]="row.id"
        [value]="selected"
        (changed)="onGroupSelect($event)"
        appClickStopPropagation
        cssClasses="m-0"
      ></app-check-mark>
    </div>

    <i class="icon color-black" [ngClass]="{ 'ni-expand-less': isOpen, 'ni-expand-more': !isOpen }"></i>
    <i *ngIf="shouldDisplayWarningIcon()" class="icon ni-warning-circle color-danger ms-2" data-testid="warningIcon"></i>

    <div class="group-row__text auto-grow">
      <form
        [formGroup]="form"
        class="w-50 d-flex justify-content-start"
      >
        <input
          type="text"
          class="title-edit edit-inline small form-control w-auto py-0125r me-0 border-1 px-2 color-black fw-bold"
          formControlName="title"
          #input
          (keyup.enter)="onEnterPress($event)"
          (keydown.esc)="onUndoChanges()"
          (blur)="onBlur($event)"
          appClickStopPropagation
          [size]="inputSize"
          [title]="'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.GROUP' | translate"
        />
        <span
          *ngIf="isCanceledOrExpired"
          class="px-3 rounded-pill bg-gray-600 color-gray-100 small fw-bold text-nowrap d-flex align-items-center"
          [class.ms-2]="form.controls['title'].enabled"
          data-testid="statusBadge"
        >
          {{ statusTranslations[row.customMadePriceRequest.status] | translate }}
        </span>
      </form>
    </div>

    <div class="w-50 d-flex align-items-center justify-content-end">
      <span class="mx-auto text-nowrap color-gray-500 fst-italic fw-light small" [class.d-none]="isOpen">
        ({{ row.productCount | i18nPlural : singularPluralMap | translate : { count: row.productCount } }})
      </span>
      <div
        class="py-1 pe-3 d-flex align-items-center h-100 justify-content-end"
        [style.width.rem]="
          columns.actions.width +
          columns.price.width +
          columns.totalPrice.width -
          0.8125 +
          (isDiscountFieldVisible && columns.netPrice.visible ? columns.netPrice.width : 0)
        "
      >
        <span
          class="color-gray text-end pt-2 pe-3 pb-2 ps-0 fst-italic group-row__total-price"
          [class.d-none]="isOpen"
          [style.width.rem]="columns.totalPrice.width"
        >
          {{ row.childrenTotalPrice | priceFormat }}
        </span>
        <div class="d-flex justify-content-center ms-1">
          <button
            class="btn p-1 btn-primary btn--action d-flex"
            appClickStopPropagation
            *ngIf="form.controls['title'].enabled"
            (click)="onEnterPress($event)"
          >
            <i class="icon ni-check"></i>
          </button>

          <div
            *ngIf="form.controls['title'].disabled"
            class="action-dropdown h-100"
            ngbDropdown
            appClickStopPropagation
            [placement]="['bottom-end', 'top-end']"
          >
            <button
              class="bg-gray-300 hover-bg-gray-400 color-gray-700 btn d-flex dropdown-toggle no-after p-1 my-2"
              ngbDropdownToggle
            >
              <i class="icon ni-more-horiz"></i>
            </button>

            <div
              ngbDropdownMenu
              class="p-0 m-0 dropdown-menu shadow border-0"
              [style.max-width.px]="240"
            >
              <button
                *ngIf="!isPM"
                ngbDropdownItem
                class="d-flex align-items-center btn btn-secondary no-outline"
                (click)="onOpenPriceRequest()"
              >
                <i class="icon ni-sticky-note me-2"></i>
                <span class="flex-1 text-white-space-normal">{{ 'ORDER_PRODUCTS_LIST.ROW_AND_GROUP_ACTIONS.OPEN_PRICE_REQUEST' | translate }}</span>
              </button>
              <button
                *ngIf="isPM && row.customMadePriceRequest.jiraIssueKey"
                ngbDropdownItem
                class="d-flex align-items-center btn btn-secondary no-outline"
                (click)="onOpenPriceRequestInSD()"
              >
                <i class="icon ni-open-in-new me-2"></i>
                <span class="flex-1 text-white-space-normal">{{ 'ORDER_PRODUCTS_LIST.ROW_AND_GROUP_ACTIONS.OPEN_PRICE_REQUEST_IN_SD' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
