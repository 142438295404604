import { Component, Input, OnInit } from '@angular/core';
import { PropertyClassChangeEventEmitterComponent } from '../../../property-class-change.component';
import { PropertyTypes, PropertyValueSelectorThemes, PropertyValuesSelectorComponent } from '../../../property-values-selector/property-values-selector.component';
import { Theme } from '../../../../ui-elements/accordion-toggler/accordion-toggler-themes.enum';
import { ConfiguratorPropertyClassCombinationKeys } from '../../../configurator-property-classes-type.enum';
import { ArticlePropertyClassPropertyInterface, ArticlePropertyValueInterface } from '../../../../core/models/configurator.model';
import { SharedModule } from '../../../../shared/shared.module';

export interface ModifiedPropertyInterface extends ArticlePropertyClassPropertyInterface {
  selectedPropertyValue?: ArticlePropertyValueInterface;
}

@Component({
    selector: 'app-generic-property',
    templateUrl: './generic-property.component.html',
    imports: [SharedModule, PropertyValuesSelectorComponent]
})
export class GenericPropertyComponent extends PropertyClassChangeEventEmitterComponent implements OnInit {

  @Input() property!: ModifiedPropertyInterface;
  @Input() isRootControllingProperty = false;

  accordionThemes = Theme;
  propertyValueSelectorThemes = PropertyValueSelectorThemes;
  selectorTheme: PropertyValueSelectorThemes = PropertyValueSelectorThemes.LIST;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.property.added) {
      this.property = {
        ...this.property,
        selectedPropertyValue: this.property.articlePropertyValues.find(
          (propertyValue) => propertyValue.selected
        )
      };
    }

    this.selectorTheme = this.getSelectorTheme(this.property);
  }

  onRemove(event: MouseEvent) {
    event.stopPropagation();

    this.propertyClassChange.emit({
      configuratorId: this.property.configuratorId,
      propertyClass: this.property.propertyClass,
      propertyName: this.property.originalName,
      valueFrom: this.property.emptyValue
    });
  }

  getSelectorTheme(property: ModifiedPropertyInterface): PropertyValueSelectorThemes {
    property.shouldDisplayPropertyImages = property.articlePropertyValues.some(
      (propertyValue) => !!propertyValue.img
    );

    if (property.shouldDisplayPropertyImages) {
      return PropertyValueSelectorThemes.IMAGES_LARGE;
    }

    return PropertyValueSelectorThemes.LIST;
  }

  /**
   * mandatory = true, selected (by default) = true, emptyValue = null => arrow-down
   * mandatory = true, selected (by default) = true, emptyValue = true => not possible
   * mandatory = true, selected = false, emptyValue = null => not possible?
   * mandatory = true, selected = false, emptyValue = true => not possible?
   *
   * mandatory = false, selected = false, emptyValue = null => edge-case (not possible?)
   * mandatory = false, selected = true, emptyValue = null => edge-case (not possible?)
   * mandatory = false, selected = false, emptyValue = true => plus-sign
   * mandatory = false, selected = true, emptyValue = true => green title with X sign
   */
  accordionTheme(): Theme {
    if (this.property.mandatory) {
      return Theme.DEFAULT;
    }

    return Theme.PLUS;
  }

  propertyType(): PropertyTypes[] {
    switch (this.property.type) {
      case ConfiguratorPropertyClassCombinationKeys.LARGE_IMAGES:
        return [PropertyTypes.ADDITIONAL_PART];
      case ConfiguratorPropertyClassCombinationKeys.TEXT_ONLY:
      case ConfiguratorPropertyClassCombinationKeys.SMALL_IMAGES:
      default:
        return [PropertyTypes.SIMPLE];
    }
  }

  propertyCanBeeRemoved(property: ModifiedPropertyInterface): boolean {
    return property.editable && !property.mandatory && property.currentValue.valueFrom !== property.emptyValue;
  }
}
