import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OrderState } from "../../../core/enums/order.state.enum";
import { UserRole } from '../../../core/enums/user-role.enum';
import { OrderArticleFormItemType } from '../../../core/models/order-article.model';
import { OrderArticleService } from '../../../core/services/order-article/order-article.service';
import { UserService } from '../../../core/services/user/user.service';
import { SelectionMenuService } from '../../../selection-menu/selection-menu.service';
import { ListModeSwitchService } from "../../../shared/components/list-mode-switch/list-mode-switch.service";
import { SharedModule } from '../../../shared/shared.module';
import { FileUploadService } from '../../../ui-elements/file-upload/file-upload.service';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { LoaderService } from '../../../ui-elements/loader/loader.service';
import { QuantitySpinnerComponent } from '../../../ui-elements/quantity-spinner/quantity-spinner.component';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { CustomArticleModalModel } from '../custom-article-modal.model';
import { ImageControlComponent } from '../image-control/image-control.component';
import { FormComponent } from './form/form.component';

@Component({
    selector: 'app-other-supplier-item-modal',
    templateUrl: './other-supplier-item-modal.component.html',
    styleUrls: ['../custom-article-modal.component.scss'],
    // Needed as modal html element is rendered out of component scope (at the end of the <body>)
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule, LoaderComponent, ImageControlComponent, FormComponent, QuantitySpinnerComponent]
})
export class OtherSupplierItemModalComponent extends CustomArticleModalModel implements OnInit, OnDestroy {
  @ViewChild('content') content: TemplateRef<any>;

  protected itemType = OrderArticleFormItemType.OTHER_SUPPLIER_ITEM;

  constructor(
    fb: UntypedFormBuilder,
    selectionMenuService: SelectionMenuService,
    orderArticleService: OrderArticleService,
    loaderService: LoaderService,
    userService: UserService,
    translateService: TranslateService,
    fileUploadService: FileUploadService,
    toastService: ToastService,
    modalService: NgbModal,
    listModeSwitchService: ListModeSwitchService,
  ) {
    super(
      fb,
      selectionMenuService,
      orderArticleService,
      loaderService,
      userService,
      translateService,
      fileUploadService,
      toastService,
      modalService,
      listModeSwitchService
    );
  }

  getWarningMessage(): string {
    if (!this.selectedOrder) {
      return 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ERRORS.NO_ORDER_SELECTED';
    }
    return 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ERRORS.ADD_REQUIRED_STUFF';
  }

  getButtonMessage(): Observable<string> {
    return new Observable(observer => {
      this.userService.fromStorage().subscribe(user => {
        let translationKey = 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ADD_TO_OFFER';
        if (this.item && this.item.id) {
          translationKey = 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.SAVE';
        } else {
          if (
            (this.selectedOrder?.state === OrderState.WAITING) ||
            (user && user.role.name === UserRole.ROLE_PM_NARBUTAS)
          ) {
            translationKey = 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ADD_TO_ORDER';
          }
        }

        this.translateService.get(translationKey).subscribe(result => {
          observer.next(result);
          observer.complete();
        });
      });
    });
  }
}
