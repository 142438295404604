import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonInterface } from '../../../shared/interfaces/modal/button.model';
import { SharedModule } from '../../../shared/shared.module';
import { OkCancelModalComponent } from '../ok-cancel-modal/ok-cancel-modal.component';

@Component({
    selector: 'app-ok-cancel-optional-control-modal',
    templateUrl: './ok-cancel-optional-control-modal.component.html',
    styleUrls: ['./ok-cancel-optional-control-modal.component.scss'],
    imports: [SharedModule]
})
export class OkCancelOptionalControlModalComponent extends OkCancelModalComponent implements OnInit {
  @Input() optionalControl?: ButtonInterface;

  @Output() optionalControlClick: EventEmitter<null> = new EventEmitter<null>();

  buttons: ButtonInterface[];

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onOptionalControlClick() {
    this.activeModal.close();
    this.optionalControlClick.emit();
  }
}
