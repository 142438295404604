import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SharedModule } from '../../../shared/shared.module';
import { RouteTypeEnums } from '../../enums/route-types.enum';
import { UserService } from '../../services/user/user.service';
import { NavbarElementInterface, NavbarElements } from '../navbar-elements';
import { NavigationSearchInputComponent } from '../search/navigation-search-input.component';
import { SearchService } from "../search/search.service";
import { TreeService } from '../tree.service';

@Component({
    selector: 'app-sub-navbar',
    templateUrl: './sub-navbar.component.html',
    styleUrls: ['./sub-navbar.component.scss'],
    imports: [SharedModule, NavigationSearchInputComponent]
})
export class SubNavbarComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  parentElement: NavbarElementInterface;
  elements?: NavbarElementInterface[] = [];
  selectedElement: NavbarElementInterface = null;

  constructor(
    private userService: UserService,
    private treeService: TreeService,
    private searchService: SearchService
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.treeService
        .getState()
        .pipe(
          switchMap(({first, second}) => {
            this.parentElement = first;
            this.selectedElement = second;
            if (!first) {
              return of([]);
            }
            return this.sortNavbarItemsByRole(this.parentElement);
          })
        )
        .subscribe(elements => {
          this.elements = elements.filter(item => !item.hideFromMenu);
        })
    );
  }

  onClick(subNavbarElement: NavbarElementInterface, event: MouseEvent) {
    if (subNavbarElement.externalPath) {
      return;
    }
    event.preventDefault();

    const routeIsCatalogue = this.parentElement.id === RouteTypeEnums.CATALOGUE;
    const sameCatalogueCategoryPicked = this.selectedElement?.id === subNavbarElement.id && routeIsCatalogue;
    const differentCatalogueCategoryPicked = this.selectedElement?.id !== subNavbarElement.id && routeIsCatalogue;

    this.selectedElement = subNavbarElement;
    if (!routeIsCatalogue) {
      this.treeService.open(null, subNavbarElement, true);
    } else if (differentCatalogueCategoryPicked) {
      this.treeService.open(null, subNavbarElement, true);
      this.treeService.modifyState({
        third: true
      });
    } else if (sameCatalogueCategoryPicked) {
      this.treeService.modifyState({
        third: !this.treeService.getStateValue().third
      });
    }
  }

  onCloseThirdNavbar() {
    this.treeService.onSearch();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.treeService.closeNavbar('second');
  }

  private sortNavbarItemsByRole(parentElement: NavbarElementInterface): Observable<NavbarElementInterface[]> {
    let elements = [];
    return this
      .userService
      .fromStorage()
      .pipe(
        map(user => {
          const parent = NavbarElements.find((navbarElement: NavbarElementInterface) => {
            return navbarElement.id === parentElement.id;
          });

          const children = parent.children.filter((child: NavbarElementInterface) => {
            return (!child.allowedRoles) || (child.allowedRoles && child.allowedRoles.includes(user.role.name));
          });

          if (parent.sortable) {
            elements = children.sort((current: NavbarElementInterface, next: NavbarElementInterface) => {
              const currentPosition = current.positions.find(position => position.role === user.role.name);
              const nextPosition = next.positions.find(position => position.role === user.role.name);

              return currentPosition.position - nextPosition.position;
            });
          } else {
            elements = children;
          }

          return elements;
        })
      );
  }

  showSearch() {
    return this.searchService.hasSearchService();
  }
}
