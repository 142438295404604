import { NgFor } from '@angular/common';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Theme } from '../../../ui-elements/accordion-toggler/accordion-toggler-themes.enum';
import { PropertyClassChangeComponent } from '../../property-class-change.component';
import { PropertyValueSelectorThemes } from '../../property-values-selector/property-values-selector.component';
import { DefaultPropertiesComponent } from './default-properties/default-properties.component';

@Component({
    selector: 'app-default-property-classes',
    templateUrl: './default-property-classes.component.html',
    styleUrls: ['./default-property-classes.component.scss'],
    imports: [NgFor, DefaultPropertiesComponent]
})
export class DefaultPropertyClassesComponent extends PropertyClassChangeComponent implements OnInit, OnChanges {

  propertyValueSelectorThemes = PropertyValueSelectorThemes;
  accordionThemes = Theme;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
