import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsDecisionMakerServiceInterface} from './decision-maker.service';
import {PERMISSIONS_DECISION_MAKER_SERVICE} from './injection-tokens';

@Directive({ selector: '[permissionsElementVisibilityDecisionMaker]' })
export class ElementVisibilityDecisionMakerDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE) private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface
  ) {
  }

  @Input('permissionsElementVisibilityDecisionMaker') set actions(actions: string[]) {
    this.permissionsDecisionMakerService.shouldAllow(actions).subscribe((shouldAllow: boolean) => {
      if (shouldAllow) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
