import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FileDropInvalidReasonEnum } from '../../../api.service';

export interface DropzoneFileUploadErrorResponseInterface {
  errors: {
    children: {
      file: {
        errors: FileDropInvalidReasonEnum[];
      };
    };
  };
}

interface FileErrorInterface {
  errors?: string[];
}

export type FileValidationErrorsType = (string | null)[];

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public indexedFileErrorsFromResponse(givenResponse: HttpErrorResponse, fieldName = 'files'): FileValidationErrorsType {
    const errors = givenResponse.error.errors.children;
    const fieldErrors = errors[fieldName]?.children;

    if (!fieldErrors) {
      return null;
    }

    return fieldErrors.map((error) => this.extractFileError(error));
  }

  public extractFileError(error: FileErrorInterface): string | null {
    if (error.errors && error.errors[0]) {
      return error.errors[0];
    }

    return null;
  }

  public extractDropzoneFileErrors(response: DropzoneFileUploadErrorResponseInterface): FileDropInvalidReasonEnum[] {
    return response.errors.children.file.errors;
  }

  public includesMimeTypeError(array: FileDropInvalidReasonEnum[]): boolean {
    const mimeTypeErrors = [
      FileDropInvalidReasonEnum.GLOBAL_FILE_MIME_TYPE_ERROR,
      FileDropInvalidReasonEnum.PICTURE_MIME_TYPE_ERROR
    ];

    return array.some(reason => mimeTypeErrors.includes(reason));
  }

  public includesFileSizeError(array: FileDropInvalidReasonEnum[]): boolean {
    const fileSizeErrors = [
      FileDropInvalidReasonEnum.GLOBAL_FILE_MAX_SIZE_ERROR,
      FileDropInvalidReasonEnum.PICTURE_MAX_SIZE_ERROR
    ];

    return array.some(reason => fileSizeErrors.includes(reason));
  }

  public extractValidationErrors(givenResponse: HttpErrorResponse): string[] {
    const errorMessages: string[] = [];
  
    function extractErrors(obj: any) {
      if (Array.isArray(obj)) {
        return;
      }
  
      if (obj && obj.errors && Array.isArray(obj.errors)) {
        errorMessages.push(...obj.errors);
      } else if (typeof obj === 'object') {
        Object.values(obj).forEach(value => extractErrors(value));
      }
    }
  
    if (givenResponse?.error?.errors?.children) {
      extractErrors(givenResponse.error.errors.children);
    }
  
    return errorMessages;
  }
}
