import {Injectable} from '@angular/core';
import {ApiService} from '../../api.service';
import {CacheableObservable} from '../../cacheable-observable/cacheable-observable.model';
import {map} from 'rxjs/operators';
import { InfoBlock } from './block-page.interface';

@Injectable({
  providedIn: 'root'
})
export class InfoBlockService {

  constructor(private api: ApiService) {
  }

  getInformation(categoryType: number, path: string): CacheableObservable<InfoBlock> {
    return this.api.get(`info-block/by-category/${categoryType}/${path}`).pipe(map(({data}) => data)) as CacheableObservable<InfoBlock>;
  }
}
