import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService, FileSizeEnum} from '../api.service';
import {CacheableObservable} from '../cacheable-observable/cacheable-observable.model';
import {
  MarkAsSeenMessageInterface,
  MessagesCreateInterface,
  MessagesInterface,
  MessagesSummaryInterface
} from '../core/models/messages.model';
import {map} from 'rxjs/operators';
import {MessageStatus} from '../core/enums/message-status.enum';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private api: ApiService) {
  }

  getAllForUser(): CacheableObservable<MessagesSummaryInterface[]> {
    return this.api.get(`messages/`).pipe(map(({data}) => data)) as CacheableObservable<MessagesSummaryInterface[]>;
  }

  getOne(messageId: number): CacheableObservable<MessagesInterface> {
    return this.api.get(`messages/${messageId}`).pipe(map(({data}) => data)) as CacheableObservable<MessagesInterface>;
  }

  delete(messageId: number) {
    return this.api.delete(`messages/${messageId}`, null);
  }

  getInfoByOrder(orderId: number): CacheableObservable<MessagesSummaryInterface> {
    return this.api.get(`messages/order/${orderId}/info`).pipe(map(({data}) => data)) as CacheableObservable<MessagesSummaryInterface>;
  }

  getByOrder(orderId: number): CacheableObservable<MessagesInterface[]> {
    return this.api.get(`messages/order/${orderId}`).pipe(map(({data}) => data)) as CacheableObservable<MessagesInterface[]>;
  }

  createByOrder(orderId: number, message: MessagesCreateInterface, files?: File[]) {
    return this.api.upload(`messages/order/${orderId}`, message, files, FileSizeEnum.SIZE_1GB);
  }

  deleteByOrder(orderId: number) {
    return this.api.delete(`messages/order/${orderId}`, null);
  }

  markMessagesAsSeen(messages): Observable<MarkAsSeenMessageInterface[]> {
    return this.updateStatusMultiple(
      messages.map(message => {
        return {id: message.id, status: MessageStatus.SEEN};
      }));
  }

  updateStatusMultiple(messages: MarkAsSeenMessageInterface[]) {
    return this.api.patch(`messages/multi/`, messages);
  }

  markAllAsSeen() {
    return this.api.post(`messages/seen`, null);
  }
}
