import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { BREAKPOINTS } from '../../constants/breakpoints.constants';
import { InquiriesRoutePath } from '../../enums/route-types.enum';
import { TreeService } from '../tree.service';
import { LogoComponent } from '../logo/logo.component';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-mobile-navbar',
    templateUrl: './mobile-navbar.component.html',
    styleUrl: './mobile-navbar.component.scss',
    imports: [SharedModule, LogoComponent, RouterLink]
})
export class MobileNavbarComponent implements OnInit, OnDestroy {
  burgerMenuOpen = false;
  claimsRoutePath = `/${InquiriesRoutePath.ROOT}/${InquiriesRoutePath.CLAIMS}`;
  private subscriptions = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private treeService: TreeService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.treeService.getBurgerMenuOpenedAsObservable().subscribe((opened) => {
        this.burgerMenuOpen = opened;

        if (opened) {
          document.querySelector('body').classList.add('overflow-hidden');
        } else {
          document.querySelector('body').classList.remove('overflow-hidden');
        }
      })
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.closeBurgerMenu();
        }
      })
    );

    this.subscriptions.add(
      this.breakpointObserver.observe(`(max-width: ${BREAKPOINTS.SM})`).subscribe((result) => {
        if (!result.matches) {
          this.closeBurgerMenu();
        }
      })
    );
  }

  openBurgerMenu(): void {
    this.treeService.setBurgerMenuOpened(true);
  }

  closeBurgerMenu(): void {
    this.treeService.setBurgerMenuOpened(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
