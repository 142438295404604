import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-updated',
    templateUrl: './app-updated.component.html',
    imports: [SharedModule]
})
export class AppUpdatedComponent implements OnInit {
  constructor(private swUpdate: SwUpdate) {}

  ngOnInit() {}

  doFullReload() {
    this.swUpdate.activateUpdate().then(result => {
      window.location.reload();
    });
  }
}
