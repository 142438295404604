import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { email as emailValidator, extendedNumbers } from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { TextFieldComponent } from '../../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

@Component({
    selector: 'app-template-footer',
    templateUrl: './template-footer.component.html',
    styleUrls: ['./template-footer.component.scss'],
    imports: [SharedModule, TextFieldComponent]
})
export class TemplateFooterComponent implements OnInit, OnChanges {
  @Input() selectedTemplate: DocumentTemplate.Template;
  textFieldThemeTypes = TextFieldThemeTypes;
  form: ReturnType<typeof this.constructForm>;

  constructor(private documentTemplateService: DocumentTemplateService) {}

  ngOnInit() {
    this.form = this.constructForm(this.selectedTemplate.manager);

    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid),
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe(values => {
        this.documentTemplateService.updateSelectedTemplate({ manager: values as DocumentTemplate.Template['manager'] });
      });
  }

  constructForm({ fullName, email, phone }: DocumentTemplate.Manager) {
    return new FormGroup({
      fullName: new FormControl(fullName, Validators.maxLength(45)),
      email: new FormControl(email, [emailValidator, Validators.maxLength(45)]),
      phone: new FormControl(phone, [extendedNumbers, Validators.maxLength(20)]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTemplate &&
      changes.selectedTemplate.currentValue &&
      changes.selectedTemplate.previousValue &&
      changes.selectedTemplate.previousValue.type !== changes.selectedTemplate.currentValue.type) {
      const { fullName, email, phone } = this.selectedTemplate.manager;

      this.form.patchValue({
        fullName,
        email,
        phone,
      }, {emitEvent: false})
    }
  }
}
