import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
    name: 'translatedDate',
    pure: true
})
export class TranslatedDatePipe implements PipeTransform {

  constructor(public translate: TranslateService) { }

  transform(value: string, ...args: any): string {
    const currentLang = this.translate.currentLang;

    return moment(value).locale(currentLang).format(args[0]);
  }
}
