<div class="d-flex align-items-center mb-3 w-100">
  <label *ngIf="title.length" class="form-label w-50 text-end color-gray pe-3 mb-0" for="id">{{ title }}</label>
  <select
    class="form-control form-select"
    [class.w-50]="title?.length"
    [id]="id"
    [name]="id"
    [ngModel]="internalValue"
    (ngModelChange)="onSelect($event)"
    [disabled]="disabled"
    [class.is-invalid]="invalid"
    [title]="title"
    >
      <ng-container *ngIf="!groups.length; else groupTemplate">
        <option
          *ngFor="let value of values"
          [value]="value[valueField]"
          [selected]="internalValue == value[valueField]"
        >
          {{ translateLabels ? (value[labelField] | translate) : value[labelField] }}
        </option>
      </ng-container>

      <ng-template #groupTemplate>
        <ng-container *ngFor="let value of values">
          <optgroup
            [label]="getTranslatedValue(value)"
          >
            <option
              *ngFor="let groupName of groups"
              [value]="[value[valueField],groupName]"
              [selected]="internalValue == [value[valueField],groupName]"
            >
              {{ translateLabels ? (value[groupName] | translate) : value[groupName] }}
            </option>
          </optgroup>
        </ng-container>
      </ng-template>
  </select>
</div>
