import { Injectable } from '@angular/core';
import { SortInterface } from '../core/models/sort.model';
import { SortFactory } from '../core/factory/sort.factory';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';
import { ApiService, FileDeleteResponseInterface } from '../api.service';
import {
  ProjectReservationInterface,
  ProjectReservationOutcomeEnum,
  ProjectReservationStatusEnum,
  ProjectReservationFileWrapperInterface,
} from '../core/models/project-reservation.model';
import { CategoryPathInterface } from '../core/models/category-path.model';
import { Observable, of } from 'rxjs';
import { ListMetaInterface } from "../shared/interfaces/listMeta.interface";
import { environment } from '../../environments/environment';

export interface CategoryListResponseInterface {
  data: CategoryPathInterface[];
}

export interface ProjectReservationResponseInterface {
  additional: any[];
  data: ProjectReservationInterface
}

export interface ProjectReservationListResponseInterface {
  meta: ListMetaInterface,
  data: ProjectReservationInterface[],
}

export interface ProjectReservationCreateInterface {
  title: string;
  status: ProjectReservationStatusEnum;
  country: number;
  city: string;
  value: number;
  workplaceCount: number;
  productSystems: string[];
  description: string;
  estimatedWinDate: string;
  executionDate: string
  architect?: string;
  type?: string;
  dealerValue?: number;
  contactInformation?: string | null;
  projectReservationFiles?: ProjectReservationFileWrapperInterface[];
}

export interface ProjectReservationUpdateInterface extends ProjectReservationCreateInterface {
  id: number;
  outcome?: ProjectReservationOutcomeEnum;
  upcomingOrdersCount?: number;
}

export interface ProjectReservationWonUpdateInterface extends Pick<
  ProjectReservationInterface,
  'lostReasons' | 'otherLostReasons' | 'outcome' | 'upcomingOrdersCount' | 'value' |
  'productSystems' | 'description' | 'workplaceCount' | 'dealerValue' | 'contactInformation'
> {
  executionDate: string;
}

export interface ProjectReservationLostUpdateInterface extends Pick<ProjectReservationInterface, 'lostReasons' | 'otherLostReasons'> {
  outcome?: ProjectReservationOutcomeEnum;
}

export type ProjectReservationLostReasonsUpdateInterface = Pick<ProjectReservationInterface, 'otherLostReasons' | 'lostReasons'>

export enum ProjectReservationReasonsMap {
  tooLongProductionLeadTime = "too_long_production_lead_time",
  tooHighPrice = "too_high_price",
  tooLongResponseTime = "response_time_was_too_long",
  lackOfAdditionalSupport = "lack_of_additional_support",
  productOrCompanyRequirementsNotMet = "products_or_company_does_not_meet_all_requirements",
  projectWasCanceled = "project_was_canceled",
  otherReasons = "other",
  // reverse map
  too_long_production_lead_time = "tooLongProductionLeadTime",
  too_high_price = "tooHighPrice",
  response_time_was_too_long = "tooLongResponseTime",
  lack_of_additional_support = "lackOfAdditionalSupport",
  products_or_company_does_not_meet_all_requirements = "productOrCompanyRequirementsNotMet",
  project_was_canceled = "projectWasCanceled",
  other = "otherReasons"
}

export enum ProjectTypes {
  private = 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_TYPES.PRIVATE',
  public = 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_TYPES.PUBLIC'
};

export const projectTypeOptions = {
  values: [
    { value: 'private', name: ProjectTypes.private },
    { value: 'public', name: ProjectTypes.public }
  ],
};

@Injectable({
  providedIn: 'root'
})
export class ProjectReservationService {

  constructor(
    private api: ApiService
  ) { }

  filterAll(filter: any, sort?: SortInterface): CacheableObservable<ProjectReservationListResponseInterface> {
    let params = filter;
    if (sort) {
      params = { ...params, sort: SortFactory.getString(sort) };
    }

    return this.api.get(`project-reservations/`, params) as CacheableObservable<ProjectReservationListResponseInterface>;
  }

  create(data: ProjectReservationCreateInterface): CacheableObservable<ProjectReservationResponseInterface> {
    return this.api.post(`project-reservations/`, data) as CacheableObservable<ProjectReservationResponseInterface>;
  }

  getProductSystems() {
    return this.api.get('catalog/systems') as CacheableObservable<CategoryListResponseInterface>;
  }

  update(
    id: ProjectReservationInterface['id'],
    data: ProjectReservationWonUpdateInterface | ProjectReservationLostUpdateInterface | ProjectReservationUpdateInterface,
    validate?: boolean
  ): Observable<ProjectReservationResponseInterface> {
    return this.api.patch(`project-reservations/${id}`, data, {
      full: validate ? 1 : 0
    }) as Observable<ProjectReservationResponseInterface>;
  }

  getOne(id: ProjectReservationInterface['id']): Observable<ProjectReservationResponseInterface> {
    return this.api.get(`project-reservations/${id}`).pipe() as Observable<ProjectReservationResponseInterface>;
  }

  delete(id: ProjectReservationInterface['id']): Observable<ProjectReservationResponseInterface> {
    return this.api.delete(`project-reservations/${id}`, {}).pipe() as Observable<ProjectReservationResponseInterface>;
  }

  deleteFile(id: ProjectReservationInterface['id'], fileId: number): CacheableObservable<FileDeleteResponseInterface> {
    return this.api.delete(`project-reservations/${id}/files/${fileId}`, {}) as CacheableObservable<FileDeleteResponseInterface>;
  }

  getFileUploadEndpoint(projectReservationId: number): string {
    return `${environment.api}project-reservations/${projectReservationId}/files`;
  }

  getFileDeleteHandler(projectReservationId: number) {
    return (file: ProjectReservationFileWrapperInterface): Observable<any> => {
      return file.id ? this.deleteFile(projectReservationId, file.id) : of(true);
    }
  }
}
