import { Pipe, PipeTransform } from '@angular/core';
import { ChangedFieldName } from '../core/models/messages.model';
import { PriceListCurrencyPipe } from '../shared/pipes/price-list-currency/price-list-currency.pipe';

@Pipe({ name: 'messageDetailsValueFormat' })
export class MessageDetailsValueFormatPipe implements PipeTransform {
  constructor(private priceListCurrencyPipe: PriceListCurrencyPipe) {}

  transform(value: number | string, changedFieldName: ChangedFieldName | string): any {
    switch (changedFieldName) {
      case ChangedFieldName.PURCHASE_DISCOUNT:
        return `${value}%`;
      case ChangedFieldName.PURCHASE_PRICE:
        return this.priceListCurrencyPipe.transform(value);
      default:
        return value;
    }
  }
}
