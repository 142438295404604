import { Component, Input, OnChanges } from '@angular/core';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgFor } from '@angular/common';

export interface DropDownInterface {
  values: any[];
  selected: any;

  onSelect(value): void;
}

@Component({
    selector: 'app-drop-down',
    templateUrl: './drop-down.component.html',
    styleUrls: ['./drop-down.component.scss'],
    imports: [NgbDropdown, NgClass, NgbDropdownToggle, NgbDropdownMenu, NgFor]
})
export class DropDownComponent implements OnChanges {
  // @TODO: needs solution to make data keys same for all passed data here
  @Input() data: DropDownInterface;
  @Input() cssClasses?: string;
  @Input() listItemClasses?: string;
  @Input() linkCssClasses?: string;
  @Input() placement = 'bottom-start bottom-end top-start top-end';
  @Input() textField?: string;
  @Input() activatorTextField?: string;

  constructor() {}

  ngOnChanges() {}
}
