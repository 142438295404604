import { Inject, Injectable } from '@angular/core';
import { LocalStorageKeys, PERSIST_LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constants';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(PERSIST_LOCAL_STORAGE_KEYS) private keysToPersist: string[]) {}

  clearStorage(): void {
    Object.keys(localStorage).forEach((key) => {
      if (!this.keysToPersist.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }

  clearFilters() {
    Object.keys(LocalStorageKeys)
      .filter((key) => key.endsWith('_FILTERS'))
      .forEach((filterKey) => {
        localStorage.removeItem(LocalStorageKeys[filterKey]);
      });
  }
}
