import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, zip } from 'rxjs';
import { CountryInterface } from '../../../core/models/country.model';
import { CurrencyInterface } from '../../../core/models/currency.model';
import { OrderInterface } from '../../../core/models/order.model';
import { CurrencyService } from '../../../core/services/currency/currency.service';
import { LanguageInterface, LanguageStoreHandlerService } from '../../../core/services/language/language-store-handler.service';
import { decimalNumbers } from '../../../shared/class/custom-validators';
import { SharedModule } from '../../../shared/shared.module';
import { FormDropDownComponent } from '../../../ui-elements/form-drop-down/form-drop-down.component';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { TextFieldComponent } from '../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../services/document-template/document-template.service';
import { DocumentTemplate } from '../document-template.interface';
import { DocumentTemplateDetailsComponent } from './document-template-details/document-template-details.component';
import { DocumentTemplatePaymentConditionsComponent } from './document-template-payment-conditions/document-template-payment-conditions.component';
import { VatCalculationOption } from './option-value.model';
import { TemplateFooterComponent } from './template-footer/template-footer.component';
import { TemplateHeadlineComponent } from './template-headline/template-headline.component';
import { TemplateProductListComponent } from './template-product-list/template-product-list.component';

@Component({
    selector: 'app-create-document-document-template',
    templateUrl: './document-template.component.html',
    imports: [
      SharedModule,
      FormDropDownComponent,
      TextFieldComponent,
      TemplateHeadlineComponent,
      DocumentTemplateDetailsComponent,
      TemplateProductListComponent,
      DocumentTemplatePaymentConditionsComponent,
      TemplateFooterComponent,
      LoaderComponent,
    ],
})
export class DocumentTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('headerImage', { static: true }) headerImage;
  @ViewChild('footerImage', { static: true }) footerImage;
  @ViewChild('templateHeadline') templateHeadline: TemplateHeadlineComponent;
  @ViewChild('documentTemplateDetails') documentTemplateDetails: DocumentTemplateDetailsComponent;
  @ViewChild('documentTemplatePaymentConditions') documentTemplatePaymentConditions: DocumentTemplatePaymentConditionsComponent;

  @Input() data: DocumentTemplate.Data;
  @Input() selectedTemplate: DocumentTemplate.Template;
  @Input() order: DocumentTemplate.Order | OrderInterface;
  @Input() loading: boolean;
  @Input() showErrors?: boolean = false;
  @Output() templateChange: EventEmitter<DocumentTemplate.Template> = new EventEmitter<DocumentTemplate.Template>();
  @Output() templateUpdate: EventEmitter<DocumentTemplate.Update.TemplatePartial> =
    new EventEmitter<DocumentTemplate.Update.TemplatePartial>();

  @Output() templateFieldUpdate: EventEmitter<{ templateFieldProperty: string; status: boolean }> = new EventEmitter<{
    templateFieldProperty: string;
    status: boolean;
  }>();

  languages: LanguageInterface[];
  countries: CountryInterface[];
  textFieldThemeTypes = TextFieldThemeTypes;
  documentTemplateTypes = DocumentTemplate.Type;
  currencyDisplayTypes = DocumentTemplate.CurrencyDisplayType;
  backgroundType = [];
  customerDataTypes = [];
  currencies: CurrencyInterface[] = [];
  vatCalculationOptions: VatCalculationOption[] = [
    {
      value: true,
      title: 'CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.VAT_CALCULATION_OPTIONS.TRUE',
    },
    {
      value: false,
      title: 'CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.VAT_CALCULATION_OPTIONS.FALSE',
    }
  ];

  subscriptions = new Subscription();

  form = new FormGroup({
    exchangeRate: new FormControl<number>(undefined, [Validators.required, decimalNumbers, Validators.maxLength(50)]),
    isVat: new FormControl<boolean>(undefined, []),
  });

  constructor(
    private languageStoreHandlerService: LanguageStoreHandlerService,
    private translator: TranslateService,
    private documentTemplateService: DocumentTemplateService,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.currencyService.fetch().subscribe((data) => {
        this.currencies = data;
      })
    );

    zip(this.languageStoreHandlerService.getAll(), this.translator.get('LANGUAGES')).subscribe(([languages, translations]) => {
      this.languages = languages.map((language) => {
        const key = language.short.toUpperCase();
        const translation = translations[key];
        return {
          ...language,
          translated: translation,
        };
      });
    });

    zip(
      this.translator.get('CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.CLIENT_DATA.TYPES'),
      this.translator.get('CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.BACKGROUND.TYPES')
    ).subscribe(([clientDataTypes, backgroundTypes]) => {
      this.backgroundType = [
        { id: DocumentTemplate.HeadlineBackgroundType.LIGHT, title: backgroundTypes['LIGHT'] },
        { id: DocumentTemplate.HeadlineBackgroundType.DARK, title: backgroundTypes['DARK'] },
      ];

      const customerDataTypes = [{ id: DocumentTemplate.CustomerDataType.OPTIONAL, title: clientDataTypes['OPTIONAL'] }];

      if (this.data.order.client && this.order.client?.id) {
        customerDataTypes.push({ id: DocumentTemplate.CustomerDataType.FROM_CUSTOMER, title: clientDataTypes['FROM_CUSTOMER'] });
      } else {
        customerDataTypes.push({ id: DocumentTemplate.CustomerDataType.SELECT_CUSTOMER, title: clientDataTypes['SELECT_CUSTOMER'] });
      }
      this.customerDataTypes = customerDataTypes;
    });

    this.form.controls.exchangeRate.setValue(this.selectedTemplate.exchangeRate);
    this.form.controls.isVat.setValue(!!this.selectedTemplate.isVat);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onTemplateChange(template) {
    this.templateChange.emit(template);
  }

  onLanguageChange(language) {
    this.documentTemplateService.updateData({ language });
  }

  onCurrencyChange(currency: CurrencyInterface) {
    this.documentTemplateService.updateTemplateCurrency(currency, currency._selectedGroup);
  }

  onExchangeRateChange(focus: boolean) {
    if (!focus && this.form.controls.exchangeRate.valid) {
      this.documentTemplateService.updateTemplateExchangeRate(this.form.controls.exchangeRate.value);
    }
  }

  onVatCalculationChange(vatCalculation) {
    this.documentTemplateService.updateTemplateIsVat(vatCalculation.value);
  }

  validateSubForms() {
    if (this.selectedTemplate.type === DocumentTemplate.Type.PROPOSAL) {
      return this.form.valid && this.templateHeadline.validateSubForms();
    }

    if (this.selectedTemplate.type === DocumentTemplate.Type.INVOICE) {
      return (
        this.form.valid &&
        this.templateHeadline.validateSubForms() &&
        this.documentTemplateDetails.validateForm() &&
        this.documentTemplatePaymentConditions.validateForm()
      );
    }
  }
}
