<form [formGroup]="form">
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="tooLongProductionLeadTime"
      id="tooLongProductionLeadTime"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_LONG_PRODUCTION' | translate"
      name="tooLongProductionLeadTime">
    <label class="form-check-label cursor-pointer" for="tooLongProductionLeadTime">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_LONG_PRODUCTION" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="tooHighPrice"
      id="tooHighPrice"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_HIGH_PRICE' | translate"
      name="tooHighPrice">
    <label class="form-check-label cursor-pointer" for="tooHighPrice">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_HIGH_PRICE" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="tooLongResponseTime"
      id="tooLongResponseTime"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_LONG_RESPONSE_TIME' | translate"
      name="tooLongResponseTime">
    <label class="form-check-label cursor-pointer" for="tooLongResponseTime">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.TOO_LONG_RESPONSE_TIME" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="lackOfAdditionalSupport"
      id="lackOfAdditionalSupport"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.LACK_OF_ADDITIONAL_SUPPORT' | translate"
      name="lackOfAdditionalSupport">
    <label class="form-check-label cursor-pointer" for="lackOfAdditionalSupport">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.LACK_OF_ADDITIONAL_SUPPORT" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="productOrCompanyRequirementsNotMet"
      id="productOrCompanyRequirementsNotMet"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.UNMET_REQUIREMENTS' | translate"
      name="productOrCompanyRequirementsNotMet">
    <label class="form-check-label cursor-pointer" for="productOrCompanyRequirementsNotMet">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.UNMET_REQUIREMENTS" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="projectWasCanceled"
      id="projectWasCanceled"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.PROJECT_CANCELED' | translate"
      name="projectWasCanceled">
    <label class="form-check-label cursor-pointer" for="projectWasCanceled">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.PROJECT_CANCELED" | translate }}
    </label>
  </div>
  <div class="form-check pb-3">
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      formControlName="otherReasons"
      id="otherReasons"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.ENTER_FEEDBACK' | translate"
      name="otherReasons"
      (change)="onFocusNotes()">
    <label class="form-check-label cursor-pointer" for="otherReasons">
      {{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.ENTER_FEEDBACK" | translate }}
    </label>
  </div>
  <div *ngIf="form.controls.otherReasons.value">
    <textarea
      #notesTextarea
      class="form-control bg-white mb-3"
      id="otherLostReasons"
      [title]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.ENTER_FEEDBACK' | translate"
      rows="5"
      [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.FEEDBACK.ENTER_FEEDBACK' | translate"
      formControlName="otherLostReasons"></textarea>
  </div>
</form>
