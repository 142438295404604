<div class="modal-header py-3 align-items-start">
  <div>
    <h2 class="mb-0">{{ 'CONVERSATION_MODAL.TITLE' | translate }}</h2>
    <span class="color-gray-600">{{ 'CONVERSATION_MODAL.SECONDARY_TITLE' | translate }}: {{ object?.title }}</span>
  </div>
  <app-ui-elements-close-button [theme]="closeButtonTheme.MODAL" (click)="onClose()"></app-ui-elements-close-button>
</div>
<div class="conversation d-flex flex-column position-relative">
  <div class="modal-body p-0 d-flex flex-column overflow-hidden">
    <app-messages-window 
      class="overflow-auto"
      [messages]="messages"
      (markAsSeen)="onMarkMessagesAsSeen()"
      [loading]="loading"
      [newMessagesCount]="newMessageCount"
    ></app-messages-window>
  </div>
  <div class="modal-footer">
    <app-message-field class="w-100"
      (submit)="onMessageAdd($event)"
    ></app-message-field>
  </div>
</div>
