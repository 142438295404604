import { Component, Input } from '@angular/core';

import { TranslatePipe } from '@ngx-translate/core';
import { ClaimStatus, ClaimStatusToTranslationMap } from '../../../core/enums/claim-state.enum';
import { ClaimInterface } from '../../../core/models/claim.model';

@Component({
    selector: 'app-claim-status',
    templateUrl: './claim-status.component.html',
    imports: [TranslatePipe]
})
export class ClaimStatusComponent {
  @Input() status: ClaimInterface['status'];

  claimStatusTranslation = ClaimStatusToTranslationMap;
  claimStatusBackgroundColor = {
    [ClaimStatus.DRAFT]: 'gray-600',
    [ClaimStatus.WAITING]: 'warning-600',
    [ClaimStatus.IN_PROGRESS]: 'primary-600',
    [ClaimStatus.COMPLETED]: 'success-600',
  };
}
