import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

@Component({
    selector: 'app-abstract-notification',
    templateUrl: './abstract-notification.component.html',
    styleUrls: ['./abstract-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule]
})
export class AbstractNotificationComponent {
  @Input() showBadge = false;
}
