import { Injectable, Optional } from '@angular/core';
import { SESSION_CONSTANTS } from './session.constants';
import {UuidGenerator} from '../../core/util/uuid.generator';

export interface SessionInterface {
  id: number | string;
  session: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor() { }

  create(id: string | number): string {
    const session: string = UuidGenerator.uuid();

    this.save(id, session);

    return session;
  }

  fromStorageOrGenerated(id: string | number): string {
    return this.saveOrOverwrite(id);
  }

  saveOrOverwrite(id: string | number): string {
    const session: string = this.fromStorage(id) || UuidGenerator.uuid();

    this.save(id, session);

    return session;
  }

  fromStorage(id: string | number): string | void {
    if (sessionStorage.getItem(SESSION_CONSTANTS.SESSION_ID_STORAGE_KEY)) {
      const parsed: SessionInterface = this.latestFromStorage();
      return (parsed.id === id) ? parsed.session : null;
    }
  }

  latestFromStorage(): SessionInterface {
    const parsed: SessionInterface = JSON.parse(sessionStorage.getItem(SESSION_CONSTANTS.SESSION_ID_STORAGE_KEY));
    return parsed;
  }

  private save(id: string | number, session: string): void {
    sessionStorage.setItem(SESSION_CONSTANTS.SESSION_ID_STORAGE_KEY, JSON.stringify({ id, session }));
  }
}
