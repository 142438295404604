import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive to automatically resize a textarea to fit its content.
 */
@Directive({
    selector: 'textarea[textareaAutosize],' + 'textarea[autosize]',
    exportAs: 'TextareaAutosize'
})
export class TextareaAutosizeDirective implements AfterViewInit {
  /** Keep track of the previous textarea value to avoid resizing when the value hasn't changed. */
  private _previousValue: string;

  private _minRows: number;
  private _maxRows: number;
  private _focused: boolean;
  private defaultMinHeight = 32;

  /** Cached height of a textarea with a single row. */
  private _cachedLineHeight: number;
  private _formControl?: NgControl;

  // Textarea elements that have the directive applied should have a single row by default.
  // Browsers normally show two rows by default and therefore this limits the minRows binding.
  @HostBinding('attr.rows') '1';

  @HostListener('input') onTextareaInput() {
    this.resizeToFitContent();
  }

  @Input('autosizeMinHeight') minHeight?: number;

  @Input('autosizeMinRows')
  get minRows() {
    return this._minRows;
  }

  set minRows(value: number) {
    this._minRows = value;
    this._setMinHeight();
  }

  @Input('autosizeMaxRows')
  get maxRows() {
    return this._maxRows;
  }

  set maxRows(value: number) {
    this._maxRows = value;
    this._setMaxHeight();
  }

  @Input('autosizeCollapse') collapse: boolean;

  @Input('autosizeFocused')
  get focused() {
    return this._focused;
  }

  set focused(value: boolean) {
    this._focused = value;
    this.resizeToFitContent();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.resizeToFitContent();
  }

  constructor(private _elementRef: ElementRef, formControl?: NgControl) {
    this._formControl = formControl;
  }

  /** Sets the minimum height of the textarea as determined by minRows. */
  _setMinHeight(): void {
    const minHeight = this.minRows && this._cachedLineHeight ? `${this.minRows * this._cachedLineHeight}px` : null;

    if (minHeight) {
      this._setTextareaStyle('minHeight', minHeight);
    }
  }

  /** Sets the maximum height of the textarea as determined by maxRows. */
  _setMaxHeight(): void {
    const maxHeight = this.maxRows && this._cachedLineHeight ? `${this.maxRows * this._cachedLineHeight}px` : null;

    if (maxHeight) {
      this._setTextareaStyle('maxHeight', maxHeight);
    }
  }

  ngAfterViewInit() {
    if (this._formControl?.control?.valueChanges) {
      this._formControl.control.valueChanges.subscribe(() => this.resizeToFitContent());
    }

    this._cacheTextareaLineHeight();
    this.setMinHeight();
    this.resizeToFitContent();
  }

  private setMinHeight() {
    if (this.minHeight) {
      const textarea = this._elementRef.nativeElement as HTMLTextAreaElement;
      const minHeight = `${this.minHeight}px`;
      textarea.style.height = minHeight;
      textarea.style.minHeight = minHeight;
    }
  }

  /** Sets a style property on the textarea element. */
  private _setTextareaStyle(property: string, value: string): void {
    const textarea = this._elementRef.nativeElement as HTMLTextAreaElement;
    textarea.style[property] = value;
  }

  /**
   * Cache the height of a single-row textarea.
   *
   * We need to know how large a single "row" of a textarea is in order to apply minRows and
   * maxRows. For the initial version, we will assume that the height of a single line in the
   * textarea does not ever change.
   */
  private _cacheTextareaLineHeight(): void {
    const textarea = this._elementRef.nativeElement as HTMLTextAreaElement;

    // Use a clone element because we have to override some styles.
    const textareaClone = textarea.cloneNode(false) as HTMLTextAreaElement;
    textareaClone.rows = 1;

    // Use `position: absolute` so that this doesn't cause a browser layout and use
    // `visibility: hidden` so that nothing is rendered. Clear any other styles that
    // would affect the height.
    textareaClone.style.position = 'absolute';
    textareaClone.style.visibility = 'hidden';
    textareaClone.style.border = 'none';
    textareaClone.style.padding = '0';
    textareaClone.style.height = '';
    textareaClone.style.minHeight = '';
    textareaClone.style.maxHeight = '';
    textareaClone.className = '';

    textarea.parentNode.appendChild(textareaClone);
    this._cachedLineHeight = textareaClone.clientHeight;
    textarea.parentNode.removeChild(textareaClone);

    // Min and max heights have to be re-calculated if the cached line height changes
    this._setMinHeight();
    this._setMaxHeight();
  }

  /** Resize the textarea to fit its content. */
  resizeToFitContent() {
    const textarea = this._elementRef.nativeElement as HTMLTextAreaElement;

    if (!textarea.value || (this.collapse && !this.focused)) {
      textarea.style.height = `${this.minHeight ? this.minHeight : this.defaultMinHeight}px`;
      return;
    }

    // here we calculate minimal two rows height to decide if we need to make textarea higher
    // if scrollHeight is less then two rows - it is safe to set height to minimal
    // subtract 8 because of minHeight includes paddings
    // and on two row height textarea is still has only one padding top and one padding bottom
    const twoLineTreshHold = this._cachedLineHeight * 2 - 8;
    if (textarea.scrollHeight < twoLineTreshHold) {
      textarea.style.height = `${this.minHeight ? this.minHeight : this.defaultMinHeight}px`;
      return;
    }

    // Reset the textarea height to auto in order to shrink back to its default size.
    textarea.style.height = 'auto';

    // Use the scrollHeight to know how large the textarea *would* be if fit its entire value.
    textarea.style.height = `${textarea.scrollHeight}px`;

    this._previousValue = textarea.value;
  }
}
