<app-generic-modal
  [heading]="'PRICELIST_CHANGE_MODAL.TITLE' | translate"
  [mobileResponsiveFooter]="true"
  [closable]="false"
>
  <ng-template appModalBody>{{ 'PRICELIST_CHANGE_MODAL.DESCRIPTION' | translate: { pricelist: priceListName } }}</ng-template>
  <ng-template appModalFooter>
    <button 
      (click)="onUnderstoodClick()"
      class="btn btn-primary btn-lg"
      data-testid="understoodButton"
    >
      {{ 'ACTIONS.UNDERSTOOD' | translate }}
    </button>
  </ng-template>
</app-generic-modal>
