import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {PROPERTY_CLASS_TRANSLATION_PREFIX} from '../../configurator-modal.constants';

@Pipe({
    name: 'configuratorPropertyValueTranslate',
    pure: false
})
export class PropertyValueTranslatePipe extends TranslatePipe implements PipeTransform, OnDestroy {
  constructor(translate: TranslateService, _ref: ChangeDetectorRef) {
    super(translate, _ref);
  }

  updateValue(key: string, interpolateParams?: Object, translations?: any): void {
    const modifiedKey = `${PROPERTY_CLASS_TRANSLATION_PREFIX}${key}`;
    super.updateValue(modifiedKey, {...interpolateParams, fallback: null}, translations);
  }
}
