<table class="table table-articles shadow-sm mb-3">
    <thead>
      <tr>
        <th scope="col" class="col-image user-select-none"></th>
        <th scope="col" class="col-description text-start ps-2 user-select-none" *ngIf="columns[1].visible">{{ columns[1].title | translate }}</th>
        <th scope="col" class="col-numbers user-select-none">{{ columns[2].title | translate }}</th>
        <th scope="col" class="col-numbers user-select-none">{{ columns[3].title | translate }}</th>
        <th scope="col" class="col-numbers user-select-none">{{ columns[4].title | translate }}</th>
        <th scope="col" class="col-numbers user-select-none" *ngIf="columns[5].visible">{{ columns[5].title | translate }}</th>
        <th scope="col" class="col-article-variants user-select-none">
          {{ columns[6].title | translate }}
          <app-pricelist-currency></app-pricelist-currency>
          (
          <app-pricelist-title></app-pricelist-title>
          )
        </th>
      </tr>
    </thead>
    <tbody class="table-articles__properties">
      <tr>
        <td class="propclass-info border-bottom" [attr.colspan]="4 + (columns[1].visible ? 1 : 0) + (columns[5].visible ? 1 : 0)">
          <div *ngIf="articlePropertyClass.propclassInfo" [innerHTML]="articlePropertyClass.propclassInfo"></div>
        </td>
        <td class="p-0 table-articles__properties__properties-cell align-bottom border-bottom">
          <ul class="grid-options p-0" #propertyContainer>
            <ng-container *ngFor="let property of articlePropertyClass.properties; let last = last">
              <li *ngIf="property.articlePropertyValues.length > 0"
                class="list-group-item property user-select-none bg-transparent text-center pt-1 pb-2 me-0125r" [class.border-bottom-0]="last">
                <div class="property__name">{{ property.name }}:</div>
                <div class="d-flex w-100 material-family-groups-container">
                  <div class="d-flex flex-column" *ngFor="let valueGroup of property.articlePropertyValues"
                    [style.width]="valueGroup.width + '%'">
                    <div 
                      *ngFor="let materialFamilyGroup of valueGroup.valuesGroupedByMaterialFamily" 
                      class="mb-1"
                      [style.min-width.rem]="materialFamilyGroup.materialFamily ? ((property.articlePropertyValues.length * materialFamilyGroupBaseWidthRem) * (valueGroup.width / 100)) : null"
                    >
                      <app-property-values-group 
                        [group]="materialFamilyGroup"
                        [property]="property"
                        [singlePropertyValuesColumn]="propertyHasSingleValuesColumn(property)"
                        [propertyColumnContainsMultipleGroups]="propertyColumnContainsMultipleGroups(valueGroup)"
                        [propertyContainer]="propertyContainer"
                        [saleModeActive]="saleModeActive"
                      ></app-property-values-group>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </td>
      </tr>
    </tbody>
    <tbody class="table-articles__article-group min-h-100">
      <tr *ngFor="let articleGroup of articlePropertyClass.articles; let j = index, let last = last"
        [class.border-bottom]="!last"
      >
        <td class="p-0">
          <ng-template let-article #articlePhoto>
            <div
              class="table-articles__article-group__photo"
              [style.background-image]="'url(' + ((article.articlePhoto | resizeImage: imageSizes.SIZE_160x160 | uncache | async) ?? '') + ')'"
              (click)="openImagePreviewModal(imagePreviewModal, article)"
              *ngIf="article?.articlePhoto; else noImage"
            ></div>
            <ng-template #noImage>
              <div
                (click)="
                  !articleGroup[0].unavailable && openConfiguratorModal(system, articleGroup[0], articleGroup[0].articleVariants[0])
                "
                class="table-articles__article-group__photo table-articles__article-group__photo--no-image"
              ></div>
            </ng-template>
          </ng-template>

          <ng-container
            *ngTemplateOutlet="articlePhoto; context: { $implicit: firstAvailableArticleWithPhoto(articleGroup) }"
          ></ng-container>
        </td>
        <td
          *ngIf="columns[1].visible"
          class="table-custom-padding"
        >
          <span
            [innerHTML]="articleGroup[0]?.longText"
            (click)="openConfiguratorModal(system, articleGroup[0], articleGroup[0].articleVariants[0])"
            class="cursor-pointer"
          ></span>
        </td>
        <td class="table-custom-padding min-h-100 color-gray-600 py-2">
          <ul class="list-unstyled items-column">
            <li
              *ngFor="let article of articleGroup"
              [innerHTML]="(article.width || '') | safeHtml"
              (click)="openConfiguratorModal(system, article, article.articleVariants[0])"
              class="cursor-pointer hover-color-black"
            ></li>
          </ul>
        </td>
        <td class="table-custom-padding min-h-100 color-gray-600 py-2">
          <ul class="list-unstyled items-column">
            <li
              *ngFor="let article of articleGroup"
              [innerHTML]="(article.depth || '') | safeHtml"
              (click)="openConfiguratorModal(system, article, article.articleVariants[0])"
              class="cursor-pointer hover-color-black"
            ></li>
          </ul>
        </td>
        <td class="table-custom-padding min-h-100 color-gray-600 py-2">
          <ul class="list-unstyled items-column">
            <li
              *ngFor="let article of articleGroup"
              [innerHTML]="(article.height || '') | safeHtml"
              (click)="openConfiguratorModal(system, article, article.articleVariants[0])"
              class="cursor-pointer hover-color-black"
            ></li>
          </ul>
        </td>
        <td *ngIf="columns[5].visible" class="table-custom-padding min-h-100 color-gray-600 py-2">
          <ul class="table-articles__article-group__code list-unstyled items-column">
            <div *ngFor="let article of articleGroup">
              <ng-container *ngIf="(system.system + '_' + blockIndex + '_' + j + article.code) as identifier">
                <li
                  class="ps-0 pe-0  table-articles__article-group__code__drop-item text-decoration-none hover-color-black"
                  [id]="identifier"
                  (click)="openComponentsDropdown(identifier)"
                  [class.table-articles__article-group__code__drop-item--active]="
                    isComponentsDropdownActive(identifier)
                  "
                ><app-search-highlight [text]="article.code" [searchString]="searchQuery"></app-search-highlight></li>
                <div
                  class="dropdown-menu table-articles__article-group__code__dropdown"
                  (clickOutside)="activeComponentsDropdown = null"
                  [exclude]="'.table-articles__article-group__code__drop-item'"
                  *ngIf="isComponentsDropdownActive(identifier)"
                >
                  <div class="bg-gray-100 border rounded-1 shadow p-3">
                    <app-article-components
                      [code]="article.code"
                      [weight]="article.weight"
                      [volume]="article.volume"
                    ></app-article-components>
                  </div>
                </div>
              </ng-container>
            </div>
          </ul>
        </td>
        <td
          class="px-0 min-h-100 py-2"
        >
          <div class="d-flex flex-wrap h-100">
            <div *ngFor="let article of articleGroup; let last = last"
              class="table-articles__article-group__prices-list w-100 d-flex align-items-stretch"
            >
              <button
                *ngFor="let articleVariant of article.articleVariants"
                class="btn btn-default table-articles__article-group__prices-list__price-button border-0 hover-color-white p-0 me-0125r"
                [ngClass]="getPriceBackgroundColor(articleVariant.names)"
                [style.width.%]="100 / article.articleVariants.length"
                [class.table-articles__article-group__prices-list__price-button--disabled]="article.unavailable"
                [class.mb-0]="last"
                (click)="openConfiguratorModal(system, article, articleVariant)"
              >
                <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
                  {{ articleVariant.price | priceFormat: { zeroReplacement: '-' } }}
                </visibility-by-variables>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

<!-- image preview modal content -->
<ng-template #imagePreviewModal let-modal>
  <div class="modal-header justify-content-between ps-4 pe-3 py-3">
    <h3 class="mb-0 me-3"><span [innerHTML]="visibleArticle.longText"></span> {{ visibleArticle.code }}</h3>
    <app-ui-elements-close-button [theme]="closeButtonTheme.MODAL" (click)="modal.dismiss('Cross click')"></app-ui-elements-close-button>
  </div>
  <div class="modal-body text-center p-0">
    <app-gallery [images]="[galleryImage]" [noDownloadAll]="true"></app-gallery>
  </div>
</ng-template>