import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    imports: [SharedModule]
})
export class RadioButtonComponent implements OnInit {
  @Input() image: string;
  @Input() radioGroup: string;
  @Input() title: string;
  @Input() checked: boolean;
  @Input() value: string;
  @Input() cssClasses: string = null;
  @Input() displayImage = true;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  select(value: string) {
    this.onChange.emit(value);
  }

}
