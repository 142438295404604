import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CAN_ADD_AX_CODE, CAN_EDIT_AX_CODE } from '../../permissions.config';
import { Observable, Subscription } from 'rxjs';
import { PermissionsDecisionMakerServiceInterface } from '../../permissions/decision-maker.service';
import { email, extendedNumbers, noWhitespace, onlyText, textWithSeparators } from '../../shared/class/custom-validators';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../../permissions/injection-tokens';
import { UserRole } from '../../core/enums/user-role.enum';
import { UserService } from '../../core/services/user/user.service';
import { ValidatorService } from '../../shared/services/validator/validator.service';
import { switchMap } from 'rxjs/operators';
import { ClientInterface } from '../../core/models/client.model';

export interface ClientFormInterface {
  companyName: FormControl;
  companyCode: FormControl;
  vatCode: FormControl;
  country: FormControl;
  countryDisabled: FormControl;
  city: FormControl;
  postCode: FormControl;
  address: FormControl;
  email: FormControl;
  phone: FormControl;
  contactPerson: FormControl;
  axClientCode?: FormControl;
}

@Injectable({
  providedIn: 'root'
})
export class ClientFormService implements OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE)
    private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface,
    private userService: UserService,
    private validatorService: ValidatorService
  ) {}

  constructForm(fields: ClientInterface, disabled: boolean = false): Observable<FormGroup<ClientFormInterface>> {
    const form: FormGroup<ClientFormInterface> = new FormGroup({
      companyName: new FormControl({ value: fields.companyName, disabled: disabled }, []),
      companyCode: new FormControl({ value: fields.companyCode, disabled: disabled }, []),
      vatCode: new FormControl({ value: fields.vatCode, disabled: disabled }, []),
      country: new FormControl({ value: fields.country, disabled: disabled }, []),
      countryDisabled: new FormControl(
        { value: fields.country ? `${fields.country.title}, ${fields.country.code}` : '', disabled: disabled },
        []
      ),
      city: new FormControl({ value: fields.city, disabled: disabled }, [noWhitespace, textWithSeparators, Validators.maxLength(50)]),
      postCode: new FormControl({ value: fields.postCode, disabled: disabled }, [noWhitespace, Validators.maxLength(50)]),
      address: new FormControl({ value: fields.address, disabled: disabled }, [noWhitespace, Validators.maxLength(50)]),
      email: new FormControl({ value: fields.email, disabled: disabled }, [email]),
      phone: new FormControl({ value: fields.phone, disabled: disabled }, [noWhitespace, extendedNumbers, Validators.maxLength(50)]),
      contactPerson: new FormControl({ value: fields.contactPerson, disabled: disabled }, [
        noWhitespace,
        onlyText,
        Validators.maxLength(50),
      ]),
    });

    return new Observable((observer) => {
      this.userService
        .getUser()
        .pipe(
          switchMap(user => {
            if (!disabled) {
              if (user.role.name === UserRole.ROLE_PM_NARBUTAS) {
                this.validatorService.setValidators(
                  form,
                  [Validators.required],
                  ['companyName', 'companyCode', 'vatCode', 'country', 'address', 'city']
                );
              } else {
                this.validatorService.addValidators(form, [Validators.required]);
              }
            }

            return this.permissionsDecisionMakerService.shouldAllow([CAN_EDIT_AX_CODE, CAN_ADD_AX_CODE]);
          })
        )
        .subscribe((shouldAllow: boolean) => {
          if (shouldAllow) {
            form.addControl('axClientCode', new FormControl(null, [Validators.required]));
          }

          observer.next(form);
          observer.complete();
        })
        .unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
