import {Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ContainerType} from '../../../core/enums/container-type.enum';

@Directive({ selector: '[appContainer]' })
export class ContainerDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private elRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.subscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => {
        if (event['containerType'] === ContainerType.FULL_WIDTH) {
          this.renderer.removeClass(this.elRef.nativeElement, 'container-xxl');
        } else if (!this.elRef.nativeElement.classList.contains('container-xxl')) {
          this.renderer.addClass(this.elRef.nativeElement, 'container-xxl');
        }
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
