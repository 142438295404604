import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-no-results-found',
    templateUrl: './no-results-found.component.html',
    styleUrls: ['./no-results-found.component.scss'],
    imports: [SharedModule]
})
export class NoResultsFoundComponent implements OnInit {
  @Input() cssClasses: string;
  constructor() {
  }

  ngOnInit() {
  }
}
