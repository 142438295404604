import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormHelper } from '../../../../core/util/form-helper/form.helper';
import { validDateFormat } from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { DateInputComponent } from '../../../../ui-elements/date-input/date-input.component';
import { TextFieldComponent } from '../../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

const MAX_PERCENTAGE_VALUE = 100;

@Component({
    selector: 'app-document-template-payment-conditions',
    templateUrl: './document-template-payment-conditions.component.html',
    imports: [SharedModule, TextFieldComponent, DateInputComponent]
})
export class DocumentTemplatePaymentConditionsComponent implements OnInit, OnDestroy {
  @Input() currency: string;
  @Input() exchangeRate = 1;
  private subscriptions: Subscription = new Subscription();
  totalValues: { percent; price } = { percent: 0, price: 0 };
  orderPayments: DocumentTemplate.OrderPayment[] = [];
  order: DocumentTemplate.Order;
  initializationRequired = true;

  textFieldThemeTypes = TextFieldThemeTypes;
  form: UntypedFormGroup;
  maxPercentageValue = MAX_PERCENTAGE_VALUE;

  constructor(private documentTemplateService: DocumentTemplateService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.documentTemplateService.getBusyStateAsObservable().subscribe(isBusy => {
        const rowControls = (this.form?.get('payments') as UntypedFormArray)?.controls || [];
        rowControls.forEach(rowControl => {
          if (isBusy) {
            rowControl.disable({ onlySelf: true });
          } else {
            rowControl.enable({ onlySelf: true });
          }
        })
      })
    );

    this.subscriptions.add(
      this.documentTemplateService.getDataAsObservable().subscribe(({ order, orderPayments }) => {
        this.order = order;
        this.orderPayments = orderPayments;
        this.totalValues.percent = 0;
        this.totalValues.price = 0;

        // reset form because we will always get fresh data here
        this.form = new UntypedFormGroup({ payments: new UntypedFormArray([]) });
        this.form.get('payments')['controls'] = [];

        this.orderPayments.forEach(({ id, title, paymentDueDate, percentage, price }) => {
          this.totalValues.percent += +percentage;
          this.totalValues.price += +price;
          const rowControl = this.createRow();
          this.patchRow(rowControl, id, title, percentage, price, paymentDueDate);
          (this.form.get('payments') as UntypedFormArray).push(rowControl);
        });
      })
    );
  }

  patchRow(
    row: AbstractControl,
    id: number,
    title: string,
    percentage: number | string,
    price: number | string,
    paymentDueDate?: string | number
  ) {
    row.patchValue(
      {
        orderPaymentId: id,
        orderPaymentTitle: title,
        orderPaymentDueDate: paymentDueDate ? moment(paymentDueDate).format('YYYY-MM-DD') : null,
        orderPaymentPercentage: +percentage || null,
        orderPaymentPrice: price || '0.00',
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  onRemove(index: number) {
    const formArray = this.form.get('payments') as UntypedFormArray;
    const formGroup = formArray.at(index);
    formArray.removeAt(index);

    const paymentId = formGroup.get('orderPaymentId').value;
    const percentage = formGroup.get('orderPaymentPercentage').value;
    const price = formGroup.get('orderPaymentPrice').value;

    if (paymentId) {
      this.totalValues.percent -= +percentage;
      this.totalValues.price -= +price;
      this.orderPayments.splice(index, 1);
      this.documentTemplateService.setBusyState(true);
      this.documentTemplateService.updateData({ orderPayments: this.orderPayments });
    }
  }

  onAddRow(row: UntypedFormGroup = this.createRow()) {
    (this.form.get('payments') as UntypedFormArray).push(row);
    this.updateOrderPayments();
  }

  private createRow(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      orderPaymentId: new UntypedFormControl(),
      orderPaymentTitle: new UntypedFormControl('', [Validators.maxLength(45)]),
      orderPaymentDueDate: new UntypedFormControl('', validDateFormat),
      orderPaymentPercentage: new UntypedFormControl('', [Validators.min(0), Validators.max(100)]),
      orderPaymentPrice: new UntypedFormControl(''),
    });

    this.subscriptions.add(
      formGroup.valueChanges
        .pipe(
          filter(() => formGroup.valid),
          distinctUntilChanged(),
          debounceTime(500)
        )
        .subscribe(values => {
          this.updateOrderPayments(values);
        })
    );

    return formGroup;
  }

  private updateOrderPayments(values?) {
    if (!values) {
      let percentage = 0;
      if (this.orderPayments.length > 0 && this.totalValues.percent < MAX_PERCENTAGE_VALUE) {
        percentage = MAX_PERCENTAGE_VALUE - this.totalValues.percent;
      }
      this.documentTemplateService.setBusyState(true);
      this.documentTemplateService.updateData({ orderPayments: [...this.orderPayments, { percentage }] });

      return;
    }

    const { orderPaymentId, orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage, orderPaymentPrice } = values;

    if (orderPaymentId !== null && this.orderPayments.length) {
      const found = this.orderPayments.find(payment => payment.id === orderPaymentId);
      if (found) {
        this.appendChangedValues({ orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage }, found);
        this.documentTemplateService.setBusyState(true);
        this.documentTemplateService.updateData({ orderPayments: this.orderPayments });
      }
    }
  }

  private appendChangedValues(
    { orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage },
    orderPayment: DocumentTemplate.OrderPayment = {}
  ): DocumentTemplate.OrderPayment {
    const body: DocumentTemplate.OrderPayment = orderPayment;
    body.title = orderPaymentTitle;

    if (orderPaymentPercentage !== null) {
      body.percentage = orderPaymentPercentage;
    }

    if (orderPaymentDueDate) {
      body.paymentDueDate = moment(orderPaymentDueDate).format()
    }

    return body;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateForm() {
    FormHelper.markFormFieldsAsTouched(this.form);

    return this.form.valid;
  }
}
