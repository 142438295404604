<table class="w-100" [class.pdf-mode]="pdfMode">
    <thead *ngIf="!pdfMode; else pdfTableHead" class="border-0 border-bottom border-2">
      <tr class="row m-0 py-2 color-gray">
        <th class="col-6 ps-1 pe-0">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.STANDARD_ITEM_PARTS' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.CODE' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.FINISHES' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS' | translate | uppercase }}</th>
        <th class="w-12.5p pe-1 text-end">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY' | translate | uppercase }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="row m-0 color-black"
        [ngClass]="pdfMode ? 'pt-1' : 'py-1 border-bottom'"
        *ngFor="let article of articles"
      >
        <td class="col-6 p-0">{{ article.title }}</td>
        <td class="w-12.5p ps-0" [class.pe-0]="pdfMode">{{ article.code }}</td>
        <td class="w-12.5p ps-0" [class.pe-0]="pdfMode">{{ article.finishes.join(' ') }}</td>
        <td class="w-12.5p ps-0" [class.pe-0]="pdfMode">{{ formatArticleDimensions(article.dimensions) }}</td>
        <td class="w-12.5p pe-1 text-end">{{ article.quantity }}</td>
      </tr>
    </tbody>
  </table>

  <ng-template #pdfTableHead>
    <thead>
      <tr class="row m-0 color-gray-700">
        <th class="text-uppercase col-6 p-0">{{ 'INQUIRIES.PRICE_REQUESTS.VIEW.PDF.STANDARD_CONFIGURATION' | translate }}</th>
        <th class="text-uppercase w-12.5p ps-0">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.CODE' | translate }}</th>
        <th class="text-uppercase w-12.5p ps-0">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.FINISHES' | translate }}</th>
        <th class="text-uppercase w-12.5p ps-0">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS' | translate }}</th>
        <th class="text-uppercase w-12.5p pe-1 text-end">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY' | translate }}</th>
      </tr>
    </thead>
  </ng-template>
