import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TooltipStylesGeneratorService } from './tooltip-styles-generator.service';
import { TooltipArrowClassGuesserService } from './tooltip-arrow-class-guesser.service';
import { TooltipChangedEventInterface } from './model';
import { Subscription } from 'rxjs';
import { TooltipOpenerService } from './tooltip-opener.service';
import { SharedModule } from '../../shared/shared.module';

@Component({
    // tslint:disable-next-line
    selector: 'tooltip',
    template: `
    <div *ngIf="open" [ngClass]="config?.additionalClasses">
      <i #arrow class="tooltip__arrow" [ngClass]="config?.arrowClasses"></i>
      <div #content class="tooltip__content" [ngClass]="config?.contentClasses">
        <ng-container *ngIf="getContentType() === 'string'">{{ config?.content }}</ng-container>
        <ng-template *ngIf="getContentType() !== 'string'" [ngTemplateOutlet]="config?.content"></ng-template>
      </div>
    </div>
  `,
    styleUrls: ['./tooltip.component.scss'],
    imports: [SharedModule]
})
export class TooltipComponent implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  @ViewChild('arrow', { read: ElementRef }) arrow: ElementRef;

  open = false;
  config = null;
  private subscriptions = new Subscription();

  constructor(
    private stylesGenerator: TooltipStylesGeneratorService,
    private arrowClassGuesser: TooltipArrowClassGuesserService,
    private renderer: Renderer2,
    private tooltipOpener: TooltipOpenerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.tooltipOpener.changedAsObservable().subscribe((event: TooltipChangedEventInterface) => {
        this.handleEvent(event);
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.open && this.content && this.config.openerElement) {
      const generatedPosition = this.stylesGenerator.generate(
        this.config.position,
        this.content,
        this.config.openerElement,
        this.config.tooltipBounds
      );
      Object.keys(generatedPosition.styles).map(key => {
        const style = generatedPosition.styles[key];
        this.renderer.setStyle(this.content.nativeElement, key, style);
      });

      this.renderer.addClass(this.content.nativeElement, generatedPosition.className);

      const generatedArrowPosition = this.arrowClassGuesser.generate(this.config.arrowPosition, this.content, this.config.openerElement);
      Object.keys(generatedArrowPosition.styles).map(key => {
        const style = generatedArrowPosition.styles[key];
        this.renderer.setStyle(this.arrow.nativeElement, key, style);
      });
      this.renderer.addClass(this.arrow.nativeElement, generatedArrowPosition.className);
      if (this.config.hoverableContent) {
        this.renderer.listen(this.content.nativeElement, 'mouseenter', evt => {
          this.tooltipOpener.clearTimer();
        });
        this.renderer.listen(this.content.nativeElement, 'mouseleave', evt => {
          this.tooltipOpener.clearTimer();

          this.tooltipOpener.changed({
            open: false,
          });
        });
      }
    }
  }

  getContentType() {
    return typeof this.config.content;
  }

  private handleEvent(event: TooltipChangedEventInterface) {
    const { open, ...config } = event;
    this.open = open;
    this.config = config;
  }
}
