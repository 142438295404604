import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { LanguageSwitchComponent } from '../../../shared/components/language-switch/language-switch.component';
import { LogoComponent } from '../logo/logo.component';
import { NavbarWrapperComponent } from '../navbar-wrapper/navbar-wrapper.component';

@Component({
    selector: 'app-simple-navbar',
    templateUrl: './simple-navbar.component.html',
    styleUrls: ['./simple-navbar.component.scss'],
    imports: [NavbarWrapperComponent, RouterLink, LogoComponent, LanguageSwitchComponent, TranslatePipe]
})
export class SimpleNavbarComponent { }
