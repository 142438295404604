import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    imports: [NgClass]
})
export class LoaderComponent implements LoaderComponentInterface {
  @Input() cssClasses: NgClass['ngClass'] = '';
  @Input() spinnerClasses: NgClass['ngClass'] = '';
  @Input() loading = false;

  constructor() {}

  show() {
    this.loading = true;
  }

  hide() {
    this.loading = false;
  }

  visible(shouldShow: boolean) {
    if (shouldShow) {
      this.show();
    } else {
      this.hide();
    }
  }
}

export interface LoaderComponentInterface {
  show: () => void
  hide: () => void
  visible: (shouldShow: boolean) => void
  cssClasses: NgClass['ngClass']
  loading: boolean
}
