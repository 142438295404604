import { Injectable } from '@angular/core';
import { ApiService, FileSizeEnum } from '../../../api.service';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { CommunicationMessageInterface, MessagesInterface } from '../../../core/models/messages.model';
import { map } from 'rxjs';

export interface CommunicationMessagesListResponseInterface {
  data: CommunicationMessageInterface[];
  additional: {
    lastSeenMessageId: number;
  };
}

@Injectable()
export abstract class MessagesBaseService {
  protected abstract entitySet: string;

  constructor(protected api: ApiService) {}

  getMessages(id: number): CacheableObservable<CommunicationMessagesListResponseInterface> {
    return this.api.get(`${this.entitySet}/${id}/messages/`) as CacheableObservable<CommunicationMessagesListResponseInterface>;
  }

  sendMessage(id: number, { text, files }: Partial<MessagesInterface>): CacheableObservable<CommunicationMessageInterface> {
    return this.api
      .upload(`${this.entitySet}/${id}/messages/`, { text }, files, FileSizeEnum.SIZE_10MB)
      .pipe(map(({ data }) => data)) as CacheableObservable<CommunicationMessageInterface>;
  }
}
