import { Component, Input, OnInit } from '@angular/core';
import { GenericModalComponent } from '../../../ui-elements/generic-modal/generic-modal.component';
import { ModalHeaderDirective } from '../../../ui-elements/generic-modal/directives/modal-header/modal-header.directive';
import { ModalBodyDirective } from '../../../ui-elements/generic-modal/directives/modal-body/modal-body.directive';
import { ModalFooterDirective } from '../../../ui-elements/generic-modal/directives/modal-footer/modal-footer.directive';

@Component({
    selector: 'app-reload',
    templateUrl: './app-reload.component.html',
    imports: [GenericModalComponent, ModalHeaderDirective, ModalBodyDirective, ModalFooterDirective]
})
export class AppReloadComponent implements OnInit {
  @Input() textHeader: string;
  @Input() textInfo: string;
  @Input() textAction: string;

  constructor() {}

  ngOnInit() {}

  doReload() {
    window.location.reload();
  }
}
