import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormHelper } from '../../../../core/util/form-helper/form.helper';
import { decimalNumbers, noWhitespace } from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { FormDropDownComponent } from '../../../../ui-elements/form-drop-down/form-drop-down.component';
import { TextFieldComponent } from '../../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

@Component({
    selector: 'app-document-template-details',
    templateUrl: './document-template-details.component.html',
    styleUrls: ['./document-template-details.component.scss'],
    imports: [SharedModule, TextFieldComponent, FormDropDownComponent]
})
export class DocumentTemplateDetailsComponent implements OnInit, OnDestroy {
  @Input() selectedTemplate: DocumentTemplate.Template;
  @Input() data: DocumentTemplate.Data;
  @Input() customerDataTypes = [];

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  form: ReturnType<typeof this.constructForm>;
  textFieldThemeTypes = TextFieldThemeTypes;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private documentTemplateService: DocumentTemplateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    const { companyName, address, companyCode, vatCode, vatRate, iban, bank, bankCode, swift } = this.selectedTemplate.dealer;
    this.form = this.constructForm();
    this.form.patchValue(
      {
        provider: {
          companyName,
          address,
          companyCode,
          vatCode,
          vatRate,
          iban,
          bank,
          bankCode,
          swift,
        },
      },
      { emitEvent: false }
    );

    this.updateCustomerForm(this.data.clientDataType);

    this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(({ provider, customer }) => {
      const dealer: DocumentTemplate.Dealer = {
        ...this.selectedTemplate.dealer,
        address: provider.address,
        companyName: provider.companyName,
        companyCode: provider.companyCode,
        vatCode: provider.vatCode,
        vatRate: provider.vatRate,
        iban: provider.iban,
        bank: provider.bank,
        bankCode: provider.bankCode,
        swift: provider.swift,
      };

      let client: DocumentTemplate.Client = {
        ...this.data.client,
      };

      if (customer) {
        client = {
          ...client,
          clientCompanyName: customer.companyName,
          clientAddress: customer.address,
          clientCompanyCode: customer.companyCode,
          clientVatCode: customer.vatCode,
        }
      }

      this.documentTemplateService.updateData({ client, selectedTemplate: { ...this.selectedTemplate, dealer } });
    });
  }

  constructForm() {
    const vatRateValidators = [
      Validators.maxLength(50),
      decimalNumbers,
      Validators.min(0),
      Validators.max(100)
    ];

    return new FormGroup({
      provider: new FormGroup({
        companyName: new FormControl('', [Validators.maxLength(50), Validators.required]),
        address: new FormControl('', [noWhitespace, Validators.maxLength(50), Validators.required]),
        companyCode: new FormControl('', [Validators.maxLength(50), Validators.required]),
        vatCode: new FormControl('', [Validators.maxLength(50), Validators.required]),
        vatRate: new FormControl(null, vatRateValidators),
        iban: new FormControl('', [Validators.maxLength(50), Validators.required]),
        bank: new FormControl('', Validators.maxLength(50)),
        bankCode: new FormControl('', Validators.maxLength(50)),
        swift: new FormControl('', Validators.maxLength(50)),
      }),
      customer: new FormGroup({
        companyName: new FormControl('', [Validators.maxLength(50), Validators.required]),
        address: new FormControl('', [Validators.maxLength(50), Validators.required]),
        companyCode: new FormControl('', [Validators.maxLength(50), Validators.required]),
        vatCode: new FormControl('', [Validators.maxLength(50), Validators.required]),
      }),
    });
  }

  onCustomerDataTypeSelect(clientDataType: DocumentTemplate.CustomerDataType) {
    this.updateCustomerForm(clientDataType);
    if (clientDataType !== DocumentTemplate.CustomerDataType.SELECT_CUSTOMER) {
      this.documentTemplateService.updateData({ clientDataType });
    }
  }

  private updateCustomerForm(clientDataType: DocumentTemplate.CustomerDataType) {
    const customFormGroup = this.form.controls.customer;

    switch (clientDataType) {
      case DocumentTemplate.CustomerDataType.SELECT_CUSTOMER:
        this.activeModal.close();
        this.documentTemplateService.openSelectClientModal();
        break;
      case DocumentTemplate.CustomerDataType.FROM_CUSTOMER:
        if (!this.data.order.client) {
          this.documentTemplateService.updateData({ clientDataType: DocumentTemplate.CustomerDataType.OPTIONAL });
          return;
        }
        const { companyName, address, companyCode, vatCode } = this.data.order.client;
        customFormGroup.patchValue(
          {
            companyName,
            address,
            companyCode,
            vatCode,
          },
          { emitEvent: false }
        );
        customFormGroup.disable({ onlySelf: true, emitEvent: false });
        break;
      case DocumentTemplate.CustomerDataType.OPTIONAL:
        const { clientCompanyName, clientAddress, clientCompanyCode, clientVatCode } = this.data.client;
        customFormGroup.patchValue(
          {
            companyName: clientCompanyName,
            address: clientAddress,
            companyCode: clientCompanyCode,
            vatCode: clientVatCode,
          },
          { emitEvent: false }
        );
        customFormGroup.enable({ onlySelf: true, emitEvent: false });
        break;
    }
  }

  get vatRateControl(): FormControl {
    return this.form.get('provider.vatRate') as FormControl;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateForm() {
    FormHelper.markFormFieldsAsTouched(this.form);
    this.form.updateValueAndValidity();

    return this.form.valid;
  }
}
