import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommunicationMessageInterface, MessagesInterface } from '../../core/models/messages.model';
import { UserInterface } from '../../core/models/user.model';
import { UserService } from '../../core/services/user/user.service';
import { LinkifyPipe } from '../../shared/pipes/linkify/linkify.pipe';
import { SharedModule } from '../../shared/shared.module';
import { LoaderComponent } from '../loader/loader.component';
import { FilesListComponent } from './files-list/files-list.component';
import { DEFAULT_CHAT_ACCEPTED_FILE_TYPES, MessageFieldComponent, MessageFieldThemeEnum } from './message-field/message-field.component';

@Component({
    selector: 'app-communication-tab',
    templateUrl: './communication-tab.component.html',
    styleUrls: ['./communication-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule, LoaderComponent, FilesListComponent, MessageFieldComponent, LinkifyPipe]
})
export class CommunicationTabComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('unreadMessages') unreadMessages: ElementRef;

  @Input() messages: CommunicationMessageInterface[] = [];
  @Input() isLoading: boolean;
  @Input() acceptedFileTypes = DEFAULT_CHAT_ACCEPTED_FILE_TYPES;
  @Input() multiple = true;
  @Input() showMessageField = true;
  @Input() messageFieldTheme = MessageFieldThemeEnum.COMMUNICATION_TAB;
  @Input() lastSeenMessageId: number;
  @Output() send = new EventEmitter<Partial<MessagesInterface>>();
  @ViewChild('messagesContainer') messagesContainer: ElementRef;

  user: UserInterface;
  shouldScrollToBottom = false;
  
  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user) => {
      this.user = user;
    });
  }

  ngOnChanges(): void {
    this.shouldScrollToBottom = true;
  }

  ngAfterViewChecked(): void {
    const { scrollHeight, clientHeight } = this.messagesContainer.nativeElement;

    if (this.shouldScrollToBottom && this.messages.length && scrollHeight >= clientHeight) {
      this.scrollToBottom();
    }
  }

  onSent(message: Partial<MessagesInterface>) {
    this.send.emit(message);
  }

  scrollToBottom() {
    const messagesContainerEl = this.messagesContainer.nativeElement;
    const { scrollHeight } = messagesContainerEl;

    setTimeout(() => {
      const offset = 84;
      const scrollToPosition = this.unreadMessages ? this.unreadMessages.nativeElement.offsetTop - offset : scrollHeight;

      messagesContainerEl.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });

      this.shouldScrollToBottom = false;
    }, 0);
  }
  
  trackByFn(index: number, item: CommunicationMessageInterface) {
    return item.id;
  }
}
