import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientInterface } from '../../core/models/client.model';
import { OrderInterface } from '../../core/models/order.model';
import { SharedModule } from '../../shared/shared.module';
import { CustomerMode } from './client-modal.model';
import { CustomersListComponent } from './customers-list/customers-list.component';

@Component({
    selector: 'app-select-client-modal',
    templateUrl: './select-client-modal.component.html',
    imports: [SharedModule, CustomersListComponent]
})
export class SelectClientModalComponent implements OnInit {
  @Input() order: OrderInterface;

  @Output() clientSelect: EventEmitter<ClientInterface> = new EventEmitter();

  selectedClient: ClientInterface;
  customerMode = CustomerMode;
  selectedMode: CustomerMode = CustomerMode.LIST;

  constructor() {}

  ngOnInit() {
    this.selectedClient = this.order && this.order.client ? this.order.client : null;
  }

  onSelectMode(mode: CustomerMode) {
    this.selectedMode = mode;
  }

  onClientSelect(client) {
    this.selectedMode = CustomerMode.LIST;
    this.selectedClient = client;
    this.clientSelect.emit(this.selectedClient);
  }
}
