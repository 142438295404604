import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

function transformToSaleCoefficientFormat(value: string | number, decimalPipe: DecimalPipe, digitsInfo: string): string {
  const transformed = decimalPipe.transform(Math.abs(Number(value || 0)), digitsInfo);

  if (!transformed) {
    return null;
  }

  return transformed.toString().replace(',', '');
}

@Pipe({ name: 'saleCoefficientFormat' })
export class SaleCoefficientFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, args?: Partial<{ min: number; max: number; digitsInfo: string }>): any {
    let { min, max, digitsInfo } = { min: 0, max: Infinity, digitsInfo: '1.2-2' };
    if (args) {
      min = args.min;
      max = args.max;
      if (args.digitsInfo) {
        digitsInfo = args.digitsInfo;
      }
    }
    if (value > max) {
      value = max;
    }
    return transformToSaleCoefficientFormat(value, this.decimalPipe, digitsInfo);
  }
}
