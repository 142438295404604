import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription, combineLatest, map, of, shareReplay, switchMap } from 'rxjs';

import { ContentTreeModel } from '../../content/content-tree.model';
import { ContentModel } from '../../content/content.model';
import { ContentService } from '../../content/content.service';
import { storeSwitchMap } from '../../core/operators/storeSwitchMap.operator';
import { SharedModule } from '../../shared/shared.module';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { TermsUrlService } from '../terms-url.service';

@Component({
    selector: 'app-terms-modal',
    host: {
        class: 'd-flex flex-column vh-100 overflow-hidden'
    },
    templateUrl: './terms-modal.component.html',
    providers: [TermsUrlService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, TermsAndConditionsComponent, LoaderComponent]
})
export class TermsModalComponent implements OnInit {
  @Input() showCloseButton: boolean = true;
  @Input() showAgreementButtons: boolean = false;
  @Input() replaceURL = true;

  @Output() agreed = new EventEmitter<boolean>();

  legal$: Observable<ContentModel.Data> = this.contentService.getContentItem('legal').pipe(
    map(item => item.data),
    shareReplay(1)
  );

  tabs$: Observable<ContentTreeModel.Data[]> = this.contentService.getContentTree('legal').pipe(
    map(res => res.data),
    shareReplay(1)
  );

  private selectedUri$ = new BehaviorSubject<string | null>(null);
  selectedItem$ = this.tabs$.pipe(
    switchMap(tabs => combineLatest([ of(tabs), this.selectedUri$ ])),
    storeSwitchMap(
      ([tabs, uri]) => uri ?? tabs[0].uri,
      (data, key) => this.contentService.getContentItem(key)
    ),
    map(res => res.data)
  );

  private initialURL!: UrlTree;
  private subscription = new Subscription();

  constructor(
    private contentService: ContentService,
    private termsUrlService: TermsUrlService,
    private modal: NgbActiveModal,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initialURL = this.router.parseUrl(this.router.url);

    this.subscription.add(
      this.selectedUri$.subscribe(uri => {
        this.replaceUrlState(
          this.termsUrlService.createTermsUrl(uri)
        );
      })
    );
  }

  ngOnDestroy() {
    this.replaceUrlState(this.initialURL);
    this.subscription.unsubscribe();
  }

  onClose() {
    this.modal.dismiss();
  }

  respond(answer: boolean) {
    this.agreed.emit(answer);
  }

  changeSelectedItem(tab: ContentTreeModel.Data['uri']) {
    this.selectedUri$.next(tab);
  }

  private replaceUrlState(url: UrlTree) {
    if (this.replaceURL) {
      this.location.replaceState(url.toString());
    }
  }
}
