import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProjectReservationLostModalFormInterface } from '../../../core/models/project-reservation.model';
import { SharedModule } from '../../../shared/shared.module';
import { ProjectReservationLostReasonsUpdateInterface } from "../../project-reservation.service";
import { ProjectReservationLostFeedbackComponent } from '../project-reservation-preview/project-reservation-lost-feedback/project-reservation-lost-feedback.component';

export interface LostModalFormInterface {
  feedback: ProjectReservationLostModalFormInterface;
}

@Component({
    selector: 'app-project-reservation-modal-lost',
    templateUrl: './project-reservation-modal-lost.component.html',
    imports: [SharedModule, ProjectReservationLostFeedbackComponent]
})
export class ProjectReservationModalLostComponent implements OnInit {
  form: FormGroup;
  isFeedbackProvided = false;

  protected subscriptions: Subscription = new Subscription();

  @Output() saved = new EventEmitter<ProjectReservationLostReasonsUpdateInterface>();

  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  constructor(protected activeModal: NgbActiveModal){
    this.constructForm();
  }

  ngOnInit() {
    const formValue = this.form.getRawValue() as LostModalFormInterface;
    if (formValue) {
      this.checkIfFeedbackProvided(formValue);
    }
  }

  constructForm(fields?: ProjectReservationLostModalFormInterface, disabled: boolean = false) {
    this.form = new FormGroup({
      feedback: new FormControl({ value: fields, disabled: disabled }, []),
    });

    this.subscriptions.add(this.form.valueChanges.subscribe((formData: LostModalFormInterface) => {
      this.checkIfFeedbackProvided(formData);
    }));
  }

  private checkIfFeedbackProvided(formData: LostModalFormInterface) {
    this.isFeedbackProvided = false;
    Object.keys(formData.feedback).forEach(key => {
      if (!['otherLostReasons', 'otherReasons'].includes(key) && formData.feedback[key]) {
        this.isFeedbackProvided = true;
      }
    });
    if (formData.feedback.otherReasons) {
      this.isFeedbackProvided = !!formData.feedback.otherLostReasons?.length;
    }
  }

  onDiscard() {
    this.activeModal.close();
  }

  onFeedbackSubmit() {
    this.saved.emit(this.form.controls.feedback.value);
    this.activeModal.close();
  }
}
