import { FormControl, FormGroup } from '@angular/forms';

export class FormHelper {
  static markFormFieldsAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.markFormFieldsAsTouched(control);
      }
    });
  }

  static formHasAtLeastOneControlWithValue(formGroup: FormGroup): boolean {
    if (!formGroup) {
      return false;
    }

    return Object.keys(formGroup.controls).some((key) => {
      const controlValue = formGroup.get(key)?.value;

      return (
        controlValue !== null &&
        controlValue !== undefined &&
        controlValue !== '' &&
        !(Array.isArray(controlValue) && controlValue.length === 0)
      );
    });
  }
}
