import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ProductsService } from '../../catalogue/products/products.service';
import { UserRole } from '../../core/enums/user-role.enum';
import { OrderArticleInterface } from '../../core/models/order-article.model';
import { OrderInterface } from '../../core/models/order.model';
import { UserInterface } from '../../core/models/user.model';
import { UserService } from '../../core/services/user/user.service';
import { ListModeSwitchService } from '../../shared/components/list-mode-switch/list-mode-switch.service';
import { SaleMode } from '../../shared/components/list-mode-switch/sale-mode.types';
import { SharedModule } from '../../shared/shared.module';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { LoaderService } from '../../ui-elements/loader/loader.service';
import { VisibilityByVariablesPipe } from '../../visibility-by-variables/pipe/visibility-by-variables.pipe';
import { VisibilityByVariablesComponent } from '../../visibility-by-variables/visibility-by-variables.component';
import { PriceDetailsInterface } from '../footer/price-details/price-details.component';

@Component({
    selector: 'app-price-details-modal',
    templateUrl: './price-details-modal.component.html',
    styleUrls: ['./price-details-modal.component.scss'],
    imports: [SharedModule, LoaderComponent, VisibilityByVariablesComponent, VisibilityByVariablesPipe]
})
export class PriceDetailsModalComponent implements OnInit, OnDestroy {
  @Input() quantity: number;
  @Input() item: string;
  @Input() selectedOrder?: OrderInterface;
  @Input() orderArticle?: OrderArticleInterface;

  loading = false;
  details: PriceDetailsInterface;
  items: OrderArticleInterface[];
  saleMode: SaleMode;
  user: UserInterface;

  saleModes = SaleMode;

  subscriptions = new Subscription();

  constructor(
    private saleModeService: ListModeSwitchService,
    private productsService: ProductsService,
    private loaderService: LoaderService,
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.refetch();

    this.subscriptions.add(this.saleModeService.saleModeAsObservable().subscribe(saleMode => (this.saleMode = +saleMode)));

    this.userService.fromStorage().subscribe(user => (this.user = user));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.activeModal.dismiss();
  }

  determinePrice(item: OrderArticleInterface) {
    if (this.user) {
      if (this.user.role.name === UserRole.ROLE_PM_NARBUTAS) {
        return item.discount;
      }

      return this.saleMode === SaleMode.ORDER ? item.discount : item.saleDiscount;
    }
  }

  private refetch(): void {
    this.loading = true;

    this.productsService
      .fetchProductPrice({
        quantity: this.quantity,
        saleMode: this.saleMode ? this.saleMode : this.saleModeService.getSaleMode(),
        order: this.selectedOrder ? this.selectedOrder.id : null,
        orderArticle: this.orderArticle ? this.orderArticle.id : null,
      })
      .noCache()
      .pipe(first())
      .subscribe((data: PriceDetailsInterface) => {
        this.details = data;
        this.items = data.orderArticles;
        this.loading = false;
      });
  }
}
